import React from 'react';

const EmptyState = (props) => {
  return (
    <div className="w-full h-20 bg-yellow-base text-xl text-black-1000 flex items-center justify-center">
      {props?.title ? props.title : 'Data is missing'}
    </div>
  );
};

export default EmptyState;
