import React from "react";
import minus from "../../assets/icons/minus.svg";
import minusLight from "../../assets/icons/minus_white.svg";
import plus from "../../assets/icons/plus.svg";
import plusLight from "../../assets/icons/plus_white.svg";
import { Image, RichText } from "@sitecore-jss/sitecore-jss-react";

const Accordion = (props) => {
  const {
    index,
    iconField,
    blockIndex,
    open,
    style = "light",
    title,
    children,
    onClick,
    blue,
  } = props;

  return (
    <div
      className={
        "border-y my-5 transition-all p-5 " +
        (style === "light" ? "border-bluedark-500" : "border-white")
      }
    >
      <div
        className="items-start justify-between cursor-pointer gap-5 flex"
        onClick={() => onClick(blockIndex, index)}
      >
        <div
          className={
            "text-lg md:text-xl md:leading-[24px] font-[500] items-center flex gap-2 " +
            (style === "light"
              ? blue === "Blue"
                ? "text-blueDark-500"
                : "text-blueDark-500"
              : "text-white")
          }
        >
          <Image field={iconField} className="shrink-0" />
          <RichText field={title} />
        </div>
        {style === "light" ? (
          open ? (
            <img src={minus} alt="minus" />
          ) : (
            <img src={plus} alt="plus" />
          )
        ) : open ? (
          <img src={minusLight} alt="minus" />
        ) : (
          <img src={plusLight} alt="plus" />
        )}
      </div>
      <div className={open ? "visible h-fit mt-4" : "hidden h-0"}>
        {children}
      </div>
    </div>
  );
};

export default Accordion;
