import React from "react";
import {
  RichText,
  Link,
  withSitecoreContext,
  Image,
} from "@sitecore-jss/sitecore-jss-react";

const CTAListBar = ({ fields, params }) => {
  return (
    <section
      className={`${
        params?.BackgroundColor === "brand" ? "bg-brand" : "bg-white"
      } py-5 pb-10 `}
    >
      <div className="container">
        <div className="mt-5 bg-tumeric-100 w-full flex-wrap gap-10 flex justify-between px-10 py-8">
          <h4 className="text-bluedark font-[500] md:text-start text-center">
            <RichText field={fields?.Title} />
          </h4>
          <div className="md:flex md:mx-0 mx-auto">
            <div>
              <a
                href={fields?.CTAbutton?.value?.href}
                target="_blank"
                rel="noreferrer"
              >
                <Image field={fields?.Image} />
              </a>
            </div>
            <div className="md:ms-6 ms-0 md:mt-0 mt-6">
              <a
                href={fields?.SecondCTA?.value?.href}
                target="_blank"
                rel="noreferrer"
              >
                <Image field={fields?.SecondCTAImage} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withSitecoreContext()(CTAListBar);
