import React from "react";
import { RichText } from "@sitecore-jss/sitecore-jss-react";

const LightBG = ({ params, fields }) => {
  return (
    <div
      className={`container md:w-10/12   md:my-14 pt-20 pb-10 bg-${
        params.BackgroundColor || "bg-blueDark-100"
      }`}
    >
      <div className="flex flex-wrap justify-center">
        <div className="basis-full md:basis-10/12">
          <h3 className="text-h2 uppercase text-center text-lime-green-900 font-[500] mb-3">
            <RichText field={fields?.Title} />
          </h3>
          <p className="text-xl text-center text-grey-700">
            <RichText field={fields?.Description} />
          </p>
        </div>
      </div>
    </div>
  );
};

export default LightBG;
