import React from "react";
import { Parallax } from "react-scroll-parallax";
import {
  Text,
  RichText,
  Image,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import { Helmet } from "react-helmet";

const TopAlignedText = ({
  sitecoreContext,
  fields,
  params,
  isMobile,
  lowCarbon,
}) => {
  const isNews = sitecoreContext.itemPath?.includes("/news/");
  return (
    <section>
      <section
        className={`relative overflow-hidden pt-8  md:bg-fixed bg-cover min-h-[400px] md:min-h-[800px]`}
        style={{
          backgroundImage: lowCarbon
            ? "none"
            : `url(${
                isMobile
                  ? fields?.BackgroundMobileImage?.value?.src ||
                    fields?.BackgroundImage?.value?.src
                  : fields?.BackgroundImage?.value?.src
              })`,
        }}
      >
        {lowCarbon !== null && (
          <>
            <Helmet>
              <meta
                name="image"
                content={fields?.BackgroundImage?.value?.src}
              />
              <meta
                name="og:image"
                content={fields?.BackgroundImage?.value?.src}
              />
            </Helmet>
            <div className="absolute top-0 start-0 w-full h-full dark:bg-green-gradient"></div>
            {params?.BackgroundColor !== "no-gradiant" && (
              <div
                className={`absolute top-0 start-0 w-full  dark:hidden ${
                  isNews ? "h-[200px]" : "md:h-[60%] h-[80%]"
                }`}
                style={{
                  background: ` ${
                    isNews
                      ? " linear-gradient(180deg, rgba(0, 0, 0, 0.70) 20.8%, rgba(0, 0, 0, 0.00) 97.39%)"
                      : params?.gradient
                  }`,
                }}
              ></div>
            )}
            <div className="relative  container">
              <Parallax
                speed={10}
                translateY={["10px", "200px"]}
                className={`absolute ${
                  params?.BackgroundColor === "hero-img-left"
                    ? ""
                    : "end-[-40px]"
                } top-[-150px] hidden md:block`}
              >
                <Image field={fields?.Image} />
              </Parallax>
              <div
                className={`min-h-[500px] lg:flex  md:mt-40 mt-20 relative z-100`}
              >
                <div
                  className={`mb-7 md:mb-14 ${
                    fields?.Description?.value && "lg:w-6/12"
                  }`}
                >
                  <h1
                    className={`${
                      params?.TextColor === "dark"
                        ? "text-blueDark-500"
                        : "text-white"
                    } mb-2 font-[500] lg:text-[96px]`}
                  >
                    <RichText field={fields?.Title} />
                  </h1>
                  <p className="text-lg md:text-xl font-normal text-white  ">
                    <Text field={fields?.Subtitle} />
                  </p>
                </div>
                {fields?.Description?.value && (
                  <div className="lg:w-1/12"></div>
                )}
                {fields?.Description?.value && (
                  <div className="lg:w-5/12">
                    <div
                      className={`${
                        params?.TextColor === "dark"
                          ? "text-blueDark-500"
                          : "text-white"
                      } text-lg md:text-xl`}
                    >
                      <RichText field={fields?.Description} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </section>
    </section>
  );
};

export default withSitecoreContext()(TopAlignedText);
