import React, { useEffect, useState } from "react";
import { useQuery, gql } from "@apollo/client";
import FNBCard from "./FNBCard";
import LoadingPlaceHolder from "../../../Common/loadingPlaceholder";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import TranslatedWord from "../../../Common/DictionaryTexts";

const GetFNBListing = gql`
  query GetFNBListing(
    $datasource: String!
    $language: String!
    $first: Int!
    $after: String!
    $keyword: String!
    $fieldsEqual: [ItemSearchFieldQuery]
  ) {
    GQLResult: extendedsearch(
      rootItem: $datasource
      language: $language
      sortBy: "customsort_s"
      sortDesc: false
      keyword: $keyword
      index: "cop28_website_{database}_index"
      first: $first
      after: $after
      fieldsEqual: $fieldsEqual
    ) {
      results {
        items {
          item {
            url
            Title: field(name: "PageTitle") {
              value
            }
            Description: field(name: "Content") {
              value
            }
            Image: field(name: "Image") {
              ... on ImageField {
                src
                alt
              }
            }
            Zone: field(name: "Zone") {
              value
            }
            Venue: field(name: "Venue") {
              value
            }
          }
        }
        pageInfo {
          hasNextPage
        }
      }
    }
  }
`;
const LoadingCards = ({ count }) => {
  const [cards, set_cards] = useState([]);

  useEffect(() => {
    let temp = [];
    for (let i = 0; i < count; i++) {
      temp.push(i);
    }
    set_cards([...temp]);
  }, [count]);

  return (
    <>
      {cards.map((card) => (
        <div key={card} className="w-full min-h-[362px]">
          <LoadingPlaceHolder
            extraStyles={{ height: "100%", borderRadius: 16 }}
          />
        </div>
      ))}
    </>
  );
};

const FoodandBeverageListing = (props) => {
  const contextFields = props.sitecoreContext?.route?.fields;
  const [currentPage, set_currentPage] = useState(0);
  const [isFirstLoad, set_isFirstLoad] = useState(true);
  const [fetching, set_fetching] = useState(false);
  const numPerPage = contextFields?.LoadSize?.value || 3;
  const [hasNextPage, set_hasNextPage] = useState(
    props.fields?.data?.GQLResult?.results?.pageInfo?.hasNextPage
  );
  const [results, set_results] = useState(
    props.fields?.data?.GQLResult?.results?.items || []
  );
  const [textFilter, set_textFilter] = useState("");
  const [text, set_text] = useState("");
  const [zoneModalShow, set_zoneModalShow] = useState(false);
  const zones = contextFields?.EventZone || [];
  const venues = contextFields?.Venues || [];
  const [selectedZones, set_selectedZones] = useState([]);
  const toggleSelectZones = (index) => {
    let temp = selectedZones ? selectedZones : [];
    const i = temp.indexOf(index);
    if (i > -1) {
      temp.splice(i, 1);
    } else {
      temp.push(index);
    }
    temp = temp.sort((a, b) => a - b);
    set_selectedZones([...temp]);
  };

  const { loading, error, data, networkStatus, refetch } = useQuery(
    GetFNBListing,
    {
      variables: {
        datasource: props.rendering.dataSource,
        language: "en",
        first: numPerPage,
        after: "0",
        keyword: "",
        $fieldsEqual: [
          { name: "_template", value: "5d471083c2a142ef950c847e8feb6208" },
        ],
      },
      notifyOnNetworkStatusChange: true, // This is an importantflag that makes loading status get changed when refetch
    }
  );

  const refetchResults = (currentPage) => {
    set_fetching(true);
    if (!currentPage) {
      set_results([]);
      set_hasNextPage(false);
    }
    set_currentPage(currentPage);

    let fieldsEqual = [
      { name: "_template", value: "5d471083c2a142ef950c847e8feb6208" },
    ];
    let zone_sm = "";
    selectedZones.map((item) => {
      zone_sm += zones[item]?.id?.replace(/[^a-zA-Z0-9{}]+/g, "") + ",";
    });
    if (zone_sm) {
      fieldsEqual.push({
        name: "zone_sm",
        value: `in:${zone_sm}`,
      });
    }
    console.log("=============== Fetching the GQL Results ===============\n");
    refetch({
      keyword: textFilter,
      first: numPerPage,
      after: (currentPage * numPerPage).toString(),
      fieldsEqual: fieldsEqual,
    });
  };

  const clearFilter = () => {
    set_text("");
    set_textFilter("");
    set_selectedZones([]);
  };

  const loadMore = () => {
    refetchResults(currentPage + 1);
  };

  useEffect(() => {
    if (!isFirstLoad) {
      refetchResults(0);
    }
  }, [textFilter, selectedZones]);

  useEffect(() => {
    if (!loading) {
      if (isFirstLoad) {
        set_isFirstLoad(false);
      } else {
        console.log(
          "=============== Finished Fetching ===============",
          data?.GQLResult?.results?.items?.length
        );
        set_hasNextPage(data?.GQLResult?.results?.pageInfo?.hasNextPage);
        set_fetching(false);
        const newResults = data?.GQLResult?.results?.items || [];
        if (currentPage) {
          set_results([...results, ...newResults]);
        } else {
          set_results(newResults);
        }
        set_fetching(false);
      }
    }
  }, [loading]);

  return (
    <div className="w-full">
      <div className="container pt-10 pb-6">
        <div className="w-full xl:px-20 mx-auto flex flex-col space-y-2 sm:space-y-0 sm:flex-row sm:justify-between sm:space-x-4 rtl:space-x-reverse text-[12px] leading-[18px] text-blueDark-500 ">
          <div className="w-full relative bg-tumeric-50">
            <input
              value={text}
              onChange={(e) => {
                let temp = e.target.value.replace(/[^a-zA-Z0-9\s]/g, "");
                if (temp.trim() !== "") {
                  set_text(temp);
                } else {
                  set_text("");
                }
                if (e.target.value === "") {
                  set_textFilter(e.target.value);
                }
              }}
              onKeyUp={(e) => {
                if (e.key === "Enter") set_textFilter(e.target.value);
              }}
              className="w-full h-[50px] ps-3 pe-9 bg-transparent outline-none"
              placeholder={contextFields?.SearchBoxPlaceholder?.value}
            />
            <button
              className="absolute top-3 end-3"
              onClick={() => set_textFilter(text)}
            >
              <svg
                className=""
                width="23"
                height="24"
                viewBox="0 0 23 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.5734 18.5597C14.7135 18.5597 18.0697 15.2035 18.0697 11.0634C18.0697 6.92328 14.7135 3.56708 10.5734 3.56708C6.43335 3.56708 3.07715 6.92328 3.07715 11.0634C3.07715 15.2035 6.43335 18.5597 10.5734 18.5597Z"
                  stroke="currentColor"
                  strokeWidth="1.40556"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19.9462 20.4332L15.8701 16.3571"
                  stroke="currentColor"
                  strokeWidth="1.40556"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div className="w-full h-[50px] sm:w-fit flex justify-center space-x-4 rtl:space-x-reverse">
            <div
              className={`w-full h-full fixed start-0 top-0 z-10 ${
                zoneModalShow ? "" : "hidden"
              }`}
              onClick={() => set_zoneModalShow(false)}
            />
            <div className="relative w-fit h-full">
              <button
                className="w-[230px] h-full bg-tumeric-50 flex items-center justify-between space-x-[10px] rtl:space-x-reverse px-4 font-[500]"
                onClick={() => set_zoneModalShow(!zoneModalShow)}
              >
                <span>
                  {contextFields.FilteByZoneLabel?.value}:{" "}
                  {selectedZones.length &&
                  selectedZones.length !== zones.length ? (
                    `${zones[selectedZones[0]].fields?.Value.value} ${
                      selectedZones.length > 1
                        ? "(+" + (selectedZones.length - 1) + ")"
                        : ""
                    }`
                  ) : (
                    <TranslatedWord EnWord="All" />
                  )}
                </span>
                <svg
                  className={`${zoneModalShow ? "rotate-180" : ""}`}
                  width="19"
                  height="18"
                  viewBox="0 0 19 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.67773 6.75L9.17773 11.25L13.6777 6.75"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
              <div
                className={`absolute top-14 end-0 w-full shadow-md bg-tumeric-50 rounded-sm z-[10] py-3 px-[22px] ${
                  zoneModalShow ? "" : "hidden"
                }`}
              >
                {zones.map((item, index) => (
                  <div
                    key={`zone_${index}`}
                    className="w-full flex space-x-2 rtl:space-x-reverse items-center cursor-pointer mt-2 first:mt-0"
                    onClick={() => toggleSelectZones(index)}
                  >
                    <div>
                      {selectedZones?.indexOf(index) > -1 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M20.4 21.75C21.1456 21.75 21.75 21.1456 21.75 20.4V3.6C21.75 2.85442 21.1456 2.25 20.4 2.25H3.6C2.85442 2.25 2.25 2.85441 2.25 3.6V20.4C2.25 21.1456 2.85441 21.75 3.6 21.75H20.4ZM6.23003 13.5836C5.9077 13.2613 5.9077 12.7387 6.23003 12.4164C6.55235 12.094 7.07495 12.094 7.39727 12.4164L9.88229 14.9014L16.9702 7.8134C17.2926 7.49107 17.8152 7.49107 18.1375 7.8134C18.4598 8.13573 18.4598 8.65832 18.1375 8.98065L10.4659 16.6522C10.1436 16.9746 9.62099 16.9746 9.29866 16.6522L6.23003 13.5836Z"
                            fill="#118170"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M20.25 20.25V3.75H3.75V20.25H20.25ZM21.75 20.4C21.75 21.1456 21.1456 21.75 20.4 21.75H3.6C2.85441 21.75 2.25 21.1456 2.25 20.4V3.6C2.25 2.85441 2.85442 2.25 3.6 2.25H20.4C21.1456 2.25 21.75 2.85442 21.75 3.6V20.4Z"
                            fill="#163331"
                          />
                        </svg>
                      )}
                    </div>
                    <div
                      title={item?.fields?.Value?.value}
                      className="w-[calc(100%-32px)] text-blueDark-500 truncate"
                    >
                      {item?.fields?.Value?.value}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* <button 
                        className='w-[184px] h-full bg-tumeric-50 flex items-center justify-center  rtl:space-x-reverse'
                        onClick={() => {}}
                    >
                        <span>Sort by: Name A-Z</span>
                        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.67773 6.75L9.17773 11.25L13.6777 6.75" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </button> */}
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-6 gap-y-10 mt-10">
          {results.map((item, index) => (
            <FNBCard
              key={`FNBCard_${index}`}
              data={item.item}
              zoneLabel={contextFields.ZoneLabel?.value}
              zones={zones}
              venues={venues}
            />
          ))}
          {fetching ? <LoadingCards count={numPerPage} /> : null}
        </div>
        {!results?.length && !fetching ? (
          <div className="w-full flex flex-col items-center p-14">
            <div className="w-fit p-5 bg-blueDark-50">
              <svg
                width="72"
                height="72"
                viewBox="0 0 72 72"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="72" height="72" fill="#BEE2D5" />
                <path
                  d="M35 46C41.0751 46 46 41.0751 46 35C46 28.9249 41.0751 24 35 24C28.9249 24 24 28.9249 24 35C24 41.0751 28.9249 46 35 46Z"
                  stroke="#118170"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M48.7333 48.7333L43 43"
                  stroke="#118170"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M31 39L39 31"
                  stroke="#118170"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M31 31L39 39"
                  stroke="#118170"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="text-[32px] text-center font-[500] uppercase mt-6 text-brand">
              <TranslatedWord EnWord="No Search Results" />
            </div>
            <div className="text-[20px] font-[500] leading-[24px] mt-6 text-blueDark-500 text-center">
              <TranslatedWord EnWord="We couldn't find what you're looking for, try searching for something else." />
            </div>
            <button
              onClick={clearFilter}
              className="bg-tumeric-200 px-[30px] py-4 flex items-center space-x-[10px] rtl:space-x-reverse mt-6"
            >
              <span>
                <TranslatedWord EnWord="Reset Search" />
              </span>
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.5 2H14.5"
                  stroke="#173330"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.5 14V10"
                  stroke="#173330"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4.5 13.0002C4.74486 11.0562 5.69466 9.26965 7.16925 7.97939C8.64384 6.68912 10.5407 5.98489 12.5 6.00025C13.794 6.00162 15.0684 6.31689 16.2139 6.91901C17.3593 7.52112 18.3416 8.39212 19.0764 9.45727C19.8113 10.5224 20.2768 11.7499 20.433 13.0345C20.5892 14.3191 20.4315 15.6224 19.9733 16.8326C19.5152 18.0429 18.7703 19.1239 17.8025 19.983C16.8348 20.8421 15.6731 21.4536 14.4171 21.765C13.1611 22.0765 11.8482 22.0787 10.5912 21.7713C9.33422 21.4639 8.17054 20.8562 7.2 20.0002L4.5 17.6002"
                  stroke="#173330"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.5 17H4.5V22"
                  stroke="#173330"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        ) : null}
        {hasNextPage && !fetching ? (
          <div className="w-full mt-6 flex justify-center">
            <button
              className="px-8 py-4 flex items-center justify-center space-x-[10px] rtl:space-x-reverse bg-brand"
              onClick={loadMore}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_2631_34800)">
                  <path
                    d="M1.66683 10.0003C1.66683 14.6027 5.39779 18.3337 10.0002 18.3337C14.6025 18.3337 18.3335 14.6027 18.3335 10.0003C18.3335 5.39795 14.6025 1.66699 10.0002 1.66699C5.39779 1.66699 1.66683 5.39795 1.66683 10.0003Z"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6.66683 10L10.0002 13.3333L13.3335 10"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10 6.66699L10 13.3337"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2631_34800">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                      transform="translate(20) rotate(90)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <span className="text-[16px] leading-[18px] font-[500] text-white uppercase">
                {contextFields?.LoadMoreText?.value}
              </span>
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default withSitecoreContext()(FoodandBeverageListing);
