import React from "react";
import {
  RichText,
  Link,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import CTA from "../../../../Common/CTA";
const ThreeColumn = ({ sitecoreContext, fields, params }) => {
  console.log("ThreeColumn", params);
  return (
    <section
      className={`${
        params?.BackgroundColor === "brand" ? "bg-brand" : "bg-white"
      } py-[56px] md:bg-gradient-to-r bg-gradient-to-b `}
      style={{ background: params?.gradient }}
    >
      <div className="container flex lg:gap-[72px] gap-[32px] lg:flex-row flex-col justify-center items-center">
        <h3
          className={`${
            params?.TextColor
              ? params?.TextColor === "light"
                ? "text-white"
                : "text-blueDark-500"
              : "text-white"
          } text-[56px] font-[500]  leading-[57px] text-center lg:text-start lg:max-w-[307px]`}
        >
          <RichText field={fields?.Title} />
        </h3>
        <div
          className={`${
            params?.TextColor
              ? params?.TextColor === "light"
                ? "text-white"
                : "text-blueDark-500"
              : "text-white"
          } text-[20px] md:text-[20px] leading-[24px] ms-auto me-auto  font-[400] text-center lg:text-start`}
        >
          <RichText field={fields?.Description} />
        </div>
        <div>
          <CTA
            className="flex w-fit items-center ms-auto"
            link={fields?.CTAbutton}
            download={true}
            color={
              sitecoreContext?.route?.fields?.PageTheme?.value === "Blue" &&
              params?.TextColor &&
              "blueDownLoad"
            }
          >
            <Link field={fields?.CTAbutton} />
          </CTA>
        </div>
      </div>
    </section>
  );
};

export default withSitecoreContext()(ThreeColumn);
