import React, { useEffect, useState, useRef } from "react";
import { RichText, Image } from "@sitecore-jss/sitecore-jss-react";
import { Helmet } from "react-helmet";
import Pagination from "../../../Common/Pagination";
import { useQuery, gql } from "@apollo/client";
import LoadingPlaceHolder from "../../../Common/loadingPlaceholder";

const GetProfileList = gql`
  query GetProfileList($language: String!, $first: Int!, $after: String!) {
    GQLResult: extendedsearch(
      rootItem: "/sitecore/content/COP28/GlobalData/Partners/PartnersMasterList"
      language: $language
      sortBy: "customsort_s"
      sortDesc: false
      first: $first
      after: $after
      index: "cop28_website_{database}_index"
      fieldsEqual: [
        { name: "_template", value: "eebafc56745449fc89101663cdb9482e" }
        { name: "PartnerType", value: "179c5c413276495ab5cd4080ee268d5f" }
      ]
    ) {
      results {
        items {
          item {
            name
            Title: field(name: "Title") {
              value
            }
            Description: field(name: "Description") {
              value
            }
            LogoImage: field(name: "Image") {
              ... on ImageField {
                src
                alt
              }
            }
            CTALink: field(name: "CTA") {
              ... on LinkField {
                url
                text
              }
            }
          }
        }
      }
    }
  }
`;

const LoadingCards = ({ count }) => {
  const [cards, set_cards] = useState([]);

  useEffect(() => {
    let temp = [];
    for (let i = 0; i < count; i++) {
      temp.push(i);
    }
    set_cards([...temp]);
  }, [count]);

  return (
    <>
      {cards.map((card) => (
        <div
          key={card}
          className="h-[250px] basis-full md:basis-[48%] lg:basis-[22%]"
        >
          <LoadingPlaceHolder
            extraStyles={{ height: "100%", borderRadius: 0 }}
          />
        </div>
      ))}
    </>
  );
};

const ParnerListingSupporters = ({ params, fields, rendering, isRtl }) => {
  console.log(isRtl, "isRtl ParnerListingSupporters");
  const perpage = fields?.data?.GQLSource?.ResultsPerPage?.intValue;
  const { loading, error, data, networkStatus, refetch } = useQuery(
    GetProfileList,
    {
      variables: {
        language: "en",
        first: perpage,
        after: "0",
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const ref = useRef(null);
  const totalPage = Math.ceil(
    fields?.data?.GQLResult?.results?.totalCount / perpage
  );

  const [results, set_results] = useState(
    fields?.data?.GQLResult?.results?.items || []
  );
  const [isFirstLoad, set_isFirstLoad] = useState(true);
  const [fetching, set_fetching] = useState(false);

  const refetchResults = (after) => {
    set_fetching(true);
    refetch({
      first: perpage,
      after: after.toString(),
    });
  };

  const onClick = (event) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
    refetchResults(event.selected * perpage);
  };

  useEffect(() => {
    if (!loading) {
      if (isFirstLoad) {
        set_isFirstLoad(false);
      } else {
        console.log(
          "=============== Finished Fetching ===============",
          data?.GQLResult?.results?.items?.length
        );
        set_results([...data?.GQLResult?.results?.items]);
        set_fetching(false);
      }
    }
  }, [loading]);

  return (
    <section className="container">
      <div className="my-14" ref={ref}>
        <RichText
          field={fields?.data?.GQLSource?.Title}
          className="text-[56px] leading-[57.12px] font-medium text-blueDark-100 text-center"
        />
        {fetching ? (
          <div className="flex flex-wrap gap-y-16 gap-x-4 mt-10 box-border justify-center">
            <LoadingCards
              count={fields?.data?.GQLSource?.ResultsPerPage?.intValue}
            />
          </div>
        ) : (
          <div className="flex flex-wrap gap-y-16 mt-10 box-border justify-center">
            {results?.map((item, index) => (
              <div
                key={index}
                className="h-[250px] basis-full md:basis-6/12 lg:basis-3/12"
              >
                <div
                  className="flex items-start justify-center relative w-full h-full mx-auto cursor-pointer"
                  onClick={() => {
                    if (item?.item?.CTALink?.url) {
                      window.open(item?.item?.CTALink?.url, "_blank");
                    }
                  }}
                >
                  <Image field={item?.item?.LogoImage} />
                  <RichText
                    field={item?.item?.Title}
                    className="absolute bottom-4 font-medium text-base leading-[18px] text-blueDark-500 text-center"
                  />
                </div>
              </div>
            ))}
          </div>
        )}
        {totalPage > 1 && (
          <div className="w-full mt-14">
            <Pagination
              totalPage={totalPage}
              onClick={onClick}
              isRTL={isRtl === "true" ? true : false}
            />
          </div>
        )}
      </div>
      {params?.DisplayHorizontalLineAtBottom === "1" && (
        <div className="w-full h-[1px] bg-blueDark-500 my-2" />
      )}
      <Helmet>
        <meta name="image" content={fields?.Image?.value?.src} />
        <meta name="og:image" content={fields?.Image?.value?.src} />
      </Helmet>
    </section>
  );
};

export default ParnerListingSupporters;
