import React, { useState } from "react";
import { RichText, Image } from "@sitecore-jss/sitecore-jss-react";
import Collapser from "../../../Common/Collapser";
import Accordion from "../../../Common/Accordion";

const CollapserWithScroll = (props) => {
    const { fields, sitecoreContext } = props;
    const [activeBlockIndex, setActiveBlockIndex] = useState("");
    const [activeItemIndex, setActiveItemIndex] = useState("");

    const onClickAccordion = (blockIndex, itemIndex) => {
        setActiveBlockIndex(blockIndex);
        if (itemIndex === activeItemIndex && blockIndex === activeBlockIndex) {
        setActiveItemIndex(null); // Clicking on the same item closes it
        } else {
        setActiveItemIndex(itemIndex); // Clicking on a different item sets it as active
        }
    };

    const onClickImage = (itemIndex, blockIndex) => {
        async function myFunction() {
            setActiveBlockIndex(blockIndex);
            setActiveItemIndex(itemIndex);
        }
        myFunction().then(
            () => {
                var elem = document.getElementById(`${blockIndex} + ${itemIndex}`);
                elem.scrollIntoView();
            }
        );
    };
    
  return (
    <div>
        <section>
            <div className="container py-14 cursor-pointer">
                {fields?.SelectedTabs.map((mainItem, blockIndex) => (
                    <div className="flex flex-col lg:flex-row" key={blockIndex}>
                        {mainItem?.fields?.SelectedSections.map((item, blockIndex) => (
                            <div className="w-full lg:w-[33.3%]" key={blockIndex}
                            > 
                                <h3
                                    className={`${
                                        item?.fields?.BackgroundImageColor?.value
                                        ? "text-white"
                                        : sitecoreContext?.route?.fields?.PageTheme?.value ===
                                            "Blue"
                                        ? "text-blueDark-600 "
                                        : "text-blueDark-500 "
                                    } bg-${item?.fields?.BackgroundImageColor?.value}
                                    p-4 font-medium text-[40px]`}
                                >
                                    <RichText field={item?.fields?.Title} />
                                </h3>
                                { item?.fields?.SelectedTabs.map((value, itemIndex) =>(
                                    <div className={`bg-${value?.fields?.BackgroundImageColor?.value} h-[33.3%] p-4 ${value?.fields?.BackgroundImageColor?.value
                                        ? "text-white"
                                        : "text-blueDark-500 "
                                        }`} onClick={() => onClickImage(itemIndex, blockIndex)} key={itemIndex}>
                                        <h6 className="font-medium text-[40px]"> {value?.fields?.Number?.value} </h6>
                                        <div className="flex flex-row">
                                            <span className="font-medium text-[32px]">{value?.fields?.SubTitle?.value}</span>
                                            <Image field={value?.fields?.Icon?.value} className="w-[56px] ml-1 md:ml-4" />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </section>
        {fields?.SelectedTabs.map((mainItem, blockIndex) => (
            <section key={blockIndex}>
                {mainItem?.fields?.SelectedSections.map((item, blockIndex) => (
                    <section
                    key={blockIndex}
                    className={`${
                        sitecoreContext?.route?.fields?.PageTheme?.value === "Blue" &&
                        item?.fields?.BackgroundColor?.value === "brand"
                        ? "bg-blueDark-600"
                        : "bg-" + item?.fields?.BackgroundColor?.value
                    } 
                    
                        font-[500] py-14`}
                    >
                        <div className="container">
                            <div className="md:flex gap-5 block">
                                <div className="md:w-1/3 w-full pe-10">
                                    <h3
                                    className={`${
                                        item?.fields?.BackgroundColor?.value === "brand"
                                        ? "text-white"
                                        : sitecoreContext?.route?.fields?.PageTheme?.value ===
                                            "Blue"
                                        ? "text-blueDark-600"
                                        : "text-blueDark-500 "
                                    } 
                                    ${
                                        sitecoreContext?.itemPath?.includes("/news/")
                                        ? " bg-mint p-2 inline-block"
                                        : ""
                                    }
                                    mb-14   font-[500]`}
                                    >
                                        <RichText field={item?.fields?.Title} />
                                    </h3>
                                </div>
                                <div className="md:w-2/3 w-full">
                                    <div
                                    className={`${
                                        item?.fields?.BackgroundColor?.value === "brand"
                                        ? "text-white"
                                        : sitecoreContext?.route?.fields?.PageTheme?.value ===
                                            "Blue"
                                        ? "text-blueDark-500"
                                        : "text-blueDark-500"
                                    } text-lg md:text-xl font-[500]`}
                                    >
                                        <RichText field={item?.fields?.Description} />
                                    </div>
                                    {item?.fields?.TabType?.value === "RichText"
                                    ? item?.fields?.SelectedTabs.map((value, i) => (
                                        <Collapser
                                            key={i}
                                            summary={value?.fields?.Title}
                                            color={item?.fields?.BackgroundColor?.value}
                                            blue={sitecoreContext?.route?.fields?.PageTheme?.value}
                                        >
                                            <div
                                            className={`${
                                                item?.fields?.BackgroundColor?.value === "brand"
                                                ? "text-white"
                                                : sitecoreContext?.route?.fields?.PageTheme
                                                    ?.value === "Blue"
                                                ? "text-blueDark-600"
                                                : "text-bluedark"
                                            } text-lg md:text-xl`}
                                            >
                                                <RichText field={value?.fields?.Description} />
                                            </div>
                                        </Collapser>
                                        ))
                                    : item?.fields?.SelectedTabs.map((value, itemIndex) =>
                                        value?.fields?.Title?.value ? (
                                            <div id={`${blockIndex} + ${itemIndex}`} key={itemIndex}>
                                                <Accordion
                                                    key={itemIndex}
                                                    iconField={value?.fields?.Icon}
                                                    style={
                                                        item?.fields?.BackgroundColor?.value === "brand"
                                                        ? "dark"
                                                        : "light"
                                                    }
                                                    open={
                                                        blockIndex === activeBlockIndex &&
                                                        itemIndex === activeItemIndex
                                                    }
                                                    title={value?.fields?.Title}
                                                    index={itemIndex}
                                                    blockIndex={blockIndex}
                                                    onClick={onClickAccordion}
                                                    blue={
                                                        sitecoreContext?.route?.fields?.PageTheme?.value
                                                    }
                                                    >
                                                    <div className="text-base text-bluedark">
                                                        <RichText field={value?.fields?.Description} />
                                                    </div>
                                                </Accordion>
                                            </div>
                                        ) : (
                                            <RichText
                                            key={itemIndex}
                                            field={value?.fields?.Description}
                                            />
                                        )
                                        )}
                                </div>
                            </div>
                        </div>
                    </section>
                ))}
            </section>
        ))}
    </div>
  );
};

export default CollapserWithScroll;
