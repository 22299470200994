import React from "react";
import { Link, RichText } from "@sitecore-jss/sitecore-jss-react";
import CTA from "../../../Common/CTA";

const ContainerCard = ({ fields }) => {
  return (
    <>
      <section className="bg-white">
        <div className="w-full flex md:flex-row flex-col relative overflow-hidden pt-[200px] pb-[60px]">
          {fields?.cards?.map((item, index) => {
            return (
              <div
                className="md:w-10/12 w-11/12 min-h-[600px] md:px-20 px-5 py-32 me-auto ms-auto text-center bg-brand"
                style={{
                  backgroundImage: `url(${item?.fields?.Image?.value?.src})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
                key={index}
              >
                <h3
                  className={`${
                    index === 0 ? "text-white" : "text-brand"
                  } font-[500] text-[150px]`}
                >
                  <RichText field={item?.fields?.Title} />
                </h3>
                <div
                  className={`mt-14 text-3xl font-bold ${
                    index === 0 ? "text-white" : "text-bluedark"
                  }`}
                >
                  <RichText field={item?.fields?.Description} />
                </div>
                <div className="mt-6">
                  {item?.fields?.CTAbutton?.value?.href && (
                    <Link field={item?.fields?.CTAbutton}>
                      <CTA
                        className="flex w-fit items-center bg-lime-green-900"
                        backIcon={true}
                      >
                        {item?.fields?.CTAbutton?.value?.text}
                      </CTA>
                    </Link>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default ContainerCard;
