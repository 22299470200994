import React, { useState, useEffect } from "react";
import ErrorBoundary from "./components/Common/ErrorBoundary";
import {
  Placeholder,
  VisitorIdentification,
} from "@sitecore-jss/sitecore-jss-react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import deepEqual from "deep-equal";
import { Helmet } from "react-helmet";
import { setLang } from "./modules/LangSlice";
import { Image, Link, RichText } from "@sitecore-jss/sitecore-jss-react";
import Modal from "./components/Common/Modal";
import Parser from "html-react-parser";

// Using bootstrap is completely optional. It's used here to provide a clean layout for samples,
// without needing extra CSS in the sample app. Remove it in package.json as well if it's removed here.
import "./assets/app.css";
import logo from "./assets/sc_logo.svg";
import { ParallaxProvider } from "react-scroll-parallax";
import "swiper/swiper.min.css";
import TakeOver from "./components/Common/TakeOver";
import UseScrollBlock from "./components/Common/UseScrollBlock";

/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/

// inject dictionary props (`t`) into navigation so we can translate it
// NOTE: using this is needed instead of using i18next directly to keep
// the component state updated when i18n state (e.g. current language) changes
// Navigation = withTranslation()(Navigation);

const Layout = ({ route, context }) => {
  const dispatch = useDispatch();
  route.siteSettings = context.siteSettings;
  const [pathname, set_pathname] = useState("");
  const [showTakeOver, setShowTakeover] = useState(
    context?.siteSettings?.EnablePopup?.value
  );
  const [blockScroll, unlockScroll] = UseScrollBlock();
  const [takeOverVisited, setTakeOverVisited] = useState(true);

  useEffect(() => {
    dispatch(setLang({ lang: context.language }));
  }, [context]);

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = context?.siteSettings?.FavIcon?.value?.src;

    // handle takeover behaviour
    if (!sessionStorage?.getItem("takeoverVisited")) {
      setTakeOverVisited(false);
    }
    if (context?.siteSettings?.EnablePopup?.value) {
      sessionStorage.setItem("takeoverVisited", 1);
    }
    // handle lowcarbon
    if (route?.fields?.isLowcorbonEnabled?.value) {
      localStorage.setItem("low-carbon-mode", true);
      document.documentElement.setAttribute("data-theme", "low-carbon");
    }
  }, [showTakeOver]);
  const _title = route?.displayName?.replace("-", " ");
  const _result = _title.replace(/([A-Z])/g, " $1");
  const finalResult = _result.charAt(0).toUpperCase() + _result.slice(1);
  const ALT_TITLE = finalResult;
  const SCRIPT_PROPS = JSON.parse(
    context?.siteSettings?.GoogleAnalyticsTag?.value || "{}"
  );

  console.log(context.language, context.itemPath);

  return (
    <React.Fragment>
      {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
      <Helmet>
        <title>
          {(route.fields &&
            route.fields.pageTitle &&
            route.fields.pageTitle.value) ||
            `COP28 UAE | ${ALT_TITLE}`}
        </title>
        <meta
          name="og:title"
          content={
            route.fields && route.fields?.SeoTitle?.value
              ? route.fields?.SeoTitle?.value
              : `COP28 UAE | ${ALT_TITLE}`
          }
        />
        <meta
          name="description"
          content={route?.fields?.SeoDescription?.value || ALT_TITLE}
        />
        <meta
          name="og:description"
          content={route?.fields?.SeoDescription?.value || ALT_TITLE}
        />
        <meta name="image" content={route?.fields?.SeoImage?.value?.src} />
        <meta name="og:image" content={route?.fields?.SeoImage?.value?.src} />
        <meta
          name="type"
          content={route?.fields?.SeoType?.value || "Section"}
        />
        <meta
          name="keywords"
          content={route?.fields?.SeoKeywords?.value || ALT_TITLE}
        />
        {!route?.fields?.isLowcorbonEnabled?.value && (
          <script {...SCRIPT_PROPS}>{`${Parser(
            context?.siteSettings?.script?.value
          )}`}</script>
        )}
        <link
          rel="alternate"
          hrefLang="en"
          href={`${context.siteSettings?.HostName?.value}/en${context.itemPath}`}
        />
        <link
          rel="alternate"
          hrefLang="ar"
          href={`${context.siteSettings?.HostName?.value}/ar${context.itemPath}`}
        />
        <link
          rel="canonical"
          href={`${context.siteSettings?.HostName?.value}/${context.language}${context.itemPath}`}
        />
        <link rel="preconnect" href="https://cdn.userway.org" />
        <link rel="preconnect" href="https://www.googletagmanager.com" />
        <link rel="preconnect" href="https://www.google-analytics.com" />
        <link rel="preconnect" href="https://www.youtube.com" />
        {/* <script
          async
          defer
          src="https://consent.cookiefirst.com/sites/cop28.com-f9966576-9029-4b1b-9d98-c7ea253bdbc8/consent.js"
        ></script> */}
      </Helmet>
      {/*
        VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
        If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
        For XM (CMS-only) apps, this should be removed.

        VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
      */}
      <VisitorIdentification />
      {/* root placeholder for the app, which we add components to using route data */}
      <ParallaxProvider>
        {showTakeOver && !takeOverVisited && (
          <TakeOver
            siteSettings={context?.siteSettings}
            onClose={() => {
              setShowTakeover(false);
              unlockScroll();
            }}
          />
        )}
        <div className="" dir={`${context.language === "en" ? "ltr" : "rtl"}`}>
          <Placeholder name="jss-header" rendering={route} />
          <Placeholder
            name="jss-main"
            rendering={route}
            isRtl={`${context.language === "en" ? false : true}`}
          ></Placeholder>
          <Placeholder name="jss-footer" rendering={route} />
        </div>
        {!route?.fields?.isLowcorbonEnabled?.value && (
          <>
            {context?.siteSettings?.Body?.value &&
              Parser(context?.siteSettings?.Body?.value)}
            {context?.siteSettings?.ChatBotScript?.value &&
              route?.fields?.IsChatBotEnabled?.value &&
              Parser(context?.siteSettings?.ChatBotScript?.value)}
          </>
        )}
        {/* {context?.siteSettings?.CookieIsConsentEnabled?.value && (
          <>
             <div
            className="fixed z-[9999] w-10 cursor-pointer end-5 bottom-20"
            onClick={() => setShowModal(!showModal)}
          >
            <Image
              field={context?.siteSettings?.CookieIcon}
              className="w-auto"
            />
          </div>

          <Modal
            open={showModal}
            style="light"
            onCloseModal={() => setShowModal(false)}
          >
            <div className="block items-start gap-8 p-5 md:flex">
              <div
                className="text-center shrink-0 relative m-auto"
                style={{ maxWidth: "600px" }}
              >
                <RichText field={context?.siteSettings?.CookieDescription} />
              </div>
            </div>
          </Modal> 
          </>
        )} */}
      </ParallaxProvider>
    </React.Fragment>
  );
};

// We don't want to re-render `Layout` when route is changed but layout data is not loaded
// Layout will be re-rendered only when layout data is changed
const propsAreEqual = (prevProps, nextProps) => {
  if (deepEqual(prevProps.route, nextProps.route)) return true;

  return false;
};

export default React.memo(Layout, propsAreEqual);
