import React from "react";
import { Parallax } from "react-scroll-parallax";
import { Text, Image, RichText } from "@sitecore-jss/sitecore-jss-react";
import { Helmet } from "react-helmet";

const ThreeColumn = ({ fields, params }) => {
  const bgColor = params?.BackgroundColor;

  return (
    <section className="relative overflow">
      <div className={`pb-5 bg-cover bg-${bgColor} md:px-10 px-4`}>
        <div className="absolute top-0 start-0 w-full h-full"></div>
        <div className="md:mx-7 pb-10 relative z-10">
          <div className="flex gap-10 md:flex-row flex-col md:pt-[200px] pt-[100px]">
            <div className="w-full md:w-1/3">
              <h1 className="uppercase md:w-5/6 w-full text-white font-[500] px-5 h3">
                <RichText field={fields?.Title} />
              </h1>
            </div>
            <div className="w-full md:w-2/3 pb-5">
              <div className="text-white flex">
                <RichText field={fields?.Description} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute z-0 end-0 bottom-[-95px] hidden md:block">
        <Image field={fields?.Image} />
      </div>
      <Helmet>
        <meta name="image" content={fields?.Image?.value?.src} />
        <meta name="og:image" content={fields?.Image?.value?.src} />
      </Helmet>
    </section>
  );
};

export default ThreeColumn;
