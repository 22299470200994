import React from "react";
import { RichText } from "@sitecore-jss/sitecore-jss-react";

const ImageCard = ({ data, onSelect }) => {

  const getFormatedDate = (date) => {
    const newDate = new Date(date);
    const day = newDate.getDate();
    const month = newDate.toLocaleString("default", { month: "short" });
    const year = newDate.getFullYear().toString().slice(2);

    return day + " " + month + " " + year;
  };

  return (
    <button 
      href={data?.CTA?.url}
      onClick={onSelect}
    >
      <div
        className="bg-cover bg-center bg-no-repeat p-2 h-[200px]"
        style={{
          backgroundImage: `url(${data?.Image?.src})`,
        }}
      >
        {data?.StartDate?.rendered && (
          <div className="px-2 py-1  w-fit  rounded-full text-base text-tumeric-300 bg-tumeric-100">
            {getFormatedDate(data?.StartDate?.rendered)}
          </div>
        )}
      </div>

      <RichText
        field={data?.Description}
        className="text-bluedark text-base mt-1 text-left"
      />
    </button>
  );
};

export default ImageCard;
