import React from "react";
import { RichText } from "@sitecore-jss/sitecore-jss-react";
import { Helmet } from "react-helmet";

const SingleColumn = ({ fields, params, isMobile }) => {
  const bgColor = params?.BackgroundColor;

  return (
    <section className="">
      <div className={`bg-${bgColor}`}>
        <div className="container">
          <h1 className="h3">
            <RichText
              field={fields?.Title}
              className={`pt-[150px] pb-24  bg-no-repeat md:bg-right-bottom bg-bottom rtl:md:bg-left-bottom text-white font-[500]`}
              style={{ backgroundImage: `url(${fields?.Image?.value?.src})` }}
            />
          </h1>
        </div>
      </div>
      <Helmet>
        <meta name="image" content={fields?.Image?.value?.src} />
        <meta name="og:image" content={fields?.Image?.value?.src} />
      </Helmet>
    </section>
  );
};

export default SingleColumn;
