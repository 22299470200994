import React, { useRef } from 'react';
import { Image } from '@sitecore-jss/sitecore-jss-react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Slider from 'react-slick';
// eslint-disable-next-line import/no-extraneous-dependencies
import "slick-carousel/slick/slick.css";
// eslint-disable-next-line import/no-extraneous-dependencies
import "slick-carousel/slick/slick-theme.css";

const ImageGallery = ({ fields }) => {

  const sliderRef = useRef(null);
    const settings = {
        dots: false,
        infinite: false,
        autoplay: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
        ],
    };

    return(
        <section className="container morpheus-den-gradient color-block">
            <Slider {...settings} ref={sliderRef} className="-mx-3 mb-8">
            {fields?.cards?.map((value, index) => (
              <div key={index} className="px-3">
                  <div className={`bg-gradient-to-b from-10% to-80% ${index===0?'dark:from-[#FFF0F2] dark:to-[#690812]': index===1?'dark:from-[#E5DFCF] dark:to-[#292D0E]':'dark:from-[#223926] dark:to-[#A8A1A0]'}`}>
                    <Image field={value?.fields?.Image} className="w-full h-[520px] md:h-[594px] dark:opacity-0"/>
                  </div>
              </div>
            ))}
            </Slider>
        </section>
    )
};

export default ImageGallery;
