import React from 'react'

const StartUpCard = ({data, clickHander}) => {

    return (
        <div 
            className='h-full flex flex-col min-h-[138px] justify-center p-4 text-blueDark-500 hover:bg-[rgba(190,226,213,0.10)] hover:text-blueDark-100 rounded-[16px] cursor-pointer'
            onClick={clickHander}
        >
            {
                data.ProfileImage?.src ?
                    <img src={data.ProfileImage?.src} width='146px' height='auto' className='mb-3'/> :
                    null
            }
            <div className='text-[20px] leading-[24px] font-[500]'>
                {data.Title?.value}
            </div>
        </div>
    )
}

export default StartUpCard
