import React from "react";
import { RichText, Image, Link } from "@sitecore-jss/sitecore-jss-react";
import CTA from "../../../Common/CTA";

const CTAButtonList = ({ fields }) => {
  return (
    <section className=" bg-white relative ">
      <div className={`h-full`}>
        <div className={`md:flex items-center md:min-h-[789px]`}>
          <div
            className={`basis-full md:basis-7/12 md:mt-0 text-end md:w-8/12 h-full top-0 bg-gradient-to-b from-10% to-80% dark:from-[#1A1B21] dark:to-[#CE7C13]`}
          >
            <Image
              field={fields?.Image}
              className="w-full object-cover dark:opacity-0"
              loading="lazy"
            />
          </div>
          <div className="basis-full md:basis-4/12 md:ps-20 px-6 h-full py-20">
            <h3 className={`mb-11 font-[500] uppercase text-bluedark`}>
              <RichText field={fields?.Title} />
            </h3>
            <div className={`md:mb-8 text-lg md:text-xl  text-bluedark`}>
              <RichText field={fields?.Description} />
            </div>
            <div className="mt-5 md:flex gap-5">
              {fields?.CTAButtonList &&
                fields?.CTAButtonList.map((item, i) => {
                  return (
                    <div key={i}>
                      {item?.fields?.CTALink?.value?.href && (
                        <Link field={item?.fields?.CTALink}>
                          <CTA
                            color={i === 1 && "brand"}
                            className={`w-[176px] ${i === 1 && "md:mt-0 mt-5"}`}
                          >
                            {item?.fields?.CTALink?.value?.text}
                          </CTA>
                        </Link>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CTAButtonList;
