import React from "react";
import parse from "html-react-parser";
import CTA from "../../../Common/CTA";
import { Link } from "@sitecore-jss/sitecore-jss-react";
const CardStack = ({ fields, params }) => (
  <div className={`py-7 md:py-14 text-white bg-${params.BackgroundColor}`}>
    <div className="container">
      <div className="flex flex-wrap box-border -mx-3 mb-7 md:mb-14">
        <div
          className={`basis-full  px-3 ${
            params.Alignment && params.Alignment === "single-column"
              ? "md:basis-full mb-4 text-center"
              : "md:basis-3/12"
          }`}
        >
          <h3 className="mb-6 md:mb-0 text-brand">
            {parse(fields?.Title?.value)}
          </h3>
        </div>
        <div
          className={`basis-full px-3 ${
            params.Alignment && params.Alignment === "single-column"
              ? "md:basis-full text-center"
              : " md:basis-9/12 "
          }`}
        >
          <p className="text-lg md:text-xl text-blueDark-500">
            {parse(fields?.Description?.value)}
          </p>
        </div>
      </div>
      {fields?.cards?.length && (
        <>
          <div className="flex flex-wrap box-border -mx-3 mb-10 md:mb-20">
            {fields?.cards.map((card, i) => {
              return (
                <div
                  key={i}
                  className="basis-full md:basis-4/12 px-3  mb-6 md:mb-0"
                >
                  <div
                    className={`px-4 md:px-6 py-12 md:py-14  text-blueDark-500 rounded-2xl md:min-h-[350px] h-full bg-${card.fields?.BackgroundColor?.value}`}
                  >
                    <img
                      src={card.fields?.Image?.value?.src}
                      className="mb-6"
                    />
                    <h5 className="mb-6 text-[20px] leading-[24px]">
                      {card.fields?.Title?.value}
                    </h5>
                    <div className="text-dark-green-900">
                      {parse(card.fields?.Description?.value)}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
      {fields?.CTALink?.value?.href && (
        <div className="text-center">
          <CTA className="text-bluedark">
            <Link field={fields?.CTALink}></Link>
          </CTA>
        </div>
      )}
    </div>
  </div>
);

export default CardStack;
