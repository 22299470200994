import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import { RichText, Image, Link } from "@sitecore-jss/sitecore-jss-react";
import CTA from "../../../Common/CTA";

export default function TopNonNewsType({ title_en, title_ar, fields }) {
  const [lowCarbon, setLowCarbon] = useState(null);
  useEffect(() => {
    setLowCarbon(window.localStorage.getItem("low-carbon-mode") === "true");
  }, []);
  return (
    <div className="w-full bg-tumeric-50 py-14">
      {lowCarbon !== null && (
        <section className="container">
          <div>
            <h3 className="mb-8 text-[40px] leading-[102%] text-brand font-[500] uppercase rtl:hidden">
              {title_en}
            </h3>
            <h3 className="mb-8 text-[40px] leading-[102%] text-brand font-[500] uppercase rtl:block hidden">
              {title_ar}
            </h3>
            <div className="mt-10 flex flex-wrap gap-y-5 justify-center -mx-3">
              {fields?.data?.GQLResult?.results?.items
                .slice(0, 4)
                .map((item, index) => {
                  return (
                    <a
                      key={index}
                      href={item.item?.url}
                      className="basis-full md:basis-6/12 lg:basis-3/12 px-3 text-base relative flex flex-col"
                    >
                      {!lowCarbon && (
                        <Image
                          field={item?.item?.children[0]?.children[0]?.Image}
                          loading="lazy"
                          className="h-[230px] object-cover"
                        />
                      )}
                      <div className="hidden dark:block h-[230px] object-cover dark:bg-yellow-gradient" />
                      <div className="flex gap-2 absolute top-0 mt-2 ms-2">
                        {item?.item?.StartDate?.rendered && (
                          <div className="text-tumeric-300 px-3 py-1 bg-tumeric-100 rounded-full text-base font-medium">
                            {new Date(
                              item?.item?.StartDate?.rendered
                            ).getDate()}{" "}
                            {new Date(
                              item?.item?.StartDate?.rendered
                            ).toLocaleString("default", {
                              month: "short",
                            })}{" "}
                            {new Date(item?.item?.StartDate?.rendered)
                              .getFullYear()
                              .toString()
                              .slice(-2)}
                          </div>
                        )}
                        {item?.item?.EndDate?.rendered && (
                          <div className="text-tumeric-300 px-3 py-1 bg-tumeric-100 rounded-full text-base font-medium">
                            {new Date(item?.item?.EndDate?.rendered).getDate()}{" "}
                            {new Date(
                              item?.item?.EndDate?.rendered
                            ).toLocaleString("default", {
                              month: "short",
                            })}{" "}
                            {new Date(item?.item?.EndDate?.rendered)
                              .getFullYear()
                              .toString()
                              .slice(-2)}
                          </div>
                        )}
                      </div>
                      <div>
                        <RichText
                          field={item?.item?.children[0]?.children[0]?.Title}
                          className="text-brand font-bold mt-1"
                        />
                        {/* <RichText 
                          field={item?.item?.Description} 
                          className="mt-1"
                        /> */}
                      </div>
                      {/* {item?.item?.CTA?.url && (
                        <CTA className=" mt-4 flex w-fit items-center">
                          <a href={item?.item?.CTA?.url}>
                            {item?.item?.CTA?.text}
                          </a>
                        </CTA>
                      )} */}
                    </a>
                  );
                })}
              {fields?.data?.GQLSource?.CTA?.url && (
                <CTA className="flex w-fit items-center" color="brand">
                  <a href={fields?.data?.GQLSource?.CTA?.url}>
                    {fields?.data?.GQLSource?.CTA?.text}
                  </a>
                </CTA>
              )}
            </div>
          </div>
        </section>
      )}
    </div>
  );
}
