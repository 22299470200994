import React from "react";
import { SwiperSlide, Swiper } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import TranslatedWord from "../../../Common/DictionaryTexts";
const DateFilter = ({ dates, selectedDate, set_selectedDate, isRtl }) => {
  const [disabledBtn, set_disabledBtn] = React.useState(0); // 0: prev button, 1: none, 2: next button

  const toNext = () => {
    const nextButton = document.querySelector(
      "#EventListDateFilter .swiper-button-next"
    );
    nextButton?.click();
  };

  const toPrev = () => {
    const prevButton = document.querySelector(
      "#EventListDateFilter .swiper-button-prev"
    );
    prevButton?.click();
  };

  const toggleDisableSwiperBtns = () => {
    const nextButton = document.querySelector(
      "#EventListDateFilter .swiper-button-next"
    );
    const prevButton = document.querySelector(
      "#EventListDateFilter .swiper-button-prev"
    );
    if (prevButton.classList.contains("swiper-button-disabled")) {
      set_disabledBtn(0);
    } else if (nextButton.classList.contains("swiper-button-disabled")) {
      set_disabledBtn(2);
    } else {
      set_disabledBtn(1);
    }
  };

  return (
    <div id="EventListDateFilter" className="w-full relative">
      <div className="w-full flex justify-end space-x-6 rtl:space-x-reverse mb-6 pt-4 lg:pt-0">
        <button
          onClick={toPrev}
          className={`${isRtl === "true" ? "" : "rotate-180"} ${
            disabledBtn === 0 ? "opacity-50 pointer-events-none" : ""
          }`}
        >
          <svg
            width="84"
            height="40"
            viewBox="0 0 84 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M52.5684 12L60.5684 20L52.5684 28"
              stroke="#173330"
              strokeWidth="2.01031"
            />
            <rect
              x="1.00515"
              y="1.00515"
              width="81.9897"
              height="37.9897"
              rx="18.9948"
              stroke="#173330"
              strokeWidth="2.01031"
            />
            <path
              d="M20.6973 19.8789H60.6973"
              stroke="#173330"
              strokeWidth="2.01031"
            />
          </svg>
        </button>
        <button
          onClick={toNext}
          className={`${isRtl === "true" ? "rotate-180" : ""} ${
            disabledBtn === 2 ? "opacity-50 pointer-events-none" : ""
          }`}
        >
          <svg
            width="84"
            height="40"
            viewBox="0 0 84 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M52.5684 12L60.5684 20L52.5684 28"
              stroke="#173330"
              strokeWidth="2.01031"
            />
            <rect
              x="1.00515"
              y="1.00515"
              width="81.9897"
              height="37.9897"
              rx="18.9948"
              stroke="#173330"
              strokeWidth="2.01031"
            />
            <path
              d="M20.6973 19.8789H60.6973"
              stroke="#173330"
              strokeWidth="2.01031"
            />
          </svg>
        </button>
      </div>
      <Swiper
        dir={isRtl === "true" ? "rtl" : "ltr"}
        className="w-full relative bg-white !p-3"
        spaceBetween={16}
        slidesPerView="auto"
        modules={[Navigation]}
        navigation={true}
        onSlideChange={toggleDisableSwiperBtns}
      >
        <SwiperSlide
          className={`!w-[101px] cursor-pointer py-2 rounded-[32px] ${
            selectedDate === "all"
              ? "bg-blueDark-50 text-blueDark-100 font-[500]"
              : "bg-tumeric-50 text-blueDark-500 hover:text-blueDark-100 hover:font-[500]"
          } text-center text-[20px] leading-[24px]`}
          onClick={() => set_selectedDate("all")}
        >
          <TranslatedWord EnWord="ALL" />
        </SwiperSlide>
        {dates?.map((date, index) => (
          <SwiperSlide
            key={index}
            className={`!w-fit last:pe-12`}
            onClick={() => set_selectedDate(index)}
          >
            <div
              className={`uppercase !w-[101px] cursor-pointer py-2 rounded-[32px] ${
                selectedDate === index
                  ? "bg-blueDark-50 text-blueDark-100 font-[500]"
                  : "bg-tumeric-50 text-blueDark-500 hover:text-blueDark-100 hover:font-[500]"
              } text-center text-[20px] leading-[24px]`}
              onClick={() => set_selectedDate(index)}
            >
              {date?.fields?.Value?.value}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="absolute bottom-3 -end-0 w-10 h-10 bg-gradient-to-l from-lightgrey z-10" />
    </div>
  );
};

export default DateFilter;
