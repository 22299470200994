import React, { useState } from "react";

export default function SingleLineText({ props }) {
  const [value, setValue] = useState(props.value);
  return (
    <>
      <div className="basis-full md:basis-6/12  mb-4 md:mb-14">
        <div className="w-full mt-4 min-h-[126px] grid items-stretch">
          <label className="w-full  font-medium mb-3 ">
            {props.field.model.title}
          </label>
          <input
            className={`w-full text-base bg-transparent px-4 py-3 md:py-5 border-b-2 outline-none ${
              props.errors.length ? "border-rose-900" : " border-current"
            }`}
            type="text"
            key={props.field.model.itemId}
            name={props.field.model.name}
            // pattern="^[A-Za-z][A-Za-z!@#$%^&amp;* ]*$"
            placeholder={props.field.model.placeholderText}
            value={props.value}
            // required={props.field.model.required}
            onChange={(e) => {
              setValue(e?.target?.value);
              props.onChange(
                props.field.valueField.name,
                e?.target?.value,
                props.isValid,
                []
              );
              e.target.classList.remove("border-rose-900");
            }}
          />
        </div>
        {props.errors?.map((error, i) => {
          return (
            <p className="my-2 text-rose-900" key={i}>
              {error}
            </p>
          );
        })}
      </div>
    </>
  );
}
