import React from "react";
// import Arrow_Right_Circle from "../../assets/images/arrow-right-circle.svg";
import Arrow_Right from "../../assets/images/arrow-right.svg";
import Arrow_Right_White from "../../assets/images/arrow-right-white.svg";
// import Arrow_Right_Circle_White from "../../assets/images/arrow-right-circle-white.svg";
import Arrow_Left_Circle from "../../assets/images/arrow-left-circle.svg";
import Arrow_Left_Circle_White from "../../assets/images/arrow-left-circle-white.svg";
import Download from "../../assets/images/download.svg";
import Download_White from "../../assets/images/download-white.svg";

export default function CTA(props) {
  return (
    <button
      onClick={props?.onClick || null}
      type={props?.type}
      disabled={props?.disabled}
      formNoValidate
      key={props?.key ? props?.key : "CTA"}
      className={`inline-flex border-0 items-center  group cursor-pointer ${
        props.color === "brand"
          ? "bg-brand hover:bg-gradient-to-b hover:from-[#173330] hover:via-[#145A50] hover:to-[#BEE2D5] text-white hover:text-white"
          : props.color === "blueDark"
          ? "bg-blueDark-600 hover:bg-gradient-to-t hover:from-[#29175B] hover:via-[#6099E5] hover:to-[#C4E1F0] text-white hover:text-blueDark-500"
          : props.color === "blue"
          ? "bg-blueCTA hover:bg-gradient-to-t hover:from-[#6099E5] hover:via-[#C4E1F0] hover:to-[#e7f4fb] text-white hover:text-blueDark-500"
          : props.color === "blueDownLoad"
          ? "bg-blueDark-600 hover:bg-gradient-to-t hover:from-[#29175B] hover:via-[#6099E5] hover:to-[#C4E1F0] text-white hover:text-blueDark-500"
          : "bg-tumeric-200 hover:bg-gradient-to-b hover:from-[#CF4800] hover:via-[#F1B460] hover:to-[#ffffff] hover:text-blueDark-500"
      } font-medium relative z-10  ${props?.small ? "px-4 py-2" : "py-3 px-8 "} 
         ${props.className}`}
    >
      {!props?.icon && !props?.download && !props?.backIcon && (
        <>
          <img
            src={Arrow_Right}
            alt=""
            className={`me-2 ${
              props.color === "brand"
                ? "!hidden group-hover:!hidden"
                : props.color === "blue"
                ? "!hidden group-hover:!block"
                : props.color === "blueDark"
                ? "!hidden group-hover:!block"
                : "!block group-hover:!block"
            } 
            ${
              props.external
                ? "-rotate-45 rtl:-rotate-[135deg]"
                : "rtl:rotate-180"
            }`}
          />
          <img
            src={Arrow_Right_White}
            alt=""
            className={`me-2 ${
              props.color === "brand"
                ? "!block group-hover:!block"
                : props.color === "blue"
                ? "!block group-hover:!hidden"
                : props.color === "blueDark"
                ? "!block group-hover:!hidden"
                : "!hidden group-hover:!hidden"
            } ${
              props.external
                ? "-rotate-45 rtl:-rotate-[135deg]"
                : "rtl:rotate-180"
            }`}
          />
        </>
      )}
      {!props?.icon && !props?.download && props?.backIcon && (
        <>
          <img
            src={Arrow_Left_Circle}
            alt=""
            className={`me-2 rtl:rotate-180 ${
              props.color === "brand" ||
              props.color === "blueDark" ||
              props.color === "blue" ||
              props.color === "blueDownLoad"
                ? "hidden group-hover:block"
                : "group-hover:hidden"
            }`}
          />
          <img
            src={Arrow_Left_Circle_White}
            alt=""
            className={`me-2 rtl:rotate-180 ${
              props.color === "brand" ||
              props.color === "blueDark" ||
              props.color === "blue" ||
              props.color === "blueDownLoad"
                ? "group-hover:hidden"
                : "hidden group-hover:block"
            }`}
          />
        </>
      )}

      {props?.children}

      {!props?.icon && props?.download && (
        <>
          <img
            src={Download}
            alt=""
            className={`ms-2 ${
              props.color !== "blueDownLoad"
                ? props.color === "brand"
                  ? "!hidden group-hover:!hidden"
                  : "group-hover:!block"
                : "!hidden group-hover:!block"
            }`}
          />
          <img
            src={Download_White}
            alt=""
            className={`ms-2 ${
              props.color !== "blueDownLoad"
                ? props.color === "brand"
                  ? "!block group-hover:!block"
                  : "hidden group-hover:!hidden"
                : "!block group-hover:!hidden"
            }`}
          />
        </>
      )}
    </button>
  );
}
