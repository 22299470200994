import React, { useEffect, useState, useLayoutEffect } from "react";
import { createPortal } from "react-dom";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import FilterPad from "./filterPad";
import DateFilter from "./dateFilter";
import EventCard from "./eventCard";
import LoadingCard from "./loadingCard";
import { useQuery, gql } from "@apollo/client";
import TranslatedWord from "../../../Common/DictionaryTexts";

const GetEventList = gql`
  query GetEventList(
    $datasource: String!
    $language: String!
    $keyword: String!
    $first: Int!
    $after: String!
    $fieldsEqual: [ItemSearchFieldQuery]
    $multisort: [ItemSearchFieldQuery]
  ) {
    GQLResult: extendedsearch(
      rootItem: $datasource
      language: $language
      index: "cop28_website_{database}_index"
      keyword: $keyword
      first: $first
      after: $after
      fieldsEqual: $fieldsEqual
      multisort: $multisort
    ) {
      results {
        items {
          item {
            url
            Title: field(name: "EventTitle") {
              value
            }
            Description: field(name: "EventDescription") {
              value
            }
            EventDate: field(name: "EventDate") {
              value
            }
            EventStartTime: field(name: "EventStartTime") {
              value
            }
            EventEndTime: field(name: "EventEndTime") {
              value
            }
            EventPartners: field(name: "EventPartners") {
              value
            }
            EventSpeakers: field(name: "EventSpeakers") {
              value
            }
            EventTags: field(name: "EventTags") {
              value
            }
            EventTheme: field(name: "EventTheme") {
              value
            }
            EventVenue: field(name: "EventVenue") {
              value
            }
            EventLocation: field(name: "EventLocation") {
              value
            }
            IsPublicEvent: field(name: "EventIsPublicEvent") {
              value
            }
          }
        }
        pageInfo {
          hasNextPage
        }
      }
    }
  }
`;
const itemsPerPage = [1, 1, 1, 1];

const EventListing = (props) => {
  const contextFields = props.sitecoreContext?.route?.fields;

  let fieldsEqual = [{ name: "IsGlobalEventDetailPage", value: "1" }];

  if (contextFields?.EventDailyDateFilter?.value) {
    try {
      fieldsEqual.push(JSON.parse(contextFields?.EventDailyDateFilter?.value));
    } catch {
      console.log("Error: invalid JSON");
    }
  }
  const { loading, error, data, networkStatus, refetch } = useQuery(
    GetEventList,
    {
      variables: {
        datasource: props.rendering.dataSource,
        language: "en",
        keyword: "",
        first: itemsPerPage.length,
        after: "0",
        $fieldsEqual: fieldsEqual,
        $multisort: [
          { name: "EventDate", value: "asc" },
          { name: "EventStartTime", value: "asc" },
          { name: "customsort", value: "asc" },
        ],
      },
      notifyOnNetworkStatusChange: true, // This is an importantflag that makes loading status get changed when refetch
    }
  );

  /** networkStatus ---------
    1: loading
    2: setVariables
    3: fetchMore
    4: refetch
    5: poll
    6: ready
  */

  const theme = {
    title: "Theme",
    items: contextFields?.EventThemes,
  };
  const partner = {
    title: "Partners",
    items: contextFields?.EventPartners,
  };
  // ZONE ------
  const zone = {
    title: "Zones",
    items: contextFields?.EventZone,
  };
  const type = {
    title: "Types",
    items: contextFields?.EventCategoryType,
  };
  const venue = {
    title: "Venues",
    items: contextFields?.EventVenue,
  };
  // const eventAccess = {
  //   title: "Event Access",
  //   items: contextFields?.EventAccess,
  // };
  const dates = contextFields?.EventDates ? contextFields?.EventDates : [];

  const [results, set_results] = useState(
    props?.fields?.data?.GQLResult?.results?.items || []
  );
  const [filterPadShow, set_filterPadShow] = useState(false);
  const [selectedFilters, set_selectedFilters] = useState();
  const [selectedDate, set_selectedDate] = useState("all");
  const [pageNum, set_pageNum] = useState(0);
  const [hasNextPage, set_hasNextPage] = useState(
    props?.fields?.data?.GQLResult?.results?.pageInfo?.hasNextPage
  );
  const [isDesktopView, set_isDesktopView] = useState(true); // This flag is useful for syncing between filterPads in mobile and desktop view
  const [isFirstLoad, set_isFirstLoad] = useState(true);
  const [fetching, set_fetching] = useState(false);
  const [resetFilter, set_resetFilter] = useState(false);

  const loadMore = () => {
    refetchResults(pageNum + 1);
  };

  const clearFilter = () => {
    set_resetFilter(true);
    set_selectedDate("all");
    set_pageNum(0);
  };

  const refetchResults = (pageNum) => {
    set_fetching(true);
    if (!pageNum) {
      set_results([]);
      set_hasNextPage(false);
    }
    set_pageNum(pageNum);

    let fieldsEqual = [{ name: "IsGlobalEventDetailPage", value: "1" }];

    let EventTheme = "";
    selectedFilters?.theme?.map((item) => {
      EventTheme += theme.items[item]?.id.replace(/[^a-zA-Z0-9{}]+/g, "") + ",";
    });
    if (EventTheme) {
      fieldsEqual.push({
        name: "EventTheme",
        value: `in:${EventTheme}`,
      });
    }

    let EventPartners = "";
    selectedFilters?.partner?.map((item) => {
      EventPartners +=
        partner.items[item]?.id.replace(/[^a-zA-Z0-9{}]+/g, "") + ",";
    });
    if (EventPartners) {
      fieldsEqual.push({
        name: "EventPartners",
        value: `in:${EventPartners}`,
      });
    }

    // if (selectedFilters?.eventAccess?.length === 1) {
    //   fieldsEqual.push({
    //     name: "EventIsPublicEvent",
    //     value: `${
    //       eventAccess.items[selectedFilters?.eventAccess[0]]?.fields?.Key
    //         ?.value === "Private"
    //         ? ""
    //         : "1"
    //     }`,
    //   });
    // }

    // ZONE ------
    if (selectedFilters?.zone?.length === 1) {
      fieldsEqual.push({
        name: "EventZone",
        value: `${zone.items[selectedFilters?.zone[0]]?.id.replace(
          /[^a-zA-Z0-9{}]+/g,
          ""
        )}`,
      });
    }

    let EventVenue = "";
    selectedFilters?.venue?.map((item) => {
      EventVenue += venue.items[item]?.id.replace(/[^a-zA-Z0-9{}]+/g, "") + ",";
    });
    if (EventVenue) {
      fieldsEqual.push({
        name: "EventVenue",
        value: `in:${EventVenue}`,
      });
    }

    let EventCategoryType = "";
    selectedFilters?.type?.map((item) => {
      EventCategoryType +=
        type.items[item]?.id.replace(/[^a-zA-Z0-9{}]+/g, "") + ",";
    });
    if (EventCategoryType) {
      fieldsEqual.push({
        name: "EventCategoryType",
        value: `in:${EventCategoryType}`,
      });
    }

    if (selectedDate !== "all") {
      let EventDate = dates[selectedDate]?.fields?.Key?.value;
      fieldsEqual.push({
        name: "EventDate",
        value: `${EventDate}`,
      });
    }
    if (contextFields?.EventDailyDateFilter?.value) {
      try {
        fieldsEqual.push(
          JSON.parse(contextFields?.EventDailyDateFilter?.value)
        );
      } catch {
        console.log("Error: invalid JSON");
      }
    }
    console.log("=============== Fetching the GQL Results ===============\n");
    refetch({
      keyword: selectedFilters?.textFilter,
      first: itemsPerPage.length,
      after: (itemsPerPage.length * pageNum).toString(),
      fieldsEqual: fieldsEqual,
      multisort: [
        { name: "EventDate", value: "asc" },
        { name: "EventStartTime", value: "asc" },
        { name: "customsort", value: "asc" },
      ],
    });
  };

  useEffect(() => {
    if (!resetFilter && selectedFilters) {
      if (!isFirstLoad) {
        refetchResults(0);
        const container = document.getElementById("EventList");
        container?.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [selectedFilters, selectedDate]);

  useEffect(() => {
    if (!loading) {
      if (isFirstLoad) {
        set_isFirstLoad(false);
      } else {
        console.log(
          "=============== Finished Fetching ===============",
          data?.GQLResult?.results?.items?.length
        );
        set_hasNextPage(data?.GQLResult?.results?.pageInfo?.hasNextPage);
        const newResults = data?.GQLResult?.results?.items || [];
        if (pageNum) {
          set_results([...results, ...newResults]);
        } else {
          set_results(newResults);
        }
        set_fetching(false);
      }
    }
  }, [loading]);

  useEffect(() => {
    set_isDesktopView(window.innerWidth > 1023);
    window.addEventListener("resize", () =>
      set_isDesktopView(window.innerWidth > 1023)
    );
    return () =>
      window.removeEventListener("resize", () =>
        set_isDesktopView(window.innerWidth > 1023)
      );
  }, []);

  useEffect(() => {
    if (filterPadShow) {
      document.getElementsByTagName("body")[0].classList.add("overflow-hidden");
    } else {
      document
        .getElementsByTagName("body")[0]
        .classList.remove("overflow-hidden");
    }
  }, [filterPadShow]);

  return (
    <div id="EventList" className="w-full bg-lightgrey pt-2 lg:pt-4 pb-14">
      <div className="container">
        <div className="w-full lg:flex lg:space-x-6">
          {isDesktopView ? (
            <div className="w-[302px] h-fit bg-white px-6 mt-[64px] py-6">
              <FilterPad
                theme={theme}
                // eventAccess={eventAccess}
                partner={partner}
                // ZONE ------
                zone={zone}
                venue={venue}
                type={type}
                selectedFilters={selectedFilters}
                set_selectedFilters={set_selectedFilters}
                resetFilter={resetFilter}
                set_resetFilter={set_resetFilter}
              />
              <div className="w-full pt-6 border-0 border-t border-blueDark-500">
                <a
                  href={contextFields.CTAbutton?.value?.href}
                  className="w-full py-4 px-[30px] bg-tumeric-200 flex space-x-[10px] rtl:space-x-reverse items-center"
                >
                  <div className="text-[16px] font-[500] leading-[18px] text-blueDark-500">
                    {contextFields.CTAbutton?.value?.text || "Download Program"}
                  </div>
                  <img
                    src={contextFields.CTAbuttonIcon?.value?.src}
                    width={24}
                    height={24}
                  />
                </a>
              </div>
            </div>
          ) : null}
          <div className="w-full lg:w-[calc(100%-326px)]">
            <div className="w-full lg:p-3 lg:py-0">
              <DateFilter
                dates={dates}
                selectedDate={selectedDate}
                set_selectedDate={set_selectedDate}
                isRtl={props?.isRtl}
              />
              {selectedFilters ? (
                <React.Fragment>
                  {!results?.length && !fetching ? (
                    <div className="w-full flex flex-col items-center p-14">
                      <div className="w-fit p-5 bg-blueDark-50">
                        <svg
                          width="72"
                          height="72"
                          viewBox="0 0 72 72"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect width="72" height="72" fill="#BEE2D5" />
                          <path
                            d="M35 46C41.0751 46 46 41.0751 46 35C46 28.9249 41.0751 24 35 24C28.9249 24 24 28.9249 24 35C24 41.0751 28.9249 46 35 46Z"
                            stroke="#118170"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M48.7333 48.7333L43 43"
                            stroke="#118170"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M31 39L39 31"
                            stroke="#118170"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M31 31L39 39"
                            stroke="#118170"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="text-[32px] text-center font-[500] uppercase mt-6 text-brand">
                        <TranslatedWord EnWord="No Search Results" />
                      </div>
                      <div className="text-[20px] font-[500] leading-[24px] mt-6 text-blueDark-500 text-center">
                        <TranslatedWord EnWord="We couldn't find what you're looking for, try searching for something else." />
                      </div>
                      <button
                        onClick={clearFilter}
                        className="bg-tumeric-200 px-[30px] py-4 flex items-center space-x-[10px] rtl:space-x-reverse mt-6"
                      >
                        <span>
                          <TranslatedWord EnWord="Reset Search" />
                        </span>
                        <svg
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.5 2H14.5"
                            stroke="#173330"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12.5 14V10"
                            stroke="#173330"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M4.5 13.0002C4.74486 11.0562 5.69466 9.26965 7.16925 7.97939C8.64384 6.68912 10.5407 5.98489 12.5 6.00025C13.794 6.00162 15.0684 6.31689 16.2139 6.91901C17.3593 7.52112 18.3416 8.39212 19.0764 9.45727C19.8113 10.5224 20.2768 11.7499 20.433 13.0345C20.5892 14.3191 20.4315 15.6224 19.9733 16.8326C19.5152 18.0429 18.7703 19.1239 17.8025 19.983C16.8348 20.8421 15.6731 21.4536 14.4171 21.765C13.1611 22.0765 11.8482 22.0787 10.5912 21.7713C9.33422 21.4639 8.17054 20.8562 7.2 20.0002L4.5 17.6002"
                            stroke="#173330"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9.5 17H4.5V22"
                            stroke="#173330"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                  ) : (
                    results.map((item, index) => {
                      let isSameTime = false;
                      if (index > 0) {
                        isSameTime =
                          results[index].item?.EventStartTime?.value ===
                          results[index - 1].item?.EventStartTime?.value;
                      }
                      return (
                        <EventCard
                          key={`card_${index}`}
                          data={item.item}
                          isSameTime={isSameTime}
                          hideTimeLine={selectedDate === "all"}
                          dates={contextFields?.EventDates || []}
                          themes={contextFields?.EventThemes || []}
                          partners={contextFields?.EventPartners || []}
                          speakers={contextFields?.EventSpeakers || []}
                          venues={contextFields?.EventVenue || []}
                        />
                      );
                    })
                  )}
                  {!isFirstLoad && fetching ? (
                    <React.Fragment>
                      {itemsPerPage.map((item, index) => (
                        <LoadingCard
                          key={`loading_${index}`}
                          speakers={index < 2}
                          partners={!(index % 2)}
                        />
                      ))}
                    </React.Fragment>
                  ) : hasNextPage ? (
                    <div className="w-full mt-14 flex justify-center">
                      <button
                        className="px-8 py-4 flex items-center justify-center space-x-[10px]rtl:space-x-reverse bg-brand"
                        onClick={loadMore}
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_2631_34800)">
                            <path
                              d="M1.66683 10.0003C1.66683 14.6027 5.39779 18.3337 10.0002 18.3337C14.6025 18.3337 18.3335 14.6027 18.3335 10.0003C18.3335 5.39795 14.6025 1.66699 10.0002 1.66699C5.39779 1.66699 1.66683 5.39795 1.66683 10.0003Z"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M6.66683 10L10.0002 13.3333L13.3335 10"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M10 6.66699L10 13.3337"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_2631_34800">
                              <rect
                                width="20"
                                height="20"
                                fill="white"
                                transform="translate(20) rotate(90)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                        <span className="text-[16px] leading-[18px] font-[500] text-white uppercase">
                          {contextFields.LoadMoreText?.value || (
                            <TranslatedWord EnWord="Load More" />
                          )}
                        </span>
                      </button>
                    </div>
                  ) : null}
                </React.Fragment>
              ) : null}
            </div>
          </div>
        </div>
        <button
          onClick={() => set_filterPadShow(!filterPadShow)}
          className={`${
            filterPadShow ? "hidden" : "lg:hidden"
          } w-16 h-16 rounded-full bg-brand sticky bottom-16 -mt-16 end-full flex justify-center items-center cursor-pointer z-10 -translate-y-14`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
          >
            <path
              d="M4.33398 10.626H36.334"
              stroke="white"
              strokeWidth="3.55556"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.33398 20.001H32.334"
              stroke="white"
              strokeWidth="3.55556"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12.334 30H28.334"
              stroke="white"
              strokeWidth="3.55556"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>

      {!isDesktopView
        ? createPortal(
            <div
              className={`${
                filterPadShow ? "" : "hidden"
              } lg:hidden fixed top-0 start-0 z-[1000000] w-full h-screen bg-white bg-opacity-30 flex items-end`}
            >
              <div
                className="w-full h-full absolute top-0 start-0"
                onClick={() => set_filterPadShow(!filterPadShow)}
              />
              <div className="w-full relative bg-white z-10 shadow-[14px_7px_14px_14px_rgba(0,0,0,0.25)] pt-[72px]">
                <div className="absolute w-full bg-white py-6 top-0 start-0">
                  <div className="container flex flex-row-reverse">
                    <button
                      onClick={() => set_filterPadShow(!filterPadShow)}
                      className="w-fit h-fit"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18 6L6 18"
                          stroke="black"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6 6L18 18"
                          stroke="black"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="w-full h-fit max-h-[calc(100vh-246px)] overflow-y-auto">
                  <div className="container pb-14">
                    <FilterPad
                      theme={theme}
                      // eventAccess={eventAccess}
                      partner={partner}
                      // ZONE ------
                      zone={zone}
                      venue={venue}
                      type={type}
                      selectedFilters={selectedFilters}
                      set_selectedFilters={set_selectedFilters}
                      resetFilter={resetFilter}
                      set_resetFilter={set_resetFilter}
                    />
                    <div className="w-full pt-6 pb-16 border-0 border-t border-blueDark-500">
                      <a
                        href={contextFields.CTAbutton?.value?.href}
                        className="w-full py-4 px-[30px] bg-tumeric-200 flex space-x-[10px] rtl:space-x-reverse justify-center items-center"
                      >
                        <div className="text-[16px] font-[500] leading-[18px] text-blueDark-500">
                          {contextFields.CTAbutton?.value?.text}
                        </div>
                        <img
                          src={contextFields.CTAbuttonIcon?.value?.src}
                          width={24}
                          height={24}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>,
            document.getElementsByTagName("body")[0]
          )
        : null}
    </div>
  );
};

export default withSitecoreContext()(EventListing);
