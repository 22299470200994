import React, { useState } from "react";
import { RichText } from "@sitecore-jss/sitecore-jss-react";
import { Helmet } from "react-helmet";
import Collapser from "../../../../Common/Collapser";

const Accordion = ({ fields }) => {
  const [readMore, set_readMore] = useState(false);

  return (
    <section>
      <div className={`bg-blueDark-100`}>
        <div className="container">
          <RichText
            field={fields?.Title}
            className={`pt-[150px] text-[56px] leading-[57px] text-white font-[500]`}
          />
          <div className="pb-16 pt-10">
            <Collapser summary={fields?.Description} color={"brand"}>
              <div className="text-white">
                <RichText field={fields?.Subtitle} />
              </div>
            </Collapser>
          </div>
        </div>
      </div>
      <Helmet>
        <meta name="image" content={fields?.Image?.value?.src} />
        <meta name="og:image" content={fields?.Image?.value?.src} />
      </Helmet>
    </section>
  );
};

export default Accordion;
