import React, { useEffect } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import Countdown from "react-countdown";
import TranslatedWord from "../../../Common/DictionaryTexts";

const Timer = ({ fields, params }) => {
  const handleOnTick = () => {
    document.querySelectorAll(".disableInlineStyle").forEach((el) => {
      el.removeAttribute("style");
    });
  };

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    const TimerItem = function ({ time, value }) {
      return (
        <div
          className="text-center px-3 md:flex-col flex justify-around items-center font-bold"
          data-uw-ignore-s17
          data-uw-ignore-s14
        >
          <p
            className="md:!text-7xl !text-9xl lg:!text-[80px] disableInlineStyle"
            data-uw-ignore-s17
            data-uw-ignore-s14
          >
            {value}
          </p>
          <span
            className=" pt-4 inline-block disableInlineStyle"
            data-uw-ignore-s17
            data-uw-ignore-s14
          >
            <TranslatedWord EnWord={time} />
          </span>
        </div>
      );
    };
    return (
      <>
        <div className="uppercase flex text-white justify-center items-center flex-wrap">
          <div className="basis-full mb-6 lg:mb-0 lg:basis-4/12">
            <h3> {fields?.Title?.value}</h3>
          </div>
          <div className="basis-full lg:basis-8/12">
            <div className="flex md:flex-row flex-col md:justify-between justify-center -mx-3">
              <TimerItem time="Days" value={days} />
              <TimerItem time="Hours" value={hours} />
              <TimerItem time="Minutes" value={minutes} />
              <TimerItem time="Seconds" value={seconds} />
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <div className="">
      <div
        style={{
          background: params?.gradient,
        }}
        className=" "
      >
        <div className="container py-6 md:py-12">
          <Countdown
            onTick={handleOnTick}
            intervalDelay={0}
            renderer={renderer}
            date={new Date(fields?.CounterInitializationTime?.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default Timer;

// data-uw-ignore-s17 for Line Height
// data-uw-ignore-s14 for Text Spacing
// data-uw-ignore-s3 for Contrast
// data-uw-ignore-s6 for Highlight Link
// turn off text spacing on mobile
// make the font size one level on mobile and 3 levels on Desktop
// reduce space between navigation menu items
