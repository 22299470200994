import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useQuery, gql } from "@apollo/client";
import StartUpCard from "./startUpCard";
import LoadingPlaceHolder from "../../../Common/loadingPlaceholder";
import { RichText } from "@sitecore-jss/sitecore-jss-react";
import TranslatedWord from "../../../Common/DictionaryTexts";

const GetStartupList = gql`
  query GetStartupList($language: String!, $first: Int!, $after: String!) {
    GQLResult: extendedsearch(
      rootItem: "/sitecore/content/COP28/GlobalData/Partners/Startups"
      language: $language
      sortBy: "customsort_s"
      sortDesc: false
      first: $first
      after: $after
      index: "cop28_website_{database}_index"
      fieldsEqual: [
        { name: "_template", value: "eebafc56745449fc89101663cdb9482e" }
      ]
    ) {
      results {
        items {
          item {
            Title: field(name: "Title") {
              value
            }
            Description: field(name: "Description") {
              value
            }

            LogoImage: field(name: "Image") {
              ... on ImageField {
                src
                alt
              }
            }
            CTALink: field(name: "CTA") {
              ... on LinkField {
                url
                text
              }
            }
            StartupList: field(name: "StartupList") {
              ... on MultilistField {
                SelectedList: targetItems {
                  Title: field(name: "Title") {
                    value
                  }
                  SubTitle: field(name: "SubTitle") {
                    value
                  }
                  Description: field(name: "Description") {
                    value
                  }
                  ProfileImage: field(name: "ProfileImage") {
                    ... on ImageField {
                      src
                      alt
                    }
                  }
                }
              }
            }
          }
        }
        pageInfo {
          hasNextPage
        }
      }
    }
  }
`;

const LoadingCards = () => {
  return (
    <div>
      <div className="w-full max-w-[400px] h-[57px] mt-14 md:mt-7">
        <LoadingPlaceHolder
          extraStyles={{ height: "100%", borderRadius: 16 }}
        />
      </div>
      <div className="w-[calc(100%+12px)] -ms-[6px] flex flex-wrap flex-basis justify-center mt-9 md:mt-7">
        {[1, 2, 3, 4, 5].map((item, index) => (
          <div
            key={`card_${index}`}
            className="basis-full sm:basis-1/2 md:basis-1/3 lg:basis-1/4 xl:basis-1/5 min-h-[200px] p-[6px]"
          >
            <LoadingPlaceHolder
              extraStyles={{ height: "100%", borderRadius: 16 }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const StartUps = (props) => {
  console.log("params=>", props);
  console.log("params=>", props.fields);
  const [modalData, set_modalData] = useState(false);
  const [currentPage, set_currentPage] = useState(0);
  const [isFirstLoad, set_isFirstLoad] = useState(true);
  const [fetching, set_fetching] = useState(false);
  const numPerPage =
    props.fields?.data?.GQLSource?.ResultsPerPage?.intValue || 4;
  const [hasNextPage, set_hasNextPage] = useState(
    props.fields?.data?.GQLResult?.results?.pageInfo?.hasNextPage
  );
  const [results, set_results] = useState(
    props.fields?.data?.GQLResult?.results?.items || []
  );
  const { loading, error, data, networkStatus, refetch } = useQuery(
    GetStartupList,
    {
      variables: {
        language: "en",
        first: numPerPage,
        after: "0",
      },
      notifyOnNetworkStatusChange: true, // This is an importantflag that makes loading status get changed when refetch
    }
  );

  const loadMore = () => {
    let temp = currentPage + 1;
    set_currentPage(temp);
    set_fetching(true);
    console.log("=============== Fetching the GQL Results ===============\n");
    refetch({
      first: numPerPage,
      after: (temp * numPerPage).toString(),
    });
  };

  useEffect(() => {
    if (!loading) {
      if (isFirstLoad) {
        set_isFirstLoad(false);
      } else {
        console.log(
          "=============== Finished Fetching ===============",
          data?.GQLResult?.results?.items?.length
        );
        const newResults = data?.GQLResult?.results?.items || [];
        set_hasNextPage(data?.GQLResult?.results?.pageInfo?.hasNextPage);
        set_results([...results, ...newResults]);
        set_fetching(false);
      }
    }
  }, [loading]);

  useEffect(() => {
    if (modalData !== false) {
      if (modalData) {
        document.body.classList.add("overflow-hidden");
      } else {
        document.body.classList.remove("overflow-hidden");
      }
    }
  }, [modalData]);

  return (
    <div className="container pb-14">
      <div className="text-blueDark-100 text-[56px] leading-[102%] font-[500] uppercase mt-14 md:mt-7">
        {props.fields?.data?.GQLSource?.Title?.value || "Start-ups"}
      </div>
      {results.map((startup, index) => (
        <div key={`enabler_${index}`}>
          <div className="text-blueDark-100 text-[32px] leading-[102%] font-[500] uppercase mt-14 md:mt-7">
            {startup.item?.Title?.value}
          </div>
          <div className="w-[calc(100%+12px)] -ms-[6px] flex flex-wrap flex-basis justify-center mt-9 md:mt-7">
            {startup.item?.StartupList?.SelectedList?.map((item, subIndex) => (
              <div
                key={`item_${index}_${subIndex}`}
                className="basis-full sm:basis-1/2 md:basis-1/3 lg:basis-1/4 xl:basis-1/5 p-[6px]"
              >
                <StartUpCard
                  data={item}
                  clickHander={() => {
                    set_modalData({
                      enablerTitle: startup.item?.Title?.value || "",
                      enablerLogo: startup.item?.LogoImage?.src,
                      ...item,
                    });
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      ))}
      {fetching ? (
        <>
          <LoadingCards />
          <LoadingCards />
          <LoadingCards />
        </>
      ) : null}
      {hasNextPage && !fetching ? (
        <div className="w-full mt-14 flex justify-center">
          <button
            className="px-8 py-4 flex items-center justify-center space-x-[10px] rtl:space-x-reverse bg-brand"
            onClick={loadMore}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_2631_34800)">
                <path
                  d="M1.66683 10.0003C1.66683 14.6027 5.39779 18.3337 10.0002 18.3337C14.6025 18.3337 18.3335 14.6027 18.3335 10.0003C18.3335 5.39795 14.6025 1.66699 10.0002 1.66699C5.39779 1.66699 1.66683 5.39795 1.66683 10.0003Z"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.66683 10L10.0002 13.3333L13.3335 10"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10 6.66699L10 13.3337"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_2631_34800">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(20) rotate(90)"
                  />
                </clipPath>
              </defs>
            </svg>
            <span className="text-[16px] leading-[18px] font-[500] text-white uppercase">
              <TranslatedWord EnWord="Load More" />
            </span>
          </button>
        </div>
      ) : null}
      {modalData
        ? createPortal(
            <div className="fixed top-0 end-0 w-full h-screen bg-black bg-opacity-40 z-[1000000] flex justify-end">
              <div
                className="w-full h-full absolute top-0 start-0 z-0 cursor-pointer"
                onClick={() => set_modalData(null)}
              />
              <div className="w-full max-w-[660px] h-full bg-white overflow-y-auto relative z-1">
                <div className="w-full flex justify-end p-6">
                  <button onClick={() => set_modalData(null)}>
                    <svg
                      width="33"
                      height="33"
                      viewBox="0 0 33 33"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.25098 8.25L24.7501 24.7492"
                        stroke="#163331"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M24.749 8.25L8.24987 24.7492"
                        stroke="#163331"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <div className="w-full h-[calc(100%-81px)] overflow-y-auto">
                  <div className="w-full px-6 pt-2 pb-14 text-blueDark-500">
                    {modalData.ProfileImage?.src ? (
                      <img
                        src={modalData.ProfileImage?.src}
                        width="185px"
                        height="auto"
                        className="mb-10"
                      />
                    ) : null}
                    <div className="text-[40px] leading-[102%] font-[500] mb-10 uppercase">
                      {modalData.Title?.value}
                    </div>
                    <RichText
                      field={modalData.Description}
                      className="!text-[20px]"
                    />
                    <div className="my-6 text-[20px] leading-[24px] font-[500]">
                      <TranslatedWord EnWord="Supported by" />
                    </div>
                    {modalData.enablerLogo ? (
                      <img
                        src={modalData.enablerLogo}
                        width="185px"
                        height="auto"
                      />
                    ) : (
                      <div className="text-[40px] leading-[102%] font-[500] uppercase">
                        {modalData.enablerTitle
                          .toLowerCase()
                          .split("enabled by ")?.length > 1
                          ? modalData.enablerTitle
                              .toLowerCase()
                              .split("enabled by ")[1]
                          : modalData.enablerTitle.toLowerCase()}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>,
            document.getElementsByTagName("body")[0]
          )
        : null}
      {props?.params?.DisplayHorizontalLineAtBottom === "1" && (
        <div className="w-full h-[1px] bg-blueDark-500 my-2" />
      )}
    </div>
  );
};

export default StartUps;
