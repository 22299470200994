import React from "react";
import { RichText, Image, Link } from "@sitecore-jss/sitecore-jss-react";

const TwoColumn = ({ fields, params }) => {
  const bgColor = params?.BackgroundColor;

  return (
    <section
      className={`${
        bgColor
          ? "bg-" + bgColor
          : "md:bg-gradient-to-r bg-gradient-to-b from-[#173330] from-0% via-[#118170] via-60% to-[#BEE2D5] to-100%"
      }`}
      style={{ background: params?.gradient ? `${params?.gradient}` : `` }}
    >
      <div className="container py-7 md:py-14">
        <div className="lg:flex md:gap-0 gap-6 -mx-4 px-4">
          <div className="flex flex-col gap-10 justify-between lg:w-4/12 font-[500]">
            <h3
              className={`uppercase pe-4 font-[500] ${
                bgColor === "brand"
                  ? "text-white"
                  : params?.TextColor === "light"
                  ? "text-white"
                  : "text-brand"
              }`}
            >
              <RichText field={fields?.Title} />
            </h3>
            {/* <Link fields={fields?.CTAbutton}> */}
            <Image field={fields?.BackgroundImage} className="w-[256px]" />
            {/* </Link> */}
          </div>
          <div
            className={`lg:w-8/12 px-0 py-7 lg:py-0 ${
              params?.NoBottomBorder !== "1" && "border-b"
            } ${
              bgColor === "brand"
                ? "text-white border-white"
                : params?.TextColor === "light"
                ? "text-white border-white"
                : "text-bluedark border-bluedark"
            }`}
          >
            <div className={`text-lg md:text-xl pb-10`}>
              <RichText field={fields?.Description} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TwoColumn;
