import React from "react";
import { RichText as RichTextComponent } from "@sitecore-jss/sitecore-jss-react";

const RichText = ({ fields, params }) => {
  return (
    <div
      className={`bg-${params?.BackgroundColor} ${
        params?.Variant !== "Full-Width" ? "container" : ""
      }`}
    >
      <div
        className={`py-14 ${
          params?.Variant === "Full-Width" ? "container" : ""
        }`}
      >
        <RichTextComponent
          field={fields?.Description}
          className="text-base leading-[18px] font-normal text-blueDark-500"
        />
      </div>
    </div>
  );
};

export default RichText;
