import React from "react";
import { RichText, Link } from "@sitecore-jss/sitecore-jss-react";
import CTA from "../../../Common/CTA";
import TwoColumn from "./TwoColumn";
import SingleColumn from "./SingleColumn";
import ThreeColumn from "./ThreeColumn";
import LightBG from "./LightBG";
import CTABar from "./CTABar";
import CTAListBar from "./CTAListBar";
import PlaceholderComponent from "../../../Common/PlaceholderComponent";

const Promotion = ({ fields, params }) => {
  return (
    <>
      {(params?.Variant === "Default" || !params?.Variant) && (
        <section className="container mb-7 md:mb-14 md:mt-0 mt-20 md:px-10">
          <h2 className="text-center mb-11 pt-10 text-brand font-[500]">
            <RichText field={fields?.Title} />
          </h2>
          <div className="flex flex-wrap -mx-4 items-center ">
            <div className="basis-full md:basis-6/12  px-4">
              <div className="mb-8 text-lg md:text-xl md:mt-20 mt-0">
                <RichText field={fields?.Description} />
              </div>
            </div>
            <div className="basis-full md:basis-6/12  px-4 md:text-end">
              {fields?.CTAbutton?.value?.href && (
                <Link field={fields?.CTAbutton}>
                  <CTA className="mb-4 flex w-fit items-center ms-auto">
                    {fields?.CTAbutton?.value?.text}
                  </CTA>
                </Link>
              )}
            </div>
          </div>
        </section>
      )}
      {params?.Variant === "TwoColumn" && (
        <TwoColumn fields={fields} params={params} />
      )}
      {params?.Variant === "ThreeColumn" && (
        <ThreeColumn fields={fields} params={params} />
      )}
      {params?.Variant === "SingleColumn" && (
        <SingleColumn fields={fields} params={params} />
      )}
      {params?.Variant === "CTA" && <CTABar fields={fields} params={params} />}
      {params?.Variant === "CTAListBar" && (
        <CTAListBar fields={fields} params={params} />
      )}
      {params?.Variant === "LightBG" && (
        <LightBG fields={fields} params={params} />
      )}
      {params?.Variant &&
        [
          "TwoColumn",
          "SingleColumn",
          "CTA",
          "LightBG",
          "Default",
          "ThreeColumn",
          "CTAListBar",
        ].indexOf(params?.Variant) === -1 && (
          <PlaceholderComponent title={`Promotion / ${params?.Variant}`} />
        )}
    </>
  );
};

export default Promotion;
