import React from "react";
import { useSelector } from "react-redux";
import { getLang } from "../../modules/LangSlice";

const words = {
  "Read More": {
    "en": "Read More",
    "ar": "اقرأ المزيد"
  },
  "Read Less": {
    "en": "Read Less",
    "ar": "اقرأ أقل"
  },
  "Load More": {
    "en": "Load More",
    "ar": "عرض المزيد"
  },
  "Hide": {
    "en": "Hide",
    "ar": "إخفاء"
  },
  "Play Video": {
    "en": "Play Video",
    "ar": "عرض الفيديو"
  },
  "Supported by": {
    "en": "Supported by",
    "ar": "مدعوم بواسطة"
  },
  "Venue/Hub": {
    "en": "Venue/Hub",
    "ar": ""
  },
  "Event Type": {
    "en": "Event Type",
    "ar": ""
  },
  "ALL": {
    "en": "ALL",
    "ar": ""
  },
  "All": {
    "en": "All",
    "ar": ""
  },
  "Location": {
    "en": "Location",
    "ar": ""
  },
  "Speakers": {
    "en": "Speakers",
    "ar": ""
  },
  "Partners": {
    "en": "Partners",
    "ar": ""
  },
  "Theme": {
    "en": "Theme",
    "ar": ""
  },
  "Zones": {
    "en": "Zones",
    "ar": ""
  },
  "Types": {
    "en": "Types",
    "ar": ""
  },
  "Venues": {
    "en": "Venues",
    "ar": ""
  },
  "No Search Results": {
    "en": "No Search Results",
    "ar": ""
  },
  "We couldn't find what you're looking for, try searching for something else.": {
    "en": "We couldn't find what you're looking for, try searching for something else.",
    "ar": ""
  },
  "Reset Search": {
    "en": "Reset Search",
    "ar": ""
  },
  "SEARCH": {
    "en": "SEARCH",
    "ar": ""
  },
  "RESULTS": {
    "en": "RESULTS",
    "ar": ""
  },
  "RESULTS FOUND": {
    "en": "RESULTS FOUND",
    "ar": ""
  },
  "There": {
    "en": "There",
    "ar": ""
  },
  "Days": {
    "en": "Days",
    "ar": ""
  },
  "Hours": {
    "en": "Hours",
    "ar": ""
  },
  "Minutes": {
    "en": "Minutes",
    "ar": ""
  },
  "Seconds": {
    "en": "Seconds",
    "ar": ""
  },
}

const TranslatedWord = ({EnWord}) => {
  const lang = useSelector(getLang);
  return (
    <>
      {
        EnWord ? 
          (
            words[EnWord] ?
              (
                lang ?
                  (
                    words[EnWord][lang] ?
                      words[EnWord][lang] :
                      EnWord
                  ) :
                  EnWord
              ) :
              EnWord
          ) :
          ''
      }
    </>
  )
}

export default TranslatedWord;
