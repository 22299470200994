import React from "react";

import { RichText, Text, Image, Link } from "@sitecore-jss/sitecore-jss-react";
import { Parallax } from "react-scroll-parallax";
import CTA from "../../../Common/CTA";

const FullwidthImage = ({ fields, params, lowCarbon }) => {
  const bgStyle = {
    backgroundImage: `url(${fields?.BackgroundImage?.value.src})`,
  };
  return (
    <>
      <section
        className={`bg-cover w-full bg-no-repeat bg-fixed bg-center relative`}
        style={!lowCarbon && lowCarbon !== null ? bgStyle : {}}
      >
        <div className={fields?.gradient ? "left-gradient dark:bg-none" : ""}>
          {(fields?.Title?.value || fields?.Description?.value) && (
            <>
              <div
                className="absolute top-0 start-0 w-full md:block hidden dark:hidden h-full dark:from-[#252525] dark:to-[#B5B4B1]"
                style={{ background: `${params?.gradient}` }}
              ></div>
              <div
                className="absolute top-0 start-0 w-full md:hidden block dark:hidden h-full dark:from-[#252525] dark:to-[#B5B4B1]"
                style={{ background: `${params?.MobileGradient}` }}
              ></div>
              <div className="absolute top-0 start-0 w-full md:hidden hidden dark:block h-full bg-gradient-to-r dark:from-[#252525] dark:to-[#B5B4B1]"></div>
            </>
          )}
          <div className="container relative">
            <div className="flex flex-wrap md:flex-nowrap pt-24 pb-10 min-h-[270px] md:min-h-[700px]">
              <div className="basis-full md:basis-10/12 lg:10/12  ">
                {fields?.SubTitle && (
                  <h4 className="text-white mb-4 md:mb-8">
                    <Text field={fields?.SubTitle} />
                  </h4>
                )}
                <h2 className="mb-8  font-[500]  text-white">
                  <RichText field={fields?.Title} />
                </h2>
                <div className="mb-14 text-white text-lg md:text-xl md:w-9/12">
                  <RichText field={fields?.Description} />
                </div>
                {fields?.CTAbutton?.value?.href && (
                  <div className="mb-24">
                    <Link field={fields?.CTAbutton}>
                      <CTA
                        link={fields?.cta_link}
                        className="flex w-fit items-center"
                      >
                        {fields?.CTAbutton?.value?.text}
                      </CTA>
                    </Link>
                  </div>
                )}
              </div>
              <div className="basis-full md:hidden lg:basis-3/12 lg:block" />
              <div className="basis-full md:basis-6/12 inline-flex self-center ">
                {fields?.Image?.value?.src && (
                  <div>
                    <Parallax
                      speed={10}
                      className="relative z-10 hidden md:block"
                    >
                      <Image
                        field={fields?.Image}
                        className="w-full relative"
                      />
                    </Parallax>
                    <Image
                      field={fields?.BackgroundImage}
                      className="w-full relative md:hidden"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FullwidthImage;
