import React from "react";
import PlaceholderComponent from "../../../Common/PlaceholderComponent";
import Profiles from "../Profiles";
import Events from "../Events";
import News from "../News";
import TopNews from "../TopNews";
import Enablers from "../Enablers";
import StartUps from "../StartUps";
import Sponsors from "../Sponsor";
import ParnerListingProviders from "../ParnerListingProviders";
import ParnerListingSupporters from "../ParnerListingSupporters";
import TopNonNewsType from "../TopNonNewsType";
import Tours from "../Tours";
import Endosers from "../Endosers";
import InitiatingEndorsers from "../InitiatingEndorsers";
import TopVideo from "../TopVideo";

const CentralizedGQL = ({ fields, params, rendering, isRtl }) => {
  return (
    <>
      {params?.Variant &&
        [
          "CentralizedGQL",
          "Profiles",
          "Events",
          "News",
          "TopNews",
          "TopNonNewsType",
          "Default",
          "Parner-Listing-Enablers",
          "Parner-Listing-MainSponsor",
          "Parner-Listing-Startups",
          "Parner-Listing-Supporters",
          "Parner-Listing-Providers",
          "Tours",
          "TopVideo",
          "Guiding-Principles-Endosers",
          "Guiding-Principles-Initiating-Endorsers",
        ].indexOf(params?.Variant) === -1 &&
        !params?.Variant?.includes("Parner-Listing-Enablers") && (
          <PlaceholderComponent title={`CentralizedGQL / ${params?.Variant}`} />
        )}
      {params?.Variant === "Profiles" && (
        <Profiles fields={fields} params={params} isRtl={isRtl} />
      )}
      {params?.Variant === "Events" && <Events fields={fields} isRtl={isRtl} />}
      {params?.Variant === "News" && <News fields={fields} params={params} />}
      {params?.Variant === "Tours" && <Tours fields={fields} />}
      {params?.Variant === "TopNews" && <TopNews fields={fields} />}
      {params?.Variant === "TopVideo" && <TopVideo fields={fields} />}
      {params?.Variant === "TopNonNewsType" && (
        <TopNonNewsType
          title_en={params?.Title_en}
          title_ar={params?.Title_ar}
          fields={fields}
        />
      )}
      {params?.Variant?.includes("Parner-Listing-Enablers") && (
        <Enablers params={params} fields={fields} rendering={rendering} />
      )}
      {params?.Variant === "Guiding-Principles-Endosers" && (
        <Endosers params={params} fields={fields} rendering={rendering} />
      )}
      {params?.Variant === "Guiding-Principles-Initiating-Endorsers" && (
        <InitiatingEndorsers
          params={params}
          fields={fields}
          rendering={rendering}
        />
      )}
      {params?.Variant === "Parner-Listing-MainSponsor" && (
        <Sponsors params={params} fields={fields} rendering={rendering} />
      )}
      {params?.Variant === "Parner-Listing-Startups" && (
        <StartUps params={params} fields={fields} rendering={rendering} />
      )}
      {params?.Variant === "Parner-Listing-Supporters" && (
        <ParnerListingSupporters
          params={params}
          fields={fields}
          rendering={rendering}
          isRtl={isRtl}
        />
      )}
      {params?.Variant === "Parner-Listing-Providers" && (
        <ParnerListingProviders
          params={params}
          fields={fields}
          rendering={rendering}
          isRtl={isRtl}
        />
      )}
      {(params?.Variant === "Default" || !params?.Variant) && (
        <section className="bg-blueDark-100 pt-40 pb-14">
          <div className="container">
            <div className="w-full flex md:flex-row flex-col relative overflow-hidden">
              <h1>welcome GQL</h1>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default CentralizedGQL;
