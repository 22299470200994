import React from "react";
import { Image, Text } from "@sitecore-jss/sitecore-jss-react";

export default function FourTwoAllotment({ fields, params }) {
  return (
    <>
      <div className={params.BackgroundColor}>
        <div className="container my-7 md:my-14">
          {fields?.Title?.value && (
            <h3 className="text-brand uppercase mb-7 md:mb-14 text-center">
              {fields?.Title?.value}
            </h3>
          )}
          <div className="flex items-center flex-wrap w-full box-border -mx-3 justify-center">
            {fields?.SelectMedia?.map((item, i) => {
              return <Card item={item} key={i} className={params.ClassName} />;
            })}
          </div>
        </div>
      </div>
    </>
  );
}

const Card = function ({ item, className }) {
  const CTA = item.fields?.CTALink?.value;
  return (
    <div
      className={`px-3 basis-full sm:basis-2/12 md:basis-4/12 mb-7 lg:mb-14 shrink grow-0 ${
        className ? className : "lg:basis-3/12"
      }`}
    >
      <a
        href={item.fields?.CTALink?.value?.href || null}
        target={item.fields?.CTALink?.value?.target}
      >
        <div className="flex flex-col justify-center  px-4 items-center">
          <div className="h-48 flex flex-col justify-center">
            <img src={item.fields?.Image?.value?.src} />
          </div>
          <h6 className="font-bold text-center">
            <Text field={item.fields?.Title} />
          </h6>
          <p className="text-center">
            <Text field={item.fields?.Description} />
          </p>
        </div>
      </a>
    </div>
  );
};
