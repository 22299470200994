import React from "react";

const FNBCard = ({ data, zoneLabel, zones, venues }) => {
  const filterById = (id, array) => {
    const index = array.findIndex(
      (x) =>
        x.id.replace("{", "").replace("}", "").toLowerCase() ===
        id.replace("{", "").replace("}", "").toLowerCase()
    );
    return array[index];
  };

  return (
    <a
      href={data?.url}
      className="text-blueDark-500 rounded-[16px] overflow-hidden bg-lightgrey"
    >
      <div
        className={`w-full aspect-[302/180] relative ${
          data?.Image?.src ? "" : "bg-black bg-opacity-20"
        } flex items-center`}
      >
        {data?.Image?.src ? (
          <img src={data?.Image?.src} className="w-full h-full" />
        ) : null}
        <div className="absolute top-2 start-2 w-[calc(100%-16px)] flex flex-wrap gap-2 text-[16px] leading-[18px] font-[500]">
          {filterById(data?.Venue?.value, venues)?.fields?.Value?.value ? (
            <div className="p-2 rounded-full bg-blueDark-50 text-blueDark-100">
              {filterById(data?.Venue?.value, venues)?.fields?.Value?.value}
            </div>
          ) : null}
        </div>
      </div>
      <div className="pt-[21px] px-4 pb-6">
        <div className="text-blueDark-500 text-[16px] leading-[20px] font-[500] min-h-[36px] px-2 flex items-center">
          {data?.Title?.value}
        </div>
        <div className="text-blueDark-500 text-[12px] leading-[18px] mt-2 px-2 uppercase">
          {zoneLabel}{" "}
          <span className="font-[500]">
            {filterById(data?.Zone?.value, zones)?.fields?.Value?.value}
          </span>
        </div>
        <div className="text-blueDark-500 text-[16px] leading-[20px] mt-[21px]">
          {" "}
          {data.Description?.value?.length > 130
            ? `${data.Description?.value.slice(0, 130)}...`
            : data.Description?.value}{" "}
        </div>
      </div>
      {/* <a href={data?.url} className='w-fit flex items-center justify-center text-bluedark text-[16px] leading-[18px] font-[500] space-x-[10px] rtl:space-x-reverse py-4 px-[30px] mt-6 bg-tumeric-200 hover:bg-gradient-to-b hover:from-[#CF4800] hover:via-[#F1B460] hover:to-[#ffffff] hover:text-blueDark-500'>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.867 8.64035L6.50736 16H8.3175C10.3799 13.9376 14.8113 9.50619 15.4124 8.90507C16.1998 8.11768 16.1925 7.87498 15.4124 7.09493L8.3175 0H6.50736L13.867 7.35965H0.320029L0 8.63978H13.867V8.64035Z" fill="currentColor"/>
                </svg>
                <span>{LearnMoreText}</span>
            </a> */}
    </a>
  );
};

export default FNBCard;
