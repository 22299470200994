import React, { useState } from "react";
import { RichText, Image } from "@sitecore-jss/sitecore-jss-react";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import Modal from "./Modal";
import TranslatedWord from "./DictionaryTexts";

const Card = (props) => {
  const {
    image,
    name,
    role,
    style = "light",
    country,
    bio,
    className,
    modalIsOff,
    cta,
  } = props;

  const [showModal, setShowModal] = useState(false);

  return (
    <div
      className={`card-modal text-grey-500 px-3 basis-full md:basis-6/12 lg:basis-3/12 text-center font-medium   relative ${className}`}
    >
      <div className="relative inline-block">
        <div className="absolute top-[5px] end-[5px]">
          <span
            className={`fi fi-${country?.rendered} h-[45px]`}
            style={{ width: "50px" }}
          />
        </div>

        <Image field={image} className="w-[250px] max-w-full mx-auto" />
      </div>
      <div
        className={`mt-8 text-lg md:text-xl ${
          style === "dark" ? "text-white" : "text-brand"
        }`}
      >
        <RichText field={name} />
      </div>
      {!!role && (
        <>
          <div
            className={`mt-2 ${
              style === "dark" ? "text-white" : "text-blueDark-500"
            } font-[400]`}
          >
            <RichText field={role} />
          </div>
          {!modalIsOff && (
            <div
              className={`text-base underline ${
                style === "dark" ? "text-white" : "text-brand"
              } font-[500] mt-4 cursor-pointer`}
              onClick={() => setShowModal(true)}
            >
              <TranslatedWord EnWord="Read More" />
            </div>
          )}

          <Modal
            open={showModal}
            style={style}
            onCloseModal={() => setShowModal(false)}
          >
            <div className="block items-start gap-8 p-5 md:flex">
              <div
                className="text-center shrink-0 relative m-auto"
                style={{ maxWidth: "300px" }}
              >
                <div className="relative w-fit m-auto">
                  <div className="absolute top-[20px] -end-[30px]">
                    <span
                      className={`fi fi-${country?.rendered} h-[45px]`}
                      style={{ width: "50px" }}
                    />
                  </div>
                  <Image
                    field={image}
                    className="w-[190px] h-[190px] m-auto rounded-full"
                  />
                </div>
                <div
                  className={`mt-8 ${
                    style === "dark" ? "text-white" : "text-brand"
                  }`}
                >
                  <RichText field={name} />
                </div>
                <div
                  className={`mt-2 ${
                    style === "dark" ? "text-white" : "text-bluedark"
                  } font-semibold`}
                >
                  {!!role && <RichText field={role} />}
                </div>
              </div>
              <div className="h-[100px] md:h-[330px] md:mt-0 mt-5 text-start text-bluedark overflow-auto px-4">
                <RichText field={bio} />
              </div>
            </div>
          </Modal>
        </>
      )}
      {cta?.url && (
        <a
          href={cta?.url}
          className="mt-2 inline-block text-brand hover:underline"
          rel="noreferrer"
          target={cta?.linktype === "external" ? "_blank" : "_self"}
        >
          {cta?.text}
        </a>
      )}
    </div>
  );
};

export default Card;
