import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX, faPlay } from "@fortawesome/free-solid-svg-icons";
import UseScrollBlock from "./UseScrollBlock";
import ReactPlayer from "react-player/lazy";
import { createPortal } from "react-dom";

const VideoModal = ({ video, poster, onClose, isIframe }) => {
  const videoRef = useRef(null);
  const overlayRef = useRef(null);
  const containerRef = useRef(null);
  const [isPlay, setIsPlay] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const [isControls, setIsControls] = useState(false);
  const [blockScroll, allowScroll] = UseScrollBlock();
  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    }
  }, []);
  const handleClose = (event) => {
    videoRef.current?.pause();
    allowScroll();
    onClose();
  };
  const handlePlay = () => {
    setIsPlay(true);
    setIsControls(true);
    videoRef.current?.play();
  };

  useEffect(() => {
    blockScroll();
  });
  return (
    <>
      {createPortal(
        <div
          className="fixed h-screen flex justify-center items-center w-screen sm:w-full top-0 start-0 z-[90] p-0 sm:p-12 bg-dark-green-900"
          onClick={(e) => {
            if (e.target === overlayRef.current) handleClose();
          }}
          ref={overlayRef}
        >
          <div
            className=" sm:h-full w-screen sm:w-full relative flex flex-col items-center justify-center "
            ref={containerRef}
            onClick={(e) => {
              if (e.target === containerRef.current) handleClose();
            }}
          >
            {/* {!isPlay && (
            <span
              className="absolute w-20 h-20 cursor-pointer z-50 top-[calc(50%-40px)] start-[calc(50%-40px)] flex justify-center items-center border-1 border rounded-full bg-[#16333166]"
              onClick={handlePlay}
            >
              <FontAwesomeIcon
                icon={faPlay}
                className="  text-white text-2xl"
              />
            </span>
          )} */}
            <span
              className=" self-end shrink-0 relative end-0 sm:-end-10 bg-white w-10 h-10 flex justify-center items-center cursor-pointer"
              onClick={handleClose}
            >
              <FontAwesomeIcon icon={faX} />
            </span>
            {/* <video
            ref={videoRef}
            src={video?.value?.href}
            poster={poster?.value?.src}
            controls={isControls}
            className="md:relative max-h-full bg-white p-5 md:p-10"
          ></video> */}
            {!isIframe && (
              <ReactPlayer
                url={video?.value?.href}
                playing={true}
                controls={true}
                style={{
                  maxHeight: "100%",
                  backgroundColor: "white",
                  padding: "20px",
                }}
                width="100%"
                height={isMobile ? "400px" : "100%"}
              />
            )}
            {isIframe && (
              <iframe
                className="w-full h-[400px] md:h-full bg-white "
                src={video?.value?.href}
              />
            )}
          </div>
        </div>,
        document.getElementsByTagName("body")[0]
      )}
    </>
  );
};

export default VideoModal;
