import React, { useState } from "react";
import {
  Link,
  RichText,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import PlaceholderComponent from "../../../Common/PlaceholderComponent";
import Accordion from "../../../Common/Accordion";
import ReadMoreLess from "../ReadMoreLess";
import LeftTitle from "../LeftTitle";
import CTA from "../../../Common/CTA";

const FAQ = ({ sitecoreContext, fields, params }) => {
  const [activeItemIndex, setActiveItemIndex] = useState("");

  const onClickAccordion = (blockIndex, itemIndex) => {
    if (itemIndex === activeItemIndex) {
      setActiveItemIndex(null); // Clicking on the same item closes it
    } else {
      setActiveItemIndex(itemIndex); // Clicking on a different item sets it as active
    }
  };

  return (
    <>
      {params?.Variant === "ReadMoreLess" && (
        <ReadMoreLess fields={fields} params={params} />
      )}
      {params?.Variant === "LeftTitle" && (
        <LeftTitle fields={fields} params={params} />
      )}
      {params?.Variant &&
        ["Default", "ReadMoreLess", "LeftTitle"].indexOf(params?.Variant) ===
          -1 && <PlaceholderComponent title={`FAQ / ${params?.Variant}`} />}
      {(params?.Variant === "Default" || !params?.Variant) && (
        <section className={`bg-${params?.BackgroundColor} pt-20`}>
          <div className="container">
            <h3
              className={`text-center font-[500] ${
                sitecoreContext?.route?.fields?.PageTheme?.value === "Blue"
                  ? "text-blueDark-600"
                  : "text-brand"
              } mb-10`}
            >
              <RichText field={fields?.Title} />
            </h3>
            <div className="mt-8">
              {fields?.Faq.map((value, itemIndex) =>
                value?.fields?.Title?.value ? (
                  <Accordion
                    key={itemIndex}
                    iconField={value?.fields?.Icon}
                    style={
                      value?.fields?.BackgroundColor?.value === "brand"
                        ? "dark"
                        : "light"
                    }
                    open={itemIndex === activeItemIndex}
                    title={value?.fields?.Title}
                    index={itemIndex}
                    onClick={onClickAccordion}
                    blue={sitecoreContext?.route?.fields?.PageTheme?.value}
                  >
                    <div
                      className={`text-base ${
                        sitecoreContext?.route?.fields?.PageTheme?.value ===
                        "Blue"
                          ? "text-blueDark-500"
                          : "text-bluedark"
                      }`}
                    >
                      <RichText field={value?.fields?.Description} />
                    </div>
                  </Accordion>
                ) : (
                  <RichText
                    className="text-base"
                    key={itemIndex}
                    field={value?.fields?.Description}
                  />
                )
              )}
            </div>
            {fields?.CTALink?.value?.href && (
              <div className="text-center my-7 md:my-14">
                <Link field={fields?.CTALink}>
                  <CTA className="text-bluedark">
                    {fields?.CTALink?.value?.text}
                  </CTA>
                </Link>
              </div>
            )}
          </div>
        </section>
      )}
    </>
  );
};

export default withSitecoreContext()(FAQ);
