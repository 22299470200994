import React from "react";
import { Helmet } from "react-helmet";

const ShortHeight = ({ fields }) => {
  return (
    <section
      className="md:h-[345px] h-[170px] w-full bg-cover bg-center"
      style={{ backgroundImage: `url(${fields?.BackgroundImage?.value?.src})` }}
    >
      <div 
        className="w-full h-1/2"
        style={{ background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.70) 20.8%, rgba(0, 0, 0, 0.00) 97.39%)' }}
      ></div>
      <Helmet>
        <meta name="image" content={fields?.Image?.value?.src} />
        <meta name="og:image" content={fields?.Image?.value?.src} />
      </Helmet>
    </section>
  );
};

export default ShortHeight;
