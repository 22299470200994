import { configureStore } from "@reduxjs/toolkit";

import LangSlice from "./LangSlice";
import ModeSlice from "./ModeSlice";

export default configureStore({
  reducer: {
    lang: LangSlice,
    lowmode: ModeSlice
  }
});
