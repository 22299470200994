import React from "react";
import { RichText } from "@sitecore-jss/sitecore-jss-react";

const VideoCard = ({ data, onSelect }) => {
  const getFormatedDate = (date) => {
    const newDate = new Date(date);
    const day = newDate.getDate();
    const month = newDate.toLocaleString("default", { month: "short" });
    const year = newDate.getFullYear().toString().slice(2);

    return day + " " + month + " " + year;
  };

  return (
    <>
      <div>
        <div
          onClick={onSelect}
          className="bg-cover bg-center bg-no-repeat p-2 h-[200px] relative"
          style={{
            backgroundImage: `url(${data?.Image?.src})`,
          }}
        >
          {data?.StartDate?.rendered && (
            <div className="px-2 py-1  w-fit  rounded-full text-base text-tumeric-300 bg-tumeric-100">
              {getFormatedDate(data?.StartDate?.rendered)}
            </div>
          )}
          <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
            <button>
              <svg
                className="text-brand rounded-full"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="20"
                  cy="20"
                  r="20"
                  fill="currentColor"
                  strokeWidth="0.75"
                />
                <path
                  d="M29 20L15.5 27.7942L15.5 12.2058L29 20Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </div>

        <RichText
          field={data?.Description}
          className="text-bluedark text-base mt-1 text-left"
        />
      </div>
    </>
  );
};

export default VideoCard;
