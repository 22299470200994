import React from 'react'

const EnablerCard = ({data}) => {
    return (
        <a href={data.CTALink?.url} className='flex flex-col justify-between p-4 text-blueDark-500 hover:bg-[rgba(190,226,213,0.10)] hover:text-blueDark-100 cursor-pointer rounded-[16px]'>
            {
                data.LogoImage?.src ?
                    <img src={data.LogoImage?.src} width='145px' height='auto'/> :
                    <div className='w-[145px] h-[75px] bg-black bg-opacity-5' />
            }
            <div className='min-h-[50px] text-[20px] leading-[24px] font-[500] mt-3'>
                {data.Title?.value}
            </div>
        </a>
    )
}

export default EnablerCard
