import { RichText } from "@sitecore-jss/sitecore-jss-react";
import React from "react";
import { SwiperSlide, Swiper } from "swiper/react";
import TranslatedWord from "../../../Common/DictionaryTexts";

const EventCard = ({
  data,
  isSameTime,
  hideTimeLine,
  dates,
  themes,
  partners,
  speakers,
  venues,
}) => {
  const formatDate = (dateString) => {
    try {
      const index = dates.findIndex((x) => x.fields?.Key?.value === dateString);
      if (index > -1) {
        return dates[index]?.fields?.Value?.value;
      } else {
        return "";
      }
    } catch (err) {
      return "";
    }
  };

  const formatTime = (timeString, ampm) => {
    try {
      let hour = timeString.slice(0, 2);
      let minute = timeString.slice(2, 4);
      if (!hour || !minute) {
        throw Error("invalid format");
      }
      if (!ampm) {
        return `${hour}:${minute}`;
      }
      hour = Number(hour);
      minute = Number(minute);
      if (hour >= 12) {
        if (hour === 12) {
          if (minute > 0) {
            return `${hour}:${minute}PM`;
          } else {
            return `${hour}PM`;
          }
        } else {
          if (minute > 0) {
            return `${hour - 12}:${minute}PM`;
          } else {
            return `${hour - 12}PM`;
          }
        }
      } else {
        if (minute > 0) {
          return `${hour}:${minute}AM`;
        } else {
          return `${hour}AM`;
        }
      }
    } catch (err) {
      return "";
    }
  };

  const filterById = (id, array) => {
    const index = array.findIndex(
      (x) =>
        x.id.replace("{", "").replace("}", "").toLowerCase() ===
        id.replace("{", "").replace("}", "").toLowerCase()
    );
    console.log(index, "////");
    return array[index];
  };

  let eventThemes = [];
  data.EventTheme?.value?.split("|")?.map((themeId) => {
    const theme = filterById(themeId, themes);
    if (theme) {
      eventThemes.push(theme);
    }
  });
  let eventPartners = [];
  data.EventPartners?.value?.split("|")?.map((partnerId) => {
    const partner = filterById(partnerId, partners);
    if (partner) {
      eventPartners.push(partner);
    }
  });
  let eventSpeakers = [];
  data.EventSpeakers?.value?.split("|")?.map((speakerId) => {
    const speaker = filterById(speakerId, speakers);
    if (speaker) {
      eventSpeakers.push(speaker);
    }
  });

  return (
    <a href={data.url} className="block w-full mt-6 text-blueDark-500">
      {!isSameTime && !hideTimeLine ? (
        <div className="flex items-center justify-between space-x-2 p-2">
          <div className="text-[16px] leading-[20px] font-[400]">
            {formatTime(data.EventStartTime?.value, true)}
          </div>
          <div className="w-[calc(100%-51px)] h-[1px] bg-blueDark-500" />
        </div>
      ) : null}
      <div className="px-4 lg:px-8">
        <div className="w-full mt-6 py-4 rounded-[2px] bg-white hover:shadow-[0px_14px_34px_0px_rgba(0,0,0,0.25)] text-[16px] leading-[20px] font-[500]">
          <div className="flex flex-wrap justify-between px-6">
            <div className="flex space-x-2 rtl:space-x-reverse me-2 mt-2">
              <div className="py-2 px-3 rounded-[60px] bg-tumeric-100 text-[#CF4800] uppercase">
                {formatDate(data.EventDate?.value)}
              </div>
              <div className="py-2 px-3 rounded-[60px] bg-wadi uppercase">
                {formatTime(data.EventStartTime?.value)}-
                {formatTime(data.EventEndTime?.value)} GST
              </div>
            </div>
          </div>
          {eventThemes?.length ? (
            <div className="w-full px-6 mt-2">
              <Swiper
                className="relative"
                spaceBetween={8}
                slidesPerView="auto"
              >
                {eventThemes.map((theme) => (
                  <SwiperSlide key={theme.id} className="!w-max last:pe-14">
                    <div className="max-w-full min-w-fit text-center py-2 px-3 rounded-[60px] bg-blueDark-50 text-blueDark-100">
                      {theme?.fields?.Value?.value}
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          ) : null}
          <div className="text-[20px] leading-[24px] font-[500] text-blueDark-100 mt-6 px-6">
            {data.Title?.value}
          </div>
          <div className="text-[16px] leading-[20px] font-[400] mt-2 px-6 multi-line-text-truncate-3">
            <RichText field={data.Description} />
          </div>
          <div className="px-6">
            {data.EventLocation?.value && (
              <>
                <div className="text-[16px] leading-[20px] font-[500] mt-6">
                  <TranslatedWord EnWord="Location" />
                </div>
                <div className="text-[16px] leading-[20px] font-[400] mt-2">
                  {data.EventLocation?.value}
                </div>
              </>
            )}
            {data.EventVenue?.value &&
              filterById(data.EventVenue?.value || "", venues)?.fields?.Value
                ?.value && (
                <>
                  <div className="text-[16px] leading-[20px] font-[500] mt-6">
                    <TranslatedWord EnWord="Venue" />
                  </div>
                  <div className="text-[16px] leading-[20px] font-[400] mt-2">
                    {
                      filterById(data.EventVenue?.value, venues)?.fields?.Value
                        ?.value
                    }
                  </div>
                </>
              )}
          </div>
          {eventSpeakers?.length ? (
            <div className="w-full text-[16px] leading-[20px] px-6">
              <div className="font-[500] mt-6">
                <TranslatedWord EnWord="Speakers" />
              </div>
              <Swiper
                className="w-full mt-2 relative"
                spaceBetween={16}
                slidesPerView="auto"
              >
                {eventSpeakers?.map((speaker) => {
                  if (speaker) {
                    console.log(speaker);
                    return (
                      <SwiperSlide
                        key={speaker.id}
                        className="!w-max last:pe-14"
                      >
                        <div className="w-full h-full flex items-center space-x-2">
                          {speaker?.fields?.Image?.value?.src && (
                            <img
                              src={speaker?.fields?.Image?.value?.src}
                              className="w-[44px] h-[44px] rounded-full"
                            />
                          )}
                          <div className="flex flex-col space-y-2">
                            {/* <div className="font-[500]">
                              {speaker?.fields?.Key?.value}
                            </div> */}
                            <div className="font-[400]">
                              {speaker?.fields?.Value?.value}
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  }
                })}
                <div className="absolute top-0 end-0 w-14 h-full bg-gradient-to-l from-lightgrey z-10" />
              </Swiper>
            </div>
          ) : null}
          {eventPartners?.length ? (
            <div className="w-full text-[16px] leading-[20px] px-6">
              <div className="font-[500] mt-6">
                <TranslatedWord EnWord="Partners" />
              </div>
              <Swiper
                className="w-full mt-2 relative"
                spaceBetween={24}
                slidesPerView="auto"
              >
                {eventPartners?.map((partner, index) => (
                  <SwiperSlide
                    key={partner.id}
                    className="!w-fit last:pe-14 !h-auto"
                  >
                    <div className="w-full h-full flex justify-center items-center text-[16px] leading-[20px] font-[400]">
                      {partner?.fields?.Image?.value?.src ? (
                        <img
                          src={partner?.fields?.Image?.value?.src}
                          width="64px"
                          height="auto"
                        />
                      ) : (
                        partner?.fields?.Value?.value
                      )}
                    </div>
                  </SwiperSlide>
                ))}
                <div className="absolute top-0 end-0 w-14 h-full bg-gradient-to-l from-lightgrey z-10" />
              </Swiper>
            </div>
          ) : null}
        </div>
      </div>
    </a>
  );
};

export default EventCard;
