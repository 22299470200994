import React, { useState, useEffect } from "react";
import { useQuery, gql } from "@apollo/client";
import SponsorCard from "./sponsorCard";
import LoadingPlaceHolder from "../../../Common/loadingPlaceholder";
import TranslatedWord from "../../../Common/DictionaryTexts";

const GetSponsorList = gql`
  query GetSponsorList($language: String!, $first: Int!, $after: String!) {
    GQLResult: extendedsearch(
      rootItem: "/sitecore/content/COP28/GlobalData/Sponsors/SponsorsMasterList"
      language: $language
      sortDesc: false
      first: $first
      after: $after
      index: "cop28_website_{database}_index"
      fieldsEqual: [
        { name: "_template", value: "eebafc56745449fc89101663cdb9482e" }
        { name: "PartnerType", value: "f981d4ad1c7d4271b490dd93f2d5fa3c" }
      ]
    ) {
      results {
        items {
          item {
            name
            Title: field(name: "Title") {
              value
            }
            Description: field(name: "Description") {
              value
            }

            LogoImage: field(name: "Image") {
              ... on ImageField {
                src
                alt
              }
            }
            CTALink: field(name: "CTA") {
              ... on LinkField {
                url
                text
              }
            }
          }
        }
        totalCount
      }
    }
  }
`;

const LoadingCards = ({ count }) => {
  const [cards, set_cards] = useState([]);

  useEffect(() => {
    let temp = [];
    for (let i = 0; i < count; i++) {
      temp.push(i);
    }
    set_cards([...temp]);
  }, [count]);

  return (
    <>
      {cards.map((card) => (
        <div
          key={card}
          className="basis-full sm:basis-1/2 lg:basis-1/3 xl:basis-1/4 p-[6px] h-auto min-h-[220px]"
        >
          <LoadingPlaceHolder
            extraStyles={{ height: "100%", borderRadius: 16 }}
          />
        </div>
      ))}
    </>
  );
};

const Sponsors = (props) => {
  const [currentPage, set_currentPage] = useState(0);
  const [isFirstLoad, set_isFirstLoad] = useState(true);
  const [fetching, set_fetching] = useState(false);
  const numPerPage =
    props.fields?.data?.GQLSource?.ResultsPerPage?.intValue || 4;
  const totalCount = props.fields?.data?.GQLResult?.results?.totalCount || 0;
  const [results, set_results] = useState(
    props.fields?.data?.GQLResult?.results?.items || []
  );

  const { loading, error, data, networkStatus, refetch } = useQuery(
    GetSponsorList,
    {
      variables: {
        language: "en",
        first: numPerPage,
        after: "0",
      },
      notifyOnNetworkStatusChange: true, // This is an importantflag that makes loading status get changed when refetch
    }
  );

  const loadMore = () => {
    set_fetching(true);
    console.log("=============== Fetching the GQL Results ===============\n");
    refetch({
      first: numPerPage,
      after: (currentPage * numPerPage).toString(),
    });
    set_currentPage((state) => state + 1);
  };

  useEffect(() => {
    if (!loading) {
      if (isFirstLoad) {
        set_isFirstLoad(false);
      } else {
        console.log(
          "=============== Finished Fetching ===============",
          data?.GQLResult?.results?.items?.length
        );
        const newResults = data?.GQLResult?.results?.items || [];
        set_results([...results, ...newResults]);
        set_fetching(false);
      }
    }
  }, [loading]);

  return (
    <div className="container pb-0 md:pb-7">
      <div className="text-blueDark-100 text-center text-[56px] leading-[102%] font-[500] uppercase pt-14 pb-6">
        {props.fields?.data?.GQLSource?.Title?.value || "Main Sponsor"}
      </div>
      <div className="w-[calc(100%+12px)] -ms-[6px] flex flex-wrap flex-basis justify-center">
        {results.map((sponsor, index) => (
          <div
            key={`sponsor_${index}`}
            className="basis-full sm:basis-1/2 lg:basis-1/3 xl:basis-1/4 p-[6px]"
          >
            <SponsorCard data={sponsor.item} />
          </div>
        ))}
        {fetching ? <LoadingCards count={numPerPage} /> : null}
      </div>
      {numPerPage * (currentPage + 1) < totalCount &&
      totalCount > numPerPage ? (
        <div className="w-full mt-14 flex justify-center">
          <button
            className="px-8 py-4 flex items-center justify-center space-x-[10px] rtl:space-x-reverse bg-brand"
            onClick={loadMore}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_2631_34800)">
                <path
                  d="M1.66683 10.0003C1.66683 14.6027 5.39779 18.3337 10.0002 18.3337C14.6025 18.3337 18.3335 14.6027 18.3335 10.0003C18.3335 5.39795 14.6025 1.66699 10.0002 1.66699C5.39779 1.66699 1.66683 5.39795 1.66683 10.0003Z"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.66683 10L10.0002 13.3333L13.3335 10"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10 6.66699L10 13.3337"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_2631_34800">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(20) rotate(90)"
                  />
                </clipPath>
              </defs>
            </svg>
            <span className="text-[16px] leading-[18px] font-[500] text-white uppercase">
              <TranslatedWord EnWord="Load More" />
            </span>
          </button>
        </div>
      ) : null}
      {props?.params?.DisplayHorizontalLineAtBottom === "1" && (
        <div className="w-full h-[1px] bg-blueDark-500 my-2" />
      )}
    </div>
  );
};

export default Sponsors;
