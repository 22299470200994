import React, { useEffect, useState } from "react";
import { RichText, Image, Link } from "@sitecore-jss/sitecore-jss-react";
import FullwidthImage from "../FullwidthImage";
import CTA from "../../../Common/CTA";
import FullWidth from "../FullWidth";
import FullwidthTwoColumn from "../FullwidthTwoColumn";
import CTAButtonList from "../CTAButtonList";
import TwoColumn from "../TwoColumn";
import PlaceholderComponent from "../../../Common/PlaceholderComponent";
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
// import MapPdfViewer from "../MapPdfViewer";
const ImagewithText = ({ fields, params }) => {
  const [lowCarbon, setLowCarbon] = useState(null);
  const [open, setOpen] = React.useState(false);
  const zoomRef = React.useRef(null);
  useEffect(() => {
    setLowCarbon(window.localStorage.getItem("low-carbon-mode") === "true");
  }, []);
  return (
    <div>
      {params?.Variant === "FullwidthImage" && (
        <FullwidthImage fields={fields} params={params} lowCarbon={lowCarbon} />
      )}
      {params?.Variant === "FullWidth" && (
        <FullWidth lowCarbon={lowCarbon} fields={fields} params={params} />
      )}
      {params?.Variant === "FullWidthTwoColumn" && (
        <FullwidthTwoColumn
          lowCarbon={lowCarbon}
          fields={fields}
          params={params}
        />
      )}
      {params?.Variant === "TwoColumn" && (
        <TwoColumn lowCarbon={lowCarbon} fields={fields} params={params} />
      )}
      {params?.Variant === "CTAButtonList" && (
        <CTAButtonList fields={fields} params={params} />
      )}
      {params?.Variant &&
        [
          "FullwidthImage",
          "FullWidth",
          "FullWidthTwoColumn",
          "TwoColumn",
          "CTAButtonList",
          "Map",
          "Default",
        ].indexOf(params?.Variant) === -1 && (
          <PlaceholderComponent title={`ImagewithText / ${params.Variant}`} />
        )}
      {(params?.Variant === "Default" ||
        !params?.Variant ||
        params?.Variant === "Map") && (
        <>
          {lowCarbon !== null && (
            <section className={`md:pb-14 bg-${params?.BackgroundColor}`}>
              <div className="flex md:pt-24 pt-10">
                <div className="basis-full container">
                  <div className="mb-8 mt-5">
                    {!Object.keys(fields?.Image?.value).length &&
                    !Object.keys(fields?.BackgroundImage?.value).length ? (
                      <div className="md:px-20 px-3">
                        <h2 className="text-brand font-[500]">
                          <RichText field={fields?.Title} />
                        </h2>
                        <div className="mb-8 text-lg md:text-xl mt-10">
                          <RichText field={fields?.Description} />
                        </div>
                      </div>
                    ) : (
                      <div className="lg:flex lg:-mx-3 box-border">
                        <div className="basis-full md:basis-5/12 px-3 mb-8">
                          <h3 className="text-brand font-[500] uppercase">
                            <RichText field={fields?.Title} />
                          </h3>
                          <div className="mb-8 text-lg md:text-xl mt-10">
                            <RichText field={fields?.Description} />
                          </div>

                          {fields?.CTAbutton?.value?.href && (
                            <div className="mt-1">
                              <Link field={fields?.CTAbutton}>
                                <CTA>{fields?.CTAbutton?.value?.text}</CTA>
                              </Link>
                            </div>
                          )}
                        </div>
                        <div className="basis-full md:basis-7/12 px-3 justify-end   dark:block dark:bg-yellow-gradient">
                          {!lowCarbon && (
                            <>
                              <Image
                                loading="lazy"
                                field={fields?.BackgroundImage}
                                className={`lg:ms-auto ${
                                  params?.NoBlur !== "1" && "dark:opacity-0"
                                }`}
                                onClick={
                                  fields?.HighresolutionImage?.value?.src ||
                                  params?.Variant === "Map"
                                    ? () => {
                                        console.log("here");
                                        setOpen(true);
                                      }
                                    : () => null
                                }
                              />
                              {fields?.HighresolutionImage?.value?.src && (
                                <Lightbox
                                  open={open}
                                  close={() => setOpen(false)}
                                  plugins={[Zoom]}
                                  zoom={{ ref: zoomRef }}
                                  carousel={{
                                    finite: true,
                                  }}
                                  render={{
                                    buttonPrev: () => null,
                                    buttonNext: () => null,
                                  }}
                                  slides={[
                                    {
                                      src: fields?.HighresolutionImage?.value
                                        ?.src,
                                      alt: "image 1",
                                      width:
                                        fields?.HighresolutionImage?.value
                                          ?.width,
                                      height:
                                        fields?.HighresolutionImage?.value
                                          ?.height,
                                    },
                                    // ...
                                  ]}
                                />
                              )}
                              {/* {params?.Variant === "Map" && open && (
                                <MapPdfViewer
                                  isOpen={open}
                                  fileUrl={fields?.ImageLink?.value?.href}
                                  onClose={() => setOpen(false)}
                                />
                              )} */}
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </section>
          )}
        </>
      )}
    </div>
  );
};

export default ImagewithText;
