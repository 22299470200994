import React, { useState } from "react";
import { RichText } from "@sitecore-jss/sitecore-jss-react";
import plusCircle from "../../assets/icons/plus-circle.svg";
import plusCircleBlue from "../../assets/icons/plus-circle-blue.svg";
import plusCircleWhite from "../../assets/icons/plus-circle-white.svg";
import minusCircle from "../../assets/icons/minus-circle.svg";
import minusCircleBlue from "../../assets/icons/minus-circle-blue.svg";
import minusCircleWhite from "../../assets/icons/minus-circle-white.svg";
import TranslatedWord from "./DictionaryTexts";

const Collapser = (props) => {
  const { summary, children, color, blue } = props;

  const [more, setMore] = useState(false);

  return (
    <div
      className={`border-b ${
        color === "brand"
          ? "border-white"
          : blue === "Blue"
          ? "border-blueDark-600"
          : "border-brand"
      } pb-2`}
    >
      <div
        className={`text-lg md:text-xl ${
          color === "brand" ? "text-white" : "text-bluedark"
        } font-medium`}
      >
        <RichText field={summary} />
      </div>
      <div
        className={
          (more ? "visible h-fit mt-4" : "hidden h-0") +
          " text-bluedark text-lg md:text-xl"
        }
      >
        {children}
      </div>
      {children ? (
        <div
          className="w-fit mx-auto cursor-pointer flex justify-center items-center gap-3 mt-8"
          onClick={() => setMore((state) => !state)}
        >
          <img
            src={
              more
                ? color === "brand"
                  ? minusCircleWhite
                  : blue === "Blue"
                  ? minusCircleBlue
                  : minusCircle
                : color === "brand"
                ? plusCircleWhite
                : blue === "Blue"
                ? plusCircleBlue
                : plusCircle
            }
            className="w-fit h-fit"
            alt="pluscircle"
          />
          <p
            className={`${
              color === "brand"
                ? "text-white"
                : blue === "Blue"
                ? "text-blueDark-600"
                : "text-blueDark-500"
            } text-base font-[500] relative top-[-1px]`}
          >
            {more ? <TranslatedWord EnWord="Read Less" /> : <TranslatedWord EnWord="Read More" />}
          </p>
        </div>
      ) : (
        <div className="my-8"></div>
      )}
    </div>
  );
};

export default Collapser;
