import React, { useCallback, useState } from "react";
import {
  RichText,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import { loader as gqlLoader } from "graphql.macro";
import GraphQLData from "../../../../lib/GraphQLData";
import arrowTopWhite from "../../../../assets/icons/arrow-top.svg";
import arrowTopDark from "../../../../assets/icons/arrow-top-dark.svg";
import EventsListing from "../../ConnectedGQLEventsListing/ConnectedGQLEventsListing";
import parse from "html-react-parser";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";
import TranslatedWord from "../../../Common/DictionaryTexts";
const gqlQuery = gqlLoader("./connected-gql-news-listing.graphql");

export const getFormatedDate = (date) => {
  const newDate = new Date(date);
  const day = newDate.getDate();
  const month = newDate.toLocaleString("default", { month: "short" });
  const year = newDate.getFullYear().toString().slice(2);

  return day + " " + month + " " + year;
};
const ConnectedGQLNewsListing = (props) => {
  const [captcha, setCpatcha] = useState();
  //  handle Google Captcha validation
  const handleVerify = useCallback((res) => {
    console.log("captcha is ", res);
    setCpatcha(res);
  }, []);
  const GQL = GraphQLData(gqlQuery, {
    name: "gqlQuery",
    options: {
      variables: {
        recaptchaResponse: captcha,
      },
    },
  })(ConnectedGQLResults.bind(props));

  return (
    <div className=" min-h-screen">
      {!captcha && (
        <div className="container">
          <h3 className="text-brand pt-48">Loading ...</h3>
        </div>
      )}
      <GoogleReCaptchaProvider
        reCaptchaKey={props.sitecoreContext?.siteSettings?.SiteKey?.value}
      >
        <GoogleReCaptcha onVerify={handleVerify} />
        {captcha && <GQL {...props} captcha={captcha} />}
      </GoogleReCaptchaProvider>
    </div>
  );
};

const ConnectedGQLResults = (props) => {
  const gqlQuery = props.gqlQuery;
  const data = gqlQuery?.GQLResult?.results?.items;
  const optionsList = props?.SortByLabelSelection;
  const formatedOptions =
    optionsList &&
    optionsList.map((item) => ({
      label: item.name,
      value: item.url,
    }));

  const [sortby, setSortby] = useState("most_recent");

  const handleChange = (e) => {
    setSortby(e.target.value);
  };

  const [per, setPer] = useState(1);

  const onHandleButton = () => {
    if (per * 8 < data?.length) {
      setPer(per + 1);
    } else {
      setPer(1);
    }
  };

  const getButtonLabel = () => (per * 8 < data?.length ? "Load More" : "Hide");
  console.log("data ====", props);
  return (
    <div className="py-10 container">
      <div className="flex md:flex-row flex-col md:items-center items-start justify-between">
        <div className="text-brand font-[500] text-[40px]">
          {props?.params?.title || "ALL NEWS"}
        </div>
        <div className="md:w-[400px] w-full">
          {/* <div className="w-full mt-4 relative">
            <svg
              className="absolute end-4 top-0 bottom-0 m-auto "
              width="19"
              height="10"
              viewBox="0 0 19 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 1L9.5 9L17.5 1"
                stroke="#163331"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <select
              id="sort_by"
              name="sort_by"
              value={sortby}
              onChange={handleChange}
              className="block w-full px-4 py-3 md:py-5 border-b-2 border-[#575757] outline-none"
            >
              {formatedOptions && formatedOptions?.map((item, index) => (
                <option value={item.value} key={index}>
                  {item.label}
                </option>
              ))}
            </select>
          </div> */}
        </div>
      </div>
      {props?.params?.description && (
        <div className="block">{parse(props?.params?.description)}</div>
      )}
      <div className="mt-8 grid md:grid-cols-2 lg:grid-cols-4 grid-cols-1 gap-6">
        {data &&
          data?.slice(0, per * 8).map((item, index) => (
            <div key={index}>
              <a href={item?.item?.CTA?.url}>
                <div
                  className="bg-cover bg-center bg-no-repeat p-2 h-[200px]"
                  style={{
                    backgroundImage: `url(${item.item?.children[0]?.children[0]?.Image?.src})`,
                  }}
                >
                  {item?.item?.StartDate?.rendered && (
                    <div className="px-2 py-1  w-fit  rounded-full text-base text-tumeric-300 bg-tumeric-100">
                      {getFormatedDate(item?.item?.StartDate?.rendered)}
                    </div>
                  )}
                </div>
                <RichText
                  field={item.item?.children[0]?.children[0]?.Title}
                  className="text-brand font-[500] text-base mt-2"
                />
                <RichText
                  field={item.item.Description}
                  className="text-bluedark text-base mt-1"
                />
              </a>
            </div>
          ))}
      </div>
      {per * 8 < data?.length ? (
        <button
          onClick={onHandleButton}
          className="flex group mx-auto mt-8 items-center gap-2.5 px-[30px] py-4 bg-brand group hover:bg-lime-green-900"
        >
          <img
            src={arrowTopWhite}
            alt="arrow-top-white"
            className={`${
              per * 8 < data?.length ? "rotate-180" : "rotate-0"
            } group-hover:hidden block`}
          />
          <img
            src={arrowTopDark}
            alt="arrow-top-dark"
            className={`${
              per * 8 < data?.length ? "rotate-180" : "rotate-0"
            } group-hover:block hidden`}
          />
          <div className="text-white group-hover:text-brand font-bold text-base">
            <TranslatedWord EnWord={getButtonLabel()} />
          </div>
        </button>
      ) : null}
    </div>
  );
};

// compose() (from @apollo/client/react/hoc) can be used when you need more than one GraphQL query
// for a single query, e.g. `compose(GraphQLData(q1), GraphQLData(q2))(component)`
export default withSitecoreContext()(ConnectedGQLNewsListing);
