import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import ReactPaginate from "react-paginate";

const Pagination = (props) => {
  console.log(props, "props pagina");
  const { totalPage, onClick } = props;
  const [currentWidth, setCurrentWidth] = useState();

  useEffect(() => {
    setCurrentWidth(window.innerWidth);
  }, []);

  if (typeof window !== "undefined") {
    const widthResizer = () => {
      const width = window.innerWidth;
      setCurrentWidth(width);
    };

    // Getting the width of the browser whenever the screen resolution changes.
    window.addEventListener("resize", widthResizer);
  }

  return (
    <div className="text-center relative z-10">
      <div className="pagination mx-auto w-fit">
        <ReactPaginate
          nextLabel={
            props?.isRTL ? (
              <FontAwesomeIcon icon={faChevronLeft} />
            ) : (
              <FontAwesomeIcon icon={faChevronRight} />
            )
          }
          onPageChange={onClick}
          pageRangeDisplayed={currentWidth > 400 ? 3 : 2}
          marginPagesDisplayed={1}
          pageCount={totalPage}
          previousLabel={
            props?.isRTL ? (
              <FontAwesomeIcon icon={faChevronRight} />
            ) : (
              <FontAwesomeIcon icon={faChevronLeft} />
            )
          }
          pageLinkClassName="page-link"
          previousLinkClassName="page-link"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakLinkClassName="page-link"
          activeClassName="active"
          renderOnZeroPageCount={null}
          initialPage={0}
          disableInitialCallback={true}
        />
      </div>
    </div>
  );
};

export default Pagination;
