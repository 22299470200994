import React, { useCallback, useEffect, useState } from "react";
import CTA from "../../../Common/CTA";
import parse from "html-react-parser";
import axios from "axios";
// import keys from "../../../../../scjssconfig.json";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import {
  Form,
  createDefaultFieldFactory,
  FieldTypes,
} from "@sitecore-jss/sitecore-jss-react-forms";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import config from "../../../../temp/config";
import SingleLineText from "../Fields/SingleLineText";
const defaultFieldFactory = createDefaultFieldFactory();

const Forms = ({ sitecoreContext, params, fields }) => {
  console.log("fields", fields);
  const [formErros, setFormErros] = useState([]);
  const [validCaptcha, setValidCaptcha] = useState(null);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [refreshReCaptcha, set_refreshReCaptcha] = useState(false);
  const [timerId, set_timerId] = useState();

  const reloadCaptcha = () => {
    setValidCaptcha(false);
    set_refreshReCaptcha((r) => !r);
  };
  const handleHiddenField = (captcha) => {
    // fields?.fields?.forEach((field) => {
    //   if (field.model.name === params.HiddenField) {
    //     // hiddenItemTypeId = field.model.fieldTypeItemId
    //     field.model.value = captcha
    //   }
    // });
    window.localStorage.setItem("captcha", captcha);
    const elem = document.getElementById("captcha_hidden");
    if (elem) {
      elem.click();
    }
  };

  //  handle Google Captcha validation
  const handleVerify = function (res) {
    setValidCaptcha(res);
    handleHiddenField(res);
  };

  // handle form submission
  const handleSubmit = function () {
    setTimeout(() => {
      setDisableSubmit(true);
    });
    setTimeout(() => {
      setDisableSubmit(false);
      reloadCaptcha();
    }, 2000);
  };

  useCallback(() => {
    clearTimeout(timerId);

    let id = setTimeout(reloadCaptcha, 100000);
    set_timerId(id);
  });

  let hiddenItemTypeId;
  fields?.fields?.forEach((field) => {
    if (field.model.name === params.HiddenField) {
      hiddenItemTypeId = field.model.fieldTypeItemId;
      field.model.value = validCaptcha;
    }
  });
  const setCaptchaValue = (props) => {
    let captcha = window.localStorage.getItem("captcha");
    window.localStorage.removeItem("captcha");
    console.log(
      "----------------- VERIFYING -----------------------\n",
      captcha
    );
    props.field.valueField.value = captcha;
    props.field.model.value = captcha;
  };
  defaultFieldFactory.setComponent(hiddenItemTypeId, (props) => {
    return (
      <fieldset className="w-0 h-0 m-0">
        <div id="captcha_hidden" onClick={() => setCaptchaValue(props)}>
          <input type="text" className="w-0 h-0" />
        </div>
      </fieldset>
    );
  });
  defaultFieldFactory.setComponent(FieldTypes.CheckboxList, (props) => {
    return (
      <>
        <div className="flex flex-wrap  items-center  ">
          {props.field.model.items?.map((item, i) => {
            const [value, setValue] = useState(props.value);

            return (
              <div key={i} className="basis-full md:basis-4/12 px-4 mb-4 ">
                <fieldset className="flex justify-between items-center h-full ">
                  <input
                    id={props.field.valueField.id + i}
                    className={`cursor-pointer peer appearance-none absolute border-2 w-5 h-5 bg-transparent checked:border-0  bg-cover ${
                      params?.BackgroundColor === "brand"
                        ? "checked:bg-check"
                        : "checked:bg-checkFill"
                    }`}
                    type="checkbox"
                    name={item.text.toLowerCase().replaceAll(" ", "_")}
                    onChange={(e) => handleOnChange(props, item.value, item)}
                  />
                  <span className="ms-10 ">{item.value}</span>
                </fieldset>
              </div>
            );
          })}
        </div>
      </>
    );
  });
  defaultFieldFactory.setComponent(FieldTypes.Checkbox, (props) => {
    const [value, setValue] = useState(!!props.value);
    return (
      <>
        <fieldset className="flex justify-between items-center h-full relative w-full">
          <input
            id={props.field.model.itemId}
            className={`cursor-pointer peer appearance-none absolute border-2 w-5 h-5 bg-transparent checked:border-0  bg-cover ${
              params?.BackgroundColor === "brand"
                ? "checked:bg-check"
                : "checked:bg-checkFill"
            }`}
            type="checkbox"
            // required={props.field.model.required}
            value={props.value}
            name={props.field.model.name.toLowerCase().replaceAll(" ", "_")}
            checked={!!value}
            onChange={(e) => {
              setValue(!value);
              props.onChange(
                props.field.valueField.name,
                !value,
                props.isValid,
                []
              );
            }}
          />
          <span className="ms-10 ">{props.field.model.title}</span>
        </fieldset>
        {props.errors?.map((error, i) => {
          return (
            <p className="my-2 text-rose-900" key={i}>
              {error}
            </p>
          );
        })}
      </>
    );
  });
  defaultFieldFactory.setComponent(FieldTypes.SingleLineText, (props) => (
    <SingleLineText props={props} key={`parent-${props.field.model.itemId}`} />
  ));
  defaultFieldFactory.setComponent(FieldTypes.DateField, (props) => {
    const [value, setValue] = useState(props.value);
    return (
      <>
        <div className="basis-full md:basis-6/12  mb-4 md:mb-14">
          <div className="w-full mt-4">
            <label className="w-full  font-medium mb-3 ">
              {props.field.model.title}
            </label>
            <input
              className={`w-full text-base bg-transparent px-4 py-3  md:py-5 border-b-2 outline-none ${
                !props.isValid ? "border-rose-900" : " border-current"
              }`}
              type="date"
              name={props.field.model.name}
              // pattern="^[A-Za-z][A-Za-z!@#$%^&amp;* ]*$"
              placeholder={props.field.model.placeholderText}
              value={props.value}
              // required={props.field.model.required}
              onChange={(e) => {
                setValue(e?.target?.value);
                props.onChange(
                  props.field.valueField.name,
                  e?.target?.value,
                  props.isValid,
                  []
                );
                e.target.classList.remove("border-rose-900");
              }}
            />
          </div>
          {props.errors?.map((error, i) => {
            return (
              <p className="my-2 text-rose-900" key={i}>
                {error}
              </p>
            );
          })}
        </div>
      </>
    );
  });
  defaultFieldFactory.setComponent(FieldTypes.DropdownList, (props) => {
    // console.log(props, "dropdown",props.field.model.name);
    const [value, setValue] = useState(props.value[0]);
    return (
      <>
        <div className="basis-full md:basis-6/12  mb-4 md:mb-14">
          <div className="w-full mt-4 relative min-h-[126px] grid items-stretch">
            <label className="w-full  font-medium mb-3 ">
              {props.field.model.title}
            </label>
            <select
              className={`w-full text-base bg-transparent px-4 py-3  md:py-5 border-b-2 outline-none ${
                props.errors.length ? "border-rose-900" : " border-current"
              }`}
              type="text"
              name={props.field.model.name}
              pattern="^[A-Za-z][A-Za-z!@#$%^&amp;* ]*$"
              placeholder={props.field.model.placeholderText}
              // required={props.field.model.required}
              value={props.value[0]}
              onChange={(e) => {
                setValue(e?.target?.value);
                props.onChange(
                  props.field.valueField.name,
                  [e?.target?.value],
                  props.isValid,
                  []
                );
                e.target.classList.remove("border-rose-900");
              }}
            >
              {props.field.model.items?.map((item, i) => (
                <option value={item.text} id={item.itemId} key={i}>
                  {item.value}
                </option>
              ))}
            </select>
            <FontAwesomeIcon
              icon={faChevronDown}
              className="absolute end-0 bottom-3 w-4 h-4"
            />
          </div>
          {props.errors?.map((error, i) => {
            return (
              <p className="my-2 text-rose-900" key={i}>
                {error}
              </p>
            );
          })}
        </div>
      </>
    );
  });
  defaultFieldFactory.setComponent(FieldTypes.NumberField, (props) => {
    const [value, setValue] = useState(props.value);

    return (
      <>
        <div className="basis-full md:basis-6/12  mb-4 md:mb-14">
          <div className="w-full mt-4 min-h-[126px] grid items-stretch">
            <label className="w-full  font-medium mb-3 ">
              {props.field.model.title}
            </label>
            <input
              className={`w-full text-base bg-transparent px-4 py-3  md:py-5 border-b-2 outline-none ${
                props.errors.length ? "border-rose-900" : " border-current"
              }`}
              type="tel"
              value={props.value}
              name={props.field.model.name}
              placeholder={props.field.model.placeholderText}
              onChange={(e) => {
                setValue(e?.target?.value);
                props.onChange(
                  props.field.valueField.name,
                  e?.target?.value,
                  props.isValid,
                  []
                );
                e.target.classList.remove("border-rose-900");
              }}
            />
          </div>
          {props.errors?.map((error, i) => {
            return (
              <p className="my-2 text-rose-900" key={i}>
                {error}
              </p>
            );
          })}
        </div>
      </>
    );
  });
  defaultFieldFactory.setComponent(FieldTypes.Telephone, (props) => {
    const [value, setValue] = useState(props.value);

    return (
      <>
        <div className="basis-full md:basis-6/12  mb-4 md:mb-14">
          <div className="w-full mt-4 min-h-[126px] grid items-stretch">
            <label className="w-full  font-medium mb-3 ">
              {props.field.model.title}
            </label>
            <input
              className={`w-full text-base bg-transparent px-4 py-3  md:py-5 border-b-2 outline-none ${
                props.errors.length ? "border-rose-900" : " border-current"
              }`}
              type="tel"
              value={props.value}
              name={props.field.model.name}
              placeholder={props.field.model.placeholderText}
              onChange={(e) => {
                setValue(e?.target?.value);
                props.onChange(
                  props.field.valueField.name,
                  e?.target?.value,
                  props.isValid,
                  []
                );
                e.target.classList.remove("border-rose-900");
              }}
            />
          </div>
          {props.errors?.map((error, i) => {
            return (
              <p className="my-2 text-rose-900" key={i}>
                {error}
              </p>
            );
          })}
        </div>
      </>
    );
  });
  defaultFieldFactory.setComponent(FieldTypes.Email, (props) => {
    // console.log(props, "Email");
    const [value, setValue] = useState(props.value);

    return (
      <>
        <div className="basis-full md:basis-6/12  mb-4 md:mb-14">
          <div className="w-full mt-4 min-h-[126px] grid items-stretch">
            <label className="w-full  font-medium mb-3 ">
              {props.field.model.title}
            </label>
            <input
              className={`w-full text-base bg-transparent px-4 py-3  md:py-5 border-b-2 outline-none ${
                !props.isValid ? "border-rose-900" : " border-current"
              }`}
              type="email"
              name={props.field.model.name}
              placeholder={props.field.model.placeholderText}
              // required={props.field.model.required}
              value={props.value}
              onChange={(e) => {
                setValue(e?.target?.value);
                props.onChange(
                  props.field.valueField.name,
                  e?.target?.value,
                  props.isValid,
                  []
                );
                e.target.classList.remove("border-rose-900");
              }}
            />
          </div>
          {props.errors?.map((error, i) => {
            return (
              <p className="my-2 text-rose-900" key={i}>
                {error}
              </p>
            );
          })}
        </div>
      </>
    );
  });
  defaultFieldFactory.setComponent(FieldTypes.Button, (props) => {
    return (
      <>
        <CTA
          type="submit"
          className="group !flex lg:ms-auto "
          onClick={handleSubmit}
          disabled={disableSubmit}
        >
          <span className="text-blueDark-500">{props.field.model.title}</span>
        </CTA>
      </>
    );
  });
  defaultFieldFactory.setComponent(FieldTypes.TextField, (props) => {
    return (
      <>
        {props.field.model.htmlTag !== "h5" && parse(props.field.model.text)}
        {props.field.model.htmlTag === "h5" && (
          <div className="pb-7 md:pb-14 flex justify-between items-center">
            <div className="w-full">
              <p className="w-full text-lg md:text-xl font-[500] text-white break-word font-URW text-[30px]">
                {props.field.model.text}
              </p>
            </div>
          </div>
        )}
      </>
    );
  });
  const ErrorComponent = (props) => {
    return (
      <div className="absolute top-full">
        {props.formErrors?.map((e, index) => {
          return (
            <div
              className="invalid text-rose-900 mb-4"
              key={`formError-${index}`}
            >
              {e.split("|")[0]}
            </div>
          );
        })}
      </div>
    );
  };
  const FieldErrorComponent = function (props) {
    return (
      <div>
        {props.errors?.map((error, index) => (
          <p style={{ color: "red", fontWeight: "bold" }} key={index}>
            {error}
          </p>
        ))}
      </div>
    );
  };
  return (
    <section
      className={` py-5 md:py-8 lg:py-20 relative ${
        params?.BackgroundColor === "brand"
          ? "bg-brand text-white"
          : "bg-taupe-400 text-bluedark"
      }`}
    >
      <div className="container">
        <div className="pb-7 md:pb-10 flex justify-between items-center">
          <GoogleReCaptchaProvider
            reCaptchaKey={sitecoreContext?.siteSettings?.SiteKey?.value}
          >
            {!validCaptcha ? (
              <GoogleReCaptcha
                onVerify={handleVerify}
                refreshReCaptcha={refreshReCaptcha}
              />
            ) : null}
          </GoogleReCaptchaProvider>
          <Form
            fieldFactory={defaultFieldFactory}
            form={fields}
            sitecoreApiHost={""}
            sitecoreApiKey={config.sitecoreApiKey}
            errorComponent={ErrorComponent}
            fieldValidationErrorsComponent={FieldErrorComponent}
            className={`relative ${fields?.metadata?.cssClass}`}
            novalidate
            key={fields?.formItemId?.id}
          />
        </div>
      </div>
    </section>
  );
};

function handleOnChange(props, event, item) {
  props.field.valueField.value = event;
  props.field.model.value = event;
  if (item !== null && item !== undefined) {
    if (item.selected === false) {
      item.selected = true;
    } else {
      item.selected = false;
    }
  }
}

export default withSitecoreContext()(Forms);
// export default Forms;
