import React from "react";
import { Image, Link, RichText } from "@sitecore-jss/sitecore-jss-react";
import CTA from "../../../Common/CTA";
import DateBadge from "../../../Common/DateBadge";

const TopNews = ({ fields }) => {
  const data = fields?.data?.GQLResult?.results?.items[0]?.item;

  return (
    <div className="container">
      <div className="bg-white md:py-24 py-[56px]  flex items-center md:flex-row flex-col">
        <Image
          field={data?.children[0]?.children[0]?.Image}
          className="md:w-1/2 w-full h-[400px] object-cover"
        />
        <div className="bg-white py-6 md:px-6 px-0 md:w-1/2 w-full">
          {data?.StartDate?.rendered && (
            <DateBadge date={data?.StartDate?.rendered} />
          )}
          <div className="mt-4">
            <RichText
              field={data?.children[0]?.children[0]?.Title}
              className="text-brand font-bold text-lg md:text-xl"
            />
            <RichText
              field={data?.Description}
              className="text-bluedark text-base mt-4"
            />
            <div className="mt-4">
              <CTA>
                <a href={data?.CTA?.url}>{data?.CTA?.text}</a>
              </CTA>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopNews;
