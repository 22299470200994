import React, { useEffect, useState } from "react";
import { Link, Text } from "@sitecore-jss/sitecore-jss-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import TranslatedWord from "../../../Common/DictionaryTexts";

const SocialFeeds = ({ fields, params }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [instagramFeeds, setInstagramFeeds] = useState([]);
  let postsCount = 0;
  useEffect(() => {
    axios
      .get(
        `${fields?.Instagram?.fields?.API?.value}?access_token=${fields?.Instagram?.fields?.Token?.value}&fields=media{media_url,media_type,permalink}`
      )
      .then((res) => {
        setInstagramFeeds(res.data.media.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, [setInstagramFeeds]);
  return (
    <section className={`bg-${params.BackgroundColor} md:pt-14 pt-7`}>
      <div className="container ">
        <div className="text-center ">
          <h3 className="mb-6">
            <Text field={fields?.Title} />
          </h3>
          <p className="mb-6">
            <Text field={fields?.Description} />
          </p>
          {fields?.SocialIcons?.length && (
            <ul className="list-none flex justify-center -mx-2">
              {fields?.SocialIcons.map((icon, index) => {
                return (
                  <li key={index} className="px-2 ">
                    <Link
                      field={icon.fields?.CTALink}
                      className="bg-brand w-11 h-11 rounded-full inline-flex justify-center items-center text-lg text-white "
                    >
                      <FontAwesomeIcon
                        icon={`fa-brands fa-${icon.fields?.Title?.value} `}
                      />
                    </Link>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
        <div className="pt-6">
          {isLoading && (
            <div className="flex -mx-3">
              <div className="h-96 animate-pulse px-3 basis-4/12">
                <div className="bg-slate-200 h-full" />
              </div>
              <div className="h-96 animate-pulse px-3 basis-4/12">
                <div className="bg-slate-200 h-full" />
              </div>
              <div className="h-96 animate-pulse ">
                <div className="bg-slate-200 h-full" />
              </div>
            </div>
          )}
          <div className="flex flex-wrap">
            {instagramFeeds.length
              ? instagramFeeds.map((item, index) => {
                  if (item.media_type !== "VIDEO") {
                    postsCount++;
                    if (postsCount > 3) return;

                    return (
                      <a
                        key={index}
                        href={item.permalink}
                        className="w-full px-3 basis-full mb-6 md:basis-4/12 flex"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={item.media_url}
                          className="object-cover md:h-full"
                        />
                      </a>
                    );
                  }
                })
              : null}
            {/* {!isLoading && !instagramFeeds.length ? (
              <>
                <h3>
                  <TranslatedWord EnWord="There" />{" "}
                </h3>
              </>
            ) : null} */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SocialFeeds;
