import React, { useRef, useState } from "react";
import { RichText, Image, Text } from "@sitecore-jss/sitecore-jss-react";
import Card from "../../../Common/Card";
import Pagination from "../../../Common/Pagination";

const Profiles = ({ fields, params, isRtl }) => {
  console.log(isRtl, "isRtl in profiles");
  const ref = useRef(null);

  const data = fields?.data?.GQLResult?.results?.items;
  const bgImage = fields?.data?.GQLSource?.BackgroundImage;
  const title = fields?.data?.GQLSource?.Title;
  const description = fields?.data?.GQLSource?.Description;
  const style = params?.BackgroundColor === "white" ? "light" : "dark";
  const perpage = fields?.data?.GQLSource?.ResultsPerPage?.intValue;
  const totalPage = Math.ceil(data.length / perpage);

  const [activePage, setActivePage] = useState(1);

  const onClick = (event) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
    setActivePage(event.selected + 1);
  };

  return (
    <main
      style={
        style === "dark"
          ? {
              background:
                "linear-gradient(180deg, #163331 0%, rgba(0, 0, 0, 0.00) 51.56%), linear-gradient(180deg, #173330 0%, #145A50 33.33%, #118170 66.67%, #BEE2D5 100%)",
            }
          : { background: "white" }
      }
    >
      <div className="container">
        <Image field={bgImage} className="absolute w-full h-full start-0 z-0" />
        {(description?.value || title?.value) && (
          <div className=" text-white pt-7 md:pt-14">
            {title?.value && (
              <h2 className="mb-7 md:mb-14 text-brand font-[500]">
                <Text field={title} />
              </h2>
            )}
            {description?.value && <RichText field={description} />}
          </div>
        )}
        <div className="">
          <div
            className={`mt-24 flex flex-wrap gap-y-14 box-border -mx-3 justify-${
              params.Alignment || "center"
            }`}
            ref={ref}
          >
            {data &&
              data
                .slice(
                  (activePage - 1) * perpage,
                  activePage * (perpage || data?.length)
                )
                .map((item, index) => (
                  <Card
                    key={index}
                    name={item?.item?.Title}
                    style={style}
                    image={item?.item?.ProfileImage}
                    country={item?.item?.Country}
                    bio={item?.item?.Description}
                    role={item?.item?.SubTitle}
                    modalIsOff={!item?.item?.Description?.value}
                    cta={item?.item?.CTA}
                  />
                ))}
          </div>
        </div>
        <div className="w-full py-20">
          {perpage && (
            <Pagination
              totalPage={totalPage}
              onClick={onClick}
              isRTL={isRtl === "true" ? true : false}
            />
          )}
        </div>
      </div>
    </main>
  );
};

export default Profiles;
