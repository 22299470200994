import React, { useState } from "react";
import { RichText } from "@sitecore-jss/sitecore-jss-react";
import Accordion from "../../../Common/Accordion";

const LeftTitle = ({ fields, params }) => {
  const [activeItemIndex, setActiveItemIndex] = useState("");

  const onClickAccordion = (blockIndex, itemIndex) => {
    if (itemIndex === activeItemIndex) {
      setActiveItemIndex(null); // Clicking on the same item closes it
    } else {
      setActiveItemIndex(itemIndex); // Clicking on a different item sets it as active
    }
  };

  return (
    <section
      className={`bg-${params?.BackgroundColor} md:px-20 md:py-20 py-10 px-6 md:flex`}
    >
      <div className="md:w-3/12">
        <h4
          className={`text-start ${
            params?.BackgroundColor === "white" ? "text-brand" : "text-white"
          }`}
        >
          <RichText field={fields?.Title} />
        </h4>
      </div>
      <div className="md:w-9/12 md:mt-0 mt-10">
        <div
          className={`text-lg md:text-xl  ${
            params?.BackgroundColor === "white" ? "text-bluedark" : "text-white"
          }`}
        >
          <RichText field={fields?.Description} />
        </div>
        {fields?.Faq.map((value, itemIndex) =>
          value?.fields?.Title?.value ? (
            <Accordion
              key={itemIndex}
              iconField={value?.fields?.Icon}
              style={params?.BackgroundColor === "brand" ? "dark" : "light"}
              open={itemIndex === activeItemIndex}
              title={value?.fields?.Title}
              index={itemIndex}
              onClick={onClickAccordion}
            >
              <p
                className={`md:text-xl text-base ${
                  params?.BackgroundColor === "white"
                    ? "text-bluedark"
                    : "text-white"
                }`}
              >
                <RichText field={value?.fields?.Description} />
              </p>
            </Accordion>
          ) : (
            <RichText key={itemIndex} field={value?.fields?.Description} />
          )
        )}
      </div>
    </section>
  );
};

export default LeftTitle;
