import React, { useState } from "react";
import TranslatedWord from "../../../Common/DictionaryTexts";

const FilterBox = ({ title, items, selectedItems, set_selectedItems }) => {
  const [expanded, set_expanded] = useState(true);

  const toggleSelect = (index) => {
    let temp = selectedItems ? selectedItems : [];
    const i = temp.indexOf(index);
    if (i > -1) {
      temp.splice(i, 1);
    } else {
      temp.push(index);
    }
    set_selectedItems([...temp]);
  };

  return items.length ? (
    <div className="w-full border-0 border-t border-blueDark-500 py-6">
      <div
        className="flex space-x-2 rtl:space-x-reverse items-center cursor-pointer"
        onClick={() => set_expanded(!expanded)}
      >
        <div className="text-brand text-[20px] leading-[24px] font-[500]">
          <TranslatedWord EnWord={title} />
        </div>
        <svg
          className={`${
            expanded ? "" : "rotate-180 translate-y-1"
          } transition-all`}
          width="16"
          height="24"
          viewBox="0 0 16 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_2671_110928)">
            <path
              d="M4 12.0225L8 16.0225L12 12.0225"
              stroke="#118170"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_2671_110928">
              <rect
                width="23"
                height="16"
                fill="white"
                transform="matrix(0 1 -1 0 16 0.0224609)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div className="w-full lg:max-h-[500px] lg:overflow-y-auto">
        {expanded
          ? items.map((item, index) => (
              <div
                key={`${title}_${index}`}
                className="w-full mt-6 flex space-x-2 rtl:space-x-reverse items-center cursor-pointer"
                onClick={() => toggleSelect(index)}
              >
                <div>
                  {selectedItems?.indexOf(index) > -1 ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M20.4 21.75C21.1456 21.75 21.75 21.1456 21.75 20.4V3.6C21.75 2.85442 21.1456 2.25 20.4 2.25H3.6C2.85442 2.25 2.25 2.85441 2.25 3.6V20.4C2.25 21.1456 2.85441 21.75 3.6 21.75H20.4ZM6.23003 13.5836C5.9077 13.2613 5.9077 12.7387 6.23003 12.4164C6.55235 12.094 7.07495 12.094 7.39727 12.4164L9.88229 14.9014L16.9702 7.8134C17.2926 7.49107 17.8152 7.49107 18.1375 7.8134C18.4598 8.13573 18.4598 8.65832 18.1375 8.98065L10.4659 16.6522C10.1436 16.9746 9.62099 16.9746 9.29866 16.6522L6.23003 13.5836Z"
                        fill="#118170"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M20.25 20.25V3.75H3.75V20.25H20.25ZM21.75 20.4C21.75 21.1456 21.1456 21.75 20.4 21.75H3.6C2.85441 21.75 2.25 21.1456 2.25 20.4V3.6C2.25 2.85441 2.85442 2.25 3.6 2.25H20.4C21.1456 2.25 21.75 2.85442 21.75 3.6V20.4Z"
                        fill="#163331"
                      />
                    </svg>
                  )}
                </div>
                <div
                  title={item?.fields?.Value?.value}
                  className="w-[calc(100%-32px)] text-[16px] leading-[24px] text-blueDark-500 truncate"
                >
                  {item?.fields?.Value?.value}
                </div>
              </div>
            ))
          : null}
      </div>
    </div>
  ) : null;
};

export default FilterBox;
