import React, { useState } from "react";
import { RichText } from "@sitecore-jss/sitecore-jss-react";
import Accordion from "../../../Common/Accordion";
import Collapser from "../../../Common/Collapser";

const ReadMoreLess = ({ fields, params }) => {
  const [activeItemIndex, setActiveItemIndex] = useState("");

  return (
    <div>
      <section className="bg-white md:px-20 pt-20 px-6">
        <h3 className="text-start text-brand mb-10">
          <RichText field={fields?.Title} />
        </h3>
        <div className="mt-8 text-lg md:text-xl md:w-4/5 w-full">
          <RichText field={fields?.Description} />
        </div>
        <div className="md:w-4/5 w-full">
          <div className="mt-8">
            {fields?.Faq.map((value, itemIndex) => (
              <Collapser key={itemIndex} summary={value?.fields?.Title}>
                <p
                  className={`${
                    value?.fields?.BackgroundColor?.value === "brand"
                      ? "text-white"
                      : "text-bluedark"
                  } text-lg md:text-xl`}
                >
                  <RichText field={value?.fields?.Description} />
                </p>
              </Collapser>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default ReadMoreLess;
