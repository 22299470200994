import React from "react";
import { Image, Link } from "@sitecore-jss/sitecore-jss-react";
import parse from "html-react-parser";
import CTA from "../../../Common/CTA";
// import Arrow_TOP from "../../../../../src/lib/"
import Arrow_TOP from "../../../../assets/images/arrow-ios-right.svg";
import PhoneIcon from "../../../../assets/icons/phone_icon.svg";

const Footer = ({ fields }) => {
  const scrollTopHandler = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  console.log(fields);
  return (
    <footer className="bg-brand pb-[24px] pt-[56px]  relative">
      <div
        className="absolute h-[56px] w-[56px] rounded-full flex justify-center items-center z-50 translate-y-[-50%] end-[24px] lg:end-[78px] bg-lime-green-900 top-0 cursor-pointer"
        onClick={scrollTopHandler}
      >
        <img src={Arrow_TOP} alt="arrow top" />
      </div>
      <div className="container">
        <div className="flex flex-wrap -mx-3 w-full box-border pb-10 md:pb-20 border-b border-b-lime-green-900 hidden">
          {fields?.SelectedLinks.map((item, i) => {
            return (
              <div
                className="z-10 mb-8  basis-full md:basis-4/12 lg:basis-2/12 px-3"
                key={i}
              >
                <p className="text-lime-green-900 text-[25px] mb-2">
                  {item?.fields?.Title?.value}
                </p>
                <div className="flex flex-col items-start">
                  {item?.fields?.SelectCTALinks.map((item, i) => {
                    return (
                      <div key={i}>
                        <Link
                          field={item?.fields?.CTALink}
                          className="mb-1 text-white text-base block underline"
                        ></Link>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
        <div className="pb-[40px] flex flex-col lg:flex-row justify-center items-center lg:justify-between">
          <Image field={fields?.PathWayLogo} className="bi" />
          <div>
            {fields?.DownloadAppLabel?.value && (
              <h5 className="mt-4  mb-4 lg:mt-0 text-[16px] leading-[18px] lg:leading-[28px] lg:text-[24px] font-[400] text-white">
                {parse(fields?.DownloadAppLabel?.value)}
              </h5>
            )}
            <div className="flex flex-col lg:flex-row flex-wrap w-full gap-4 lg:gap-8 justify-between lg:justify-between">
              {fields?.AppleStoreCTA?.value?.href && (
                <a
                  href={fields?.AppleStoreCTA?.value?.href}
                  className="text-center"
                >
                  <img
                    src={fields?.AppleStoreImage?.value?.src}
                    className="inline-block"
                  />
                </a>
              )}
              {fields?.GooglePlayCTA?.value?.href && (
                <a
                  href={fields?.GooglePlayCTA?.value?.href}
                  className="text-center"
                >
                  <img
                    src={fields?.GooglePlayImage?.value?.src}
                    className="inline-block"
                  />
                </a>
              )}
            </div>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row flex-wrap mb-[24px] md:mb-0 justify-between  md:pb-[40px]  -mx-3">
          <div className="flex basis-full flex-col lg:flex-row md:basis-6/12 px-3 lg:gap-[40px] lg:items-center">
            <Link field={fields?.PrimaryLogoCTALink} className="">
              <Image field={fields?.PrimaryLogo} className="bi" />
            </Link>
            <Link
              field={fields?.SecondaryLogoCTALink}
              className="mt-[24px] lg:mt-0"
            >
              <Image field={fields?.SecondaryLogo} />
            </Link>
          </div>
          <div className="flex basis-full md:basis-6/12 px-3 ">
            <ul className="flex ms-0 lg:ms-auto me-0 items-center lg:items-start gap-2 text-lg list-none mt-6 lg:mt-0">
              {fields?.SocialIconList.map((item, i) => {
                return (
                  <li className="h-[46px] w-[46px]" key={i}>
                    <Link
                      target="_blank"
                      field={item?.fields?.Link}
                      className="bg-white text-brand border border-1 border-white hover:bg-transparent hover:text-white inline-flex  w-12 h-12 items-center justify-center rounded-full"
                    >
                      <Image field={item?.fields?.Icon} />
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="pt-[16px]">
          <div className="flex flex-wrap w-full gap-[24px]">
            <div className="basis-full md:basis-full text-white flex flex-wrap justify-between">
              <div className="basis-full lg:basis-7/12">
                <ul className="list-none flex gap-1 flex-wrap ms-0">
                  {fields?.CopyRightLinks?.map((link, i) => {
                    return (
                      <li key={i}>
                        <Link field={link.fields?.CTALink} />{" "}
                        {i < fields?.CopyRightLinks.length - 1 && "|"}
                      </li>
                    );
                  })}
                </ul>
                <p className="mb-1 me-10">{fields?.CopyRightText?.value}</p>
              </div>
              <div className="basis-full lg:basis-5/12 lg:order-1 -order-1 mb-6">
                <div className="lg:text-end flex flex-col-reverse lg:flex-row flex-wrap lg:flex-nowrap lg:items-center lg:-mx-2 lg:justify-end ">
                  <div className="lg:px-2">
                    {parse(fields?.CallCenterDetails?.value || "")}
                  </div>
                  <div className="lg:px-2 mb-2 lg:mb-0">
                    {/* <img src={PhoneIcon} className="rtl:scale-x-[-1]" /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
