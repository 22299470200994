import React from "react";

const DateBadge = (props) => {
  const { date } = props;

  return (
    <div className="px-2 py-1  w-fit text-tumeric-300 bg-tumeric-100 rounded-full font-medium text-base">
      {new Date(date).getDate()}{" "}
      {new Date(date).toLocaleString("default", { month: "short" })}{" "}
      {new Date(date).getFullYear().toString().slice(-2)}
    </div>
  );
};

export default DateBadge;
