import React from "react";
import { RichText, Link } from "@sitecore-jss/sitecore-jss-react";
import CTA from "../../../Common/CTA";

const CTAsBanner = ({ fields }) => {
  return (
    <div className={`container`}>
      <div
        className="w-full rounded-2xl relative bg-no-repeat bg-center bg-cover md:px-6 md:py-12 px-5 py-6"
        style={{
          backgroundImage: `url(${fields?.BackgroundImage?.value?.src})`,
        }}
      >
        <div
          className="w-full h-full rounded-2xl absolute top-0 start-0"
          style={{
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%)",
          }}
        ></div>
        <div className="lg:flex relative z-50">
          <div className="xl:w-8/12 lg:w-7/12 w-full">
            <RichText
              field={fields?.Title}
              className="uppercase text-white md:text-[40px] text-3xl leading-[40.8px] font-medium"
            />
          </div>
          <div className="xl:w-4/12 lg:w-5/12 w-full lg:flex lg:items-center lg:mt-0 mt-5 justify-end">
            <div>
              <CTA
                className="flex w-fit items-center ms-auto"
                link={fields?.CTAButton1}
                download={false}
                external={fields?.CTAButton1?.value?.linktype === "external"}
                color={"brand"}
              >
                <Link field={fields?.CTAButton1} />
              </CTA>
            </div>
            <div className="lg:ms-6 lg:mt-0 mt-2">
              <CTA
                className="flex w-fit items-center ms-auto"
                link={fields?.CTAButton2}
                download={false}
                external={fields?.CTAButton2?.value?.linktype === "external"}
                color={"blueDark"}
              >
                <Link field={fields?.CTAButton2} />
              </CTA>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CTAsBanner;
