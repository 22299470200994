import React, { useEffect, useState } from "react";
import Default from "./Default";
import HighLights from "./HighLights";

const MediaGallery = ({ fields, params, isRtl }) => {
  const [lowCarbon, setLowCarbon] = useState(null);
  useEffect(() => {
    setLowCarbon(window.localStorage.getItem("low-carbon-mode") === "true");
  });
  const getComponents = (variant) => {
    switch (variant) {
      case "HighLights":
        return <HighLights fields={fields} isRtl={isRtl} />;
      default:
        return (
          <Default
            isLowCarbon={lowCarbon}
            fields={fields}
            isRtl={isRtl}
            params={params}
          />
        );
    }
  };

  return getComponents(params?.Variant);
};

export default MediaGallery;
