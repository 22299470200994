import React, { useEffect, useState } from "react";
import Promo from "../Promo";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";

const Carousel = function ({ params, fields, sitecoreContext }) {
  const [lowCarbon, setLowCarbon] = useState(null);
  console.log(sitecoreContext);
  useEffect(() => {
    setLowCarbon(window.localStorage.getItem("low-carbon-mode") === "true");
  });
  return (
    <>
      {params?.Variant === "Promo" && (
        <Promo
          fields={fields}
          params={params}
          isRTL={sitecoreContext?.language === "ar"}
          isLowCarbon={lowCarbon}
        />
      )}
    </>
  );
};
export default withSitecoreContext()(Carousel);
