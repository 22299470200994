import React, { useState, useEffect } from "react";
import { RichText } from "@sitecore-jss/sitecore-jss-react";
import Close from "../../../../assets/icons/close.svg";

const NotificationBar = ({ fields, params }) => {
  const [show, set_show] = useState(true);

  useEffect(()=>{
    if(!localStorage.getItem('notification') ){
      set_show(true);
    } else if(localStorage.getItem('notification')  === '0'){
      set_show(false)
    }
  }, [])
  
  return (
    show && (
      <div className="fixed bottom-7 z-[60] w-full">
        <div className="container">
          <div
            className="p-6 w-full max-w-[1280px] mx-auto flex justify-between"
            style={{ background: `${params?.gradient}` }}
          >
            <div className="w-fit">
              <RichText
                field={fields?.Description}
                className="text-white text-base"
              />
            </div>
            <div className="w-fit"> 
              <img
                src={Close}
                onClick={() =>{
                  set_show(false);
                  localStorage.setItem('notification', '0');
                }}
                className="cursor-pointer md:w-10 md:h-10 w-6 h-6"
              />
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default NotificationBar;
