import React from "react";
import LoadingPlaceHolder from "../../../Common/loadingPlaceholder";

const LoadingCard = () => {
  return (
    <div className="w-full h-[258px]">
        <LoadingPlaceHolder
        extraStyles={{ height: "100%", borderRadius: 10 }}
        />
    </div>
  );
};

export default LoadingCard;
