import React, { useEffect, useState } from "react";
import FilterBox from "./filterBox";

const FilterPad = ({
  theme,
  // eventAccess,
  partner,
  // ZONE ------
  zone,
  type,
  venue,
  selectedFilters,
  set_selectedFilters,
  resetFilter,
  set_resetFilter,
}) => {
  const [text, set_text] = useState("");
  const [textFilter, set_textFilter] = useState("");
  const [selectedTheme, set_selectedTheme] = useState([]);
  // const [selectedEventAccess, set_selectedEventAccess] = useState([])
  // ZONE ------
  const [selectedZone, set_selectedZone] = useState([]);
  const [selectedPartner, set_selectedPartner] = useState([]);
  const [selectedType, set_selectedType] = useState([]);
  const [selectedVenue, set_selectedVenue] = useState([]);
  const [isFirstLoad, set_isFirstLoad] = useState(true);

  useEffect(() => {
    if (
      !resetFilter &&
      (selectedTheme || selectedEventAccess || selectedPartner || textFilter)
    ) {
      set_selectedFilters({
        theme: selectedTheme,
        // eventAccess: selectedEventAccess,
        partner: selectedPartner,
        // ZONE ------
        zone: selectedZone,
        venue: selectedVenue,
        type: selectedType,
        textFilter: textFilter,
      });
    }
  }, [
    selectedTheme,
    // selectedEventAccess,
    selectedPartner,
    // ZONE ------
    selectedZone,
    selectedVenue,
    selectedType,
    textFilter,
  ]);

  useEffect(() => {
    if (resetFilter) {
      set_text("");
      set_textFilter("");
      set_selectedTheme([]);
      // set_selectedEventAccess([])
      set_selectedPartner([]);
      set_selectedZone([]);
      set_selectedType([]);
      set_selectedVenue([]);
      set_resetFilter(false);
    }
  }, [resetFilter]);

  useEffect(() => {
    if (selectedFilters && isFirstLoad) {
      set_isFirstLoad(false);
      if (selectedFilters?.textFilter !== text) {
        set_text(selectedFilters?.textFilter || "");
      }
      if (selectedFilters?.textFilter !== textFilter) {
        set_textFilter(selectedFilters?.textFilter || "");
      }
      if (
        JSON.stringify(selectedFilters?.theme) !== JSON.stringify(selectedTheme)
      ) {
        set_selectedTheme(selectedFilters?.theme || []);
      }
      // if (JSON.stringify(selectedFilters?.eventAccess) !== JSON.stringify(selectedEventAccess)) {
      //     set_selectedEventAccess(selectedFilters?.eventAccess || [])
      // }
      // ZONE ------
      if (
        JSON.stringify(selectedFilters?.zone) !== JSON.stringify(selectedZone)
      ) {
        set_selectedZone(selectedFilters?.zone || []);
      }
      if (
        JSON.stringify(selectedFilters?.partner) !==
        JSON.stringify(selectedPartner)
      ) {
        set_selectedPartner(selectedFilters?.partner || []);
      }
    }
  }, [selectedFilters]);

  return (
    <div className="w-full">
      <div className="w-full relative rounded-[2px] border border-blueDark-500 px-3 mb-6">
        <input
          value={text}
          onChange={(e) => {
            let temp = e.target.value.replace(/[^a-zA-Z0-9\s]/g, "");
            if (temp.trim() !== "") {
              set_text(temp);
            } else {
              set_text("");
            }
            if (e.target.value === "") {
              set_textFilter(e.target.value);
            }
          }}
          onKeyUp={(e) => {
            if (e.key === "Enter") set_textFilter(e.target.value);
          }}
          placeholder="Search Our Program"
          className="w-full pe-[26px] outline-none h-[45px] text-[12px] leading-[18px] border-blueDark-500"
        />
        <button
          className="absolute top-[10px] end-3"
          onClick={() => set_textFilter(text)}
        >
          <svg
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.5734 18.059C14.7135 18.059 18.0697 14.7028 18.0697 10.5627C18.0697 6.42261 14.7135 3.06641 10.5734 3.06641C6.43335 3.06641 3.07715 6.42261 3.07715 10.5627C3.07715 14.7028 6.43335 18.059 10.5734 18.059Z"
              stroke="#173330"
              strokeWidth="1.40556"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.9462 19.9335L15.8701 15.8574"
              stroke="#173330"
              strokeWidth="1.40556"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
      <FilterBox
        title={theme?.title}
        items={theme?.items ? theme?.items : []}
        selectedItems={selectedTheme}
        set_selectedItems={set_selectedTheme}
      />
      {/* <FilterBox 
                title={eventAccess?.title} 
                items={eventAccess?.items ? eventAccess?.items : []} 
                selectedItems={selectedEventAccess}
                set_selectedItems={set_selectedEventAccess}
            /> */}

      {/* 
            // ZONE ------
             */}
      <FilterBox
        title={zone?.title}
        items={zone?.items ? zone?.items : []}
        selectedItems={selectedZone}
        set_selectedItems={set_selectedZone}
      />
      <FilterBox
        title={venue?.title}
        items={venue?.items ? venue?.items : []}
        selectedItems={selectedVenue}
        set_selectedItems={set_selectedVenue}
      />
      <FilterBox
        title={type?.title}
        items={type?.items ? type?.items : []}
        selectedItems={selectedType}
        set_selectedItems={set_selectedType}
      />
      <FilterBox
        title={partner?.title}
        items={partner?.items ? partner?.items : []}
        selectedItems={selectedPartner}
        set_selectedItems={set_selectedPartner}
      />
    </div>
  );
};

export default FilterPad;
