import React, { useState } from "react";
import { RichText } from "@sitecore-jss/sitecore-jss-react";
import { loader as gqlLoader } from "graphql.macro";
import GraphQLData from "../../../../lib/GraphQLData";
import arrowTopWhite from "../../../../assets/icons/arrow-top.svg";
import arrowTopDark from "../../../../assets/icons/arrow-top-dark.svg";
import CTA from "../../../Common/CTA";
import TranslatedWord from "../../../Common/DictionaryTexts";
const gqlQuery = gqlLoader("./connected-gql-events-listing.graphql");

export const getFormatedDate = (date) => {
  const newDate = new Date(date);
  const day = newDate.getDate();
  const month = newDate.toLocaleString("default", { month: "short" });
  const year = newDate.getFullYear().toString().slice(2);

  return day + " " + month + " " + year;
};

const ConnectedGQLEventsListing = (props) => {
  const GQL = GraphQLData(gqlQuery, {
    name: "gqlQuery",
    options: {
      variables: {
        recaptchaResponse:
          "***********************DURING SUBMIT CLICK***********************************",
      },
    },
  })(ConnectedGQLResults.bind(props));

  return <GQL {...props} />;
};

const ConnectedGQLResults = (props) => {
  const gqlQuery = props.gqlQuery;
  const data = gqlQuery?.GQLResult?.results?.items;
  const optionsList = props?.SortByLabelSelection;
  const formatedOptions =
    optionsList &&
    optionsList.map((item) => ({
      label: item.name,
      value: item.url,
    }));

  const [sortby, setSortby] = useState("most_recent");

  const handleChange = (e) => {
    setSortby(e.target.value);
  };

  const [per, setPer] = useState(8);

  const onHandleButton = () => {
    if (per * 8 < data?.length) {
      setPer(per + 1);
    } else {
      setPer(1);
    }
  };

  const getButtonLabel = () => (per * 8 < data?.length ? "Load More" : "Hide");

  return (
    <div className="py-10 container">
      <div className="flex md:flex-row w-full flex-col md:items-center items-start justify-between">
        <h4 className="text-brand font-[500] text-[40px] mb-4 md:mb-8">
          {props.fields?.Title?.value}
        </h4>
        <div className="md:w-[400px] w-full"></div>
      </div>
      <div className="mt-8 flex flex-wrap w-full box-border -mx-3 items-stretch h-full">
        {data &&
          data?.slice(0, per * 8).map((item, index) => (
            <div
              key={index}
              className=" px-3 basis-full md:basis-4/12 lg:basis-3/12 flex flex-col justify-between mb-4 md:mb-6 "
            >
              <div>
                <div className="bg-cover bg-center bg-no-repeat justify-between ">
                  {item?.item?.StartDate?.rendered && (
                    <div className="px-2 py-1 text-brand w-fit bg-brand/10 rounded-full text-base">
                      {getFormatedDate(item?.item?.StartDate?.rendered)}
                    </div>
                  )}
                </div>
                <h4 className="text-brand font-[500] text-base my-4">
                  {item?.item?.Title?.value}
                </h4>
                <p className="text-bluedark text-base mt-1">
                  {item?.item?.Description?.value}
                </p>
              </div>
              <CTA
                color="brand"
                className="flex   justify-center items-center mt-6"
              >
                <a href={item?.item?.url}>Find Out More</a>
              </CTA>
            </div>
          ))}
      </div>

      {data?.length > per && (
        <button
          onClick={onHandleButton}
          className="flex group mx-auto mt-8 items-center gap-2.5 px-[30px] py-4 bg-brand group hover:bg-lime-green-900"
        >
          <img
            src={arrowTopWhite}
            alt="arrow-top-white"
            className={`${
              per * 8 < data?.length ? "rotate-180" : "rotate-0"
            } group-hover:hidden block`}
          />
          <img
            src={arrowTopDark}
            alt="arrow-top-dark"
            className={`${
              per * 8 < data?.length ? "rotate-180" : "rotate-0"
            } group-hover:block hidden`}
          />
          <div className="text-white group-hover:text-brand font-bold text-base">
            <TranslatedWord EnWord={getButtonLabel()} />
          </div>
        </button>
      )}
    </div>
  );
};

// compose() (from @apollo/client/react/hoc) can be used when you need more than one GraphQL query
// for a single query, e.g. `compose(GraphQLData(q1), GraphQLData(q2))(component)`
export default ConnectedGQLEventsListing;
