import React, { useCallback, useEffect, useRef, useState } from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { Link as RouterLink } from "react-router-dom";
import { loader as gqlLoader } from "graphql.macro";
import GraphQLData from "../../../../lib/GraphQLData";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pagination from "../../../Common/Pagination";
import parse from "html-react-parser";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import TranslatedWord from "../../../Common/DictionaryTexts";
const gqlQuery = gqlLoader("./get-search-results.graphql");
const getKeyValuePair = function (str) {
  // Input string with key-value pairs  "key1=value1,key2=value2,key3=value3";
  const inputString = str;
  // Split the input string into an array of key-value pairs
  const keyValuePairs = inputString.split(",");
  // Initialize an empty object to store the key-value pairs
  const arrayFields = [];
  // Iterate through the key-value pairs and populate the object
  for (const pair of keyValuePairs) {
    const [key, value] = pair.split("=");
    const jsonObject = { name: key, value: decodeURIComponent(value) };
    arrayFields.push(jsonObject);
  }

  console.log(arrayFields);
  return arrayFields;
};

const SearchResults = (props) => {
  console.log("props", props);
  console.log("isRtl", props.isRtl);
  const [captcha, setCpatcha] = useState();
  const [q, setQ] = useState("");

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    setQ(urlParams.get("q") || urlParams.get("query") || "");
  });
  //  handle Google Captcha validation
  const handleVerify = useCallback((res) => {
    console.log("captcha is ", res);
    setCpatcha(res);
  }, []);
  const GQL = GraphQLData(gqlQuery, {
    name: "gqlQuery",
    options: {
      variables: {
        first: parseInt(props.params.first) || 10,
        after: props.params.after.toString() || "0",
        keyword: q,
        fieldsEqual: getKeyValuePair(props.params.fieldsEqual) || [
          {
            name: "_template",
            value:
              "in:87b026c6417b550091fb05761a1e474a,761a7e049879421699ef0be071b0f62a,99b4aff5-9276433890627751819c6f9f,acce2d897a954fcdb3c086b39680f902,0570c429d3694791bee15bf215329d96,0603f16635b8469f8123e8d87bedc171,7bb0411f50cd4c21ad8f1fcde7c3affe",
          },
          { name: "_name", value: "notin:404,__Standard Values" },
        ],
        recaptchaResponse: captcha || "not ready",
      },
    },
  })(ConnectedGQLResults.bind(props));

  return (
    <div className="bg-brand min-h-screen">
      {!captcha && (
        <div className="container">
          <h3 className="text-white pt-48">Loading ...</h3>
        </div>
      )}
      <GoogleReCaptchaProvider
        reCaptchaKey={props.sitecoreContext?.siteSettings?.SiteKey?.value}
      >
        <GoogleReCaptcha onVerify={handleVerify} />
        {captcha && <GQL {...props} query={q} captcha={captcha} />}
      </GoogleReCaptchaProvider>
    </div>
  );
};

const ConnectedGQLResults = (props) => {
  console.log("props", props);
  const gqlQuery = props.gqlQuery;
  const totalCount = gqlQuery.GQLResult?.results?.totalCount;
  const itemsPerPage = parseInt(props.params.first);
  const totalPages = Math.ceil(totalCount / itemsPerPage);
  const [catpcha, setCpatcha] = useState();
  const ref = useRef(null);
  const handleVerify = useCallback((res) => {
    setCpatcha(res);
  }, []);
  const [skip, setSkip] = useState(props.params.after);
  const [query, setQuery] = useState(props.query);
  const [activePage, setActivePage] = useState(1);

  // Async loading and error handling
  // Remember to never return null from a JSS component when loading,
  // this will break Experience Editor.
  const { error, loading, refetch } = props.gqlQuery;

  const { datasource, contextItem } = props.gqlQuery;
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const getUpdatedGQLVariables = (skip) => {
    setRefreshReCaptcha(!refreshReCaptcha);

    ref.current.scrollIntoView({ behavior: "smooth", block: "start" });

    console.log("itemsPerPage", itemsPerPage);

    let fieldEqualsComputed = [
      { name: "_template", value: "289f1fc237e54753b26103963c1e436a" },
    ];
    console.log("captcah is :", catpcha);

    return {
      first: itemsPerPage,
      after: skip || "0",
      keyword: query,
      fieldsEqual: getKeyValuePair(props.params.fieldsEqual) || [
        {
          name: "_template",
          value:
            "in:87b026c6417b550091fb05761a1e474a,761a7e049879421699ef0be071b0f62a,99b4aff5-9276433890627751819c6f9f,acce2d897a954fcdb3c086b39680f902,0570c429d3694791bee15bf215329d96,0603f16635b8469f8123e8d87bedc171,7bb0411f50cd4c21ad8f1fcde7c3affe",
        },
        { name: "_name", value: "notin:404,__Standard Values" },
      ],
      recaptchaResponse: catpcha,
    };
  };

  const handlePagination = function (event) {
    setActivePage(event.selected + 1);
    var countSkip = itemsPerPage * (event.selected + 1);
    let _skip = (countSkip - itemsPerPage).toString();
    setSkip(_skip);
    refetch(getUpdatedGQLVariables(_skip));
  };

  const data = gqlQuery?.GQLResult?.results?.items;
  console.log("data", data);
  return (
    <section className="pt-40 pb-10 bg-brand">
      <GoogleReCaptcha
        onVerify={handleVerify}
        refreshReCaptcha={refreshReCaptcha}
      ></GoogleReCaptcha>
      <div className="container">
        <div>
          <h1 className="text-white py-2 h3">
            <span className="text-lime-green-900">
              <TranslatedWord EnWord="SEARCH" />
            </span>
            <TranslatedWord EnWord="RESULTS" />
          </h1>
          <div className="relative">
            <input
              type="text"
              placeholder="What can we help you with?"
              className="h-[64px] w-full p-[16px] focus:outline-none mt-[32px]"
              value={query}
              onChange={(e) => {
                let temp = e.target.value.replace(/[^a-zA-Z0-9\s]/g, "");
                if (temp.trim() !== "") {
                  setQuery(temp);
                } else {
                  setQuery("");
                }
              }}
              onKeyUp={(e) => {
                if (e.key === "Enter") refetch(getUpdatedGQLVariables());
              }}
            />
            <div className="absolute  end-6 top-[54px]  w-fit ">
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                style={{ color: "#163331", width: "20px", height: "20px" }}
                className="cursor-pointer"
                onClick={() => refetch(getUpdatedGQLVariables())}
              />
            </div>
          </div>
        </div>
        {!query.loading && (
          <div ref={ref}>
            {totalCount > 0 ? (
              <p className="text-white pt-14">
                {totalCount}
                <TranslatedWord EnWord="RESULTS FOUND" />
              </p>
            ) : null}
            <div>
              {data &&
                data.length > 0 &&
                data.map((item, i) => {
                  return (
                    <div
                      key={i}
                      className="text-white pt-8 pb-4 border-b-2 border-white"
                    >
                      <a href={item.item.url}>
                        <p className="capitalize font-medium mb-4">
                          {item.item.Title?.value ||
                            item.item.name.replaceAll("-", " ")}
                        </p>
                        <p>{item.item.Description?.value}</p>
                      </a>
                    </div>
                  );
                })}
            </div>
            {data && data.length === 0 && (
              <>
                <p className="text-white text-center my-12 md:my-24">
                  {parse(props.fields?.SearchResultsEmptyMessage?.value)}
                </p>
              </>
            )}
            <div className="w-full py-20">
              {totalCount > itemsPerPage && (
                <Pagination
                  isRTL={props?.isRtl === "true" ? true : false}
                  totalPage={totalPages}
                  onClick={handlePagination}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

// compose() (from @apollo/client/react/hoc) can be used when you need more than one GraphQL query
// for a single query, e.g. `compose(GraphQLData(q1), GraphQLData(q2))(component)`
export default withSitecoreContext()(SearchResults);
