import { createSlice } from "@reduxjs/toolkit";

export const ModeSlice = createSlice({
  name: "lowmode",
  initialState: {
    lowmode: false,
  },
  reducers: {
    setLowMode: (state, action) => {
      state.lowmode = action.payload.lowmode;
    },
  }
});

export const { setLowMode } = ModeSlice.actions;

export const getLowMode = state => state.lowmode.lowmode;

export default ModeSlice.reducer;
