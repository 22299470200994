import React from 'react';
import { Image, Link } from '@sitecore-jss/sitecore-jss-react';
import EmptyState from '../../../Common/EmptyState';

const MainMenu = ({ fields }) => {
  console.log('---------------- Main Menu ---------------------\n', fields);
  return (
    fields ?
    <div className="w-full h-[100px] bg-red-500">
    </div> :
    <EmptyState title="Navigation / MainMenu"/>
  )
}

export default MainMenu;
