import React from "react";
import { RichText } from "@sitecore-jss/sitecore-jss-react";

const MulticardListDefaultVariant = ({ fields }) => {
  return (
    <section className="container">
      <div className="py-14">
        <h3 className="text-blueDark-500 text-[56px] font-medium leading-[57.12px] uppercase">
          {fields?.Title?.value}
        </h3>
        <div className="flex flex-wrap gap-y-8 mt-10 box-border justify-start">
          {fields?.SelectedPrimaryCards.map((item, index) => (
            <div key={index} className="basis-full md:basis-6/12 lg:basis-4/12">
              <div className="md:pe-6">
                <RichText
                  field={item?.fields?.TimeSlot}
                  className="text-blueDark-600 text-base font-medium px-2 py-1 bg-wadi rounded-[80px] w-fit"
                />
                <h3 className="text-blueDark-100 text-[32px] leading-[32.64px] font-medium uppercase mt-4">
                  {item?.fields?.Title?.value}
                </h3>
                <RichText
                  field={item?.fields?.Description}
                  className="text-bluedark text-base font-normal mt-4"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default MulticardListDefaultVariant;
