import React, { useEffect, useRef, useState } from "react";
import { Image, RichText } from "@sitecore-jss/sitecore-jss-react";
// eslint-disable-next-line import/no-extraneous-dependencies
import Slider from "react-slick";
// eslint-disable-next-line import/no-extraneous-dependencies
import "slick-carousel/slick/slick.css";
// eslint-disable-next-line import/no-extraneous-dependencies
import "slick-carousel/slick/slick-theme.css";
import "./stlye.css";
import CTA from "../../../Common/CTA";
import VideoModal from "../../../Common/VideoModal";
import TranslatedWord from "../../../Common/DictionaryTexts";

const CarouselBannerV2 = ({ fields, params, isRtl }) => {

  const sliderRef = useRef(null);
  const [isModelOn, setIsModuleOn] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [video, setVideo] = useState({});
  const [lowCarbon, setLowCarbon] = useState(null);

  useEffect(() => {
    setLowCarbon(window.localStorage.getItem("low-carbon-mode") === "true");
    const resizeHandler = () => {
      setIsMobile(window.innerWidth <= 768)
    }
    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    return () => window.removeEventListener('resize', resizeHandler)
  }, []);

  const settings = {
    appendDots: (dots) => {
      return (
        <div dir={isRtl === "true" ? "rtl" : "ltr"}>
          <ul className="absolute end-10 md:end-20 bottom-14 rtl:flex rtl:flex-row-reverse rtl:ms-0">
            {dots}
          </ul>
        </div>
      );
    },
    customPaging: function (i) {
      return (
        <span className={`h-px bg-white w-8 md:w-16 inline-block me-2 relative ${lowCarbon ? "hidden" : ""}`}>
          <span
            className="h-0.5 absolute bottom-0 start-0 bg-white w-0"
            style={{ animationDuration: `${params.speed}ms` }}
          />
        </span>
      );
    },
    dots: true,
    infinite: true,
    autoplay: true,
    fade: false,
    adaptiveHeight: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
    autoplaySpeed: parseInt(params.speed) || 7000,
    rtl: isRtl === "true" ? true : false,
  };

  return (
    <>
      <section className={`color-block relative carouselBannerV2 min-h-screen`}>
        {isModelOn === "en" && (
          <VideoModal
            video={video?.fields?.Video}
            poster={
              isMobile && video?.fields?.BackgroundMobileImage?.value?.src
                ? video?.fields?.BackgroundMobileImage
                : video?.fields?.BackgroundImage
            }
            onClose={() => setIsModuleOn("")}
            isMobile={isMobile}
            isIframe={fields?.IsIframe?.value}
          />
        )}
        {isModelOn === "ar" && (
          <VideoModal
            video={video?.fields?.VideoAR}
            poster={
              isMobile && video?.fields?.BackgroundMobileImage?.value?.src
                ? video?.fields?.BackgroundMobileImage
                : video?.fields?.BackgroundImage
            }
            onClose={() => setIsModuleOn("")}
            isMobile={isMobile}
            isIframe={fields?.IsIframe?.value}
          />
        )}

        <Slider {...settings} ref={sliderRef} className="overflow-hidden h-full">
          {fields.CarouselBanners?.map((slide, index) => (
            <div
              key={index}
              className={`relative h-full`}
              dir={isRtl === "true" ? "rtl" : "ltr"}
            >
              <div className={`w-full h-full flex pb-[110px] md:pb-16 ${slide.fields?.VerticalAlignment?.value !== 'top' ? 'items-end' : 'items-start'}`}>
                {!lowCarbon && lowCarbon !== null && (
                  <div
                    style={{
                      background: `url(${isMobile &&
                        slide?.fields?.BackgroundMobileImage?.value?.src
                        ? slide?.fields?.BackgroundMobileImage?.value?.src
                        : slide?.fields?.BackgroundImage?.value?.src
                        })  center\/ cover`,
                    }}
                    className="bg-cover absolute top-0 start-0 w-full  h-full "
                  />
                )}
                <div className="absolute top-0 start-0 w-full h-full dark:bg-green-gradient hidden dark:block z-0" />
                {
                  !lowCarbon && lowCarbon !== null && (
                    !slide.fields?.isGreenGradient?.value ?
                      <div className="absolute top-0 start-0 w-full h-full" style={{ background: slide.fields?.VerticalAlignment?.value !== 'top' ? 'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.23) 33.22%, rgba(0, 0, 0, 0.70) 100%)' : 'linear-gradient(0deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.23) 33.22%, rgba(0, 0, 0, 0.70) 100%)' }} /> :
                      <div className="absolute top-0 start-0 w-full h-full" style={{ background: slide.fields?.VerticalAlignment?.value !== 'top' ? 'linear-gradient(180deg, rgba(20, 90, 80, 0.00) 36.36%, rgba(20, 90, 80, 0.90) 61.5%, #145A50 100%)' : 'linear-gradient(0deg, rgba(20, 90, 80, 0.00) 36.36%, rgba(20, 90, 80, 0.90) 61.5%, #145A50 100%)' }} />
                  )
                }
                <div className={`relative z-10 container  ${slide.fields?.VerticalAlignment?.value !== 'top' ? 'mt-[350px] md:mt-[400px]' : 'mt-[120px] md:mt-[170px]'} flex ${slide.fields?.HorizontalAlignment?.value === "left" ? "justify-start text-start" : "justify-center text-center"}`}>
                  <div className={`w-full max-w-[737px] flex flex-col gap-6 ${slide.fields?.HorizontalAlignment?.value === "left" ? "items-start" : "items-center"}`}>
                    <RichText tag="h4" field={slide.fields?.Title} className={`text-[40px] leading-[102%] font-medium text-white h-fit`} />
                    <RichText tag="div" field={slide.fields?.Description} className={`text-[16px] leading-[18px] font-normal text-white`} />
                    <Image field={slide.fields?.Image} className="w-[192px] h-auto" />
                    {
                      slide.fields?.CTAButtonList?.length > 0 &&
                      <div className={`flex gap-4 flex-wrap w-full ${slide.fields?.HorizontalAlignment?.value === "left" ? "justify-start" : "justify-center"}`}>
                        {slide.fields?.CTAButtonList?.map((cta, index) => (
                          <a key={index} href={cta.fields?.CTALink?.value?.href}>
                            <CTA
                              className={`${index === 1 && "bg-white"}`}
                              external={cta.fields?.CTALink?.value?.linktype === "external"}
                            >
                              {cta.fields?.CTALink?.value?.text}
                            </CTA>
                          </a>
                        ))}
                      </div>
                    }
                    {
                      slide.fields?.Video?.value?.href &&
                      <div className={`flex w-full ${slide.fields?.HorizontalAlignment?.value === "left" ? "justify-start" : "justify-center"}`}>
                        {slide.fields?.Video?.value?.href && (
                          <CTA
                            onClick={() => {
                              setIsModuleOn("en");
                              setVideo(slide);
                            }}
                          >
                            <TranslatedWord EnWord={slide.fields?.VideoAR?.value?.href ? "Play Video (English)" : "Play Video"} />
                          </CTA>
                        )}
                      </div>
                    }
                    {
                      slide.fields?.VideoAR?.value?.href &&
                      <div className={`flex w-full ${slide.fields?.HorizontalAlignment?.value === "left" ? "justify-start" : "justify-center"}`}>
                        {slide.fields?.VideoAR?.value?.href && (
                          <CTA
                            onClick={() => {
                              setIsModuleOn("ar");
                              setVideo(slide);
                            }}
                          >
                            <TranslatedWord EnWord="Play Video (Arabic)" />
                          </CTA>
                        )}
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </section>
    </>
  );
};

export default CarouselBannerV2;
