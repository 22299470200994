import React from "react";
import { Image, RichText } from "@sitecore-jss/sitecore-jss-react";
import { Helmet } from "react-helmet";

const StackedTextAndImage = ({ fields }) => {
  return (
    <section className="relative overflow-hidden">
      <Helmet>
        <meta name="image" content={fields?.BackgroundImage?.value?.src} />
        <meta name="og:image" content={fields?.BackgroundImage?.value?.src} />
      </Helmet>
      <div className="pt-8 min-h-[400px] bg-cover bg-brand md:px-10 px-4">
        <div className="absolute top-0 start-0 w-full h-full"></div>
        <div className="md:mx-7 pb-10">
          <div className="flex gap-10 md:flex-row flex-col md:pt-[200px] pt-[100px]">
            <div className="w-full md:w-1/2">
              <div className="uppercase md:w-5/6 w-full text-white font-[500]">
                <RichText field={fields?.Title} />
              </div>
              <div className="text-white font-semibold md:w-5/6 w-full md:mt-0 mt-5">
                <RichText field={fields?.Subtitle} />
              </div>
            </div>
            <div className="w-full md:w-1/2 pb-5">
              <div className="text-white text-lg md:text-xl">
                <RichText field={fields?.Description} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Image
        field={fields?.BackgroundImage}
        className="object-cover h-[542px] w-full"
      />
    </section>
  );
};

export default StackedTextAndImage;
