import React from "react";
import { Parallax } from "react-scroll-parallax";
import { Text, Image, RichText } from "@sitecore-jss/sitecore-jss-react";
import { Helmet } from "react-helmet";

const TwoColumn = ({ fields, params }) => {
  const bgColor = params?.BackgroundColor;

  return (
    <section className="relative overflow">
      <div className={`pb-5 bg-cover bg-${bgColor} `}>
        <div className="absolute top-0 start-0 w-full h-full"></div>
        <div className="container pb-10 relative z-10">
          <div className="flex  md:flex-row flex-col -mx-3 box-border md:pt-[200px] pt-[100px]">
            <div className="basis-full lg:basis-7/12 px-3 mb-6 lg:mb-0">
              <h1 className="uppercase  text-white font-[500] ">
                <RichText field={fields?.Title} />
              </h1>
            </div>
            <div className="basis-full lg:basis-5/12 px-3">
              <div className="text-white flex text-lg md:text-xl">
                <RichText field={fields?.Description} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute z-0 end-0 bottom-[-95px] hidden md:block">
        <Image field={fields?.Image} />
      </div>
      <Helmet>
        <meta name="image" content={fields?.Image?.value?.src} />
        <meta name="og:image" content={fields?.Image?.value?.src} />
      </Helmet>
    </section>
  );
};

export default TwoColumn;
