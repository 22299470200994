import React from "react";
import LoadingPlaceHolder from "../../../Common/loadingPlaceholder";

const LoadingCard = ({ speakers, partners }) => {
  return (
    <div className="w-full mt-6 text-blueDark-500">
      <div className="px-4 lg:px-8">
        <div className="w-full mt-6 p-6 pt-4 rounded-[2px] bg-white text-[16px] leading-[18px] font-[500]">
          <div className="flex space-x-2 rtl:space-x-reverse me-2 mt-2">
            <div className="w-[73px] h-[34px]">
              <LoadingPlaceHolder
                extraStyles={{ height: "100%", borderRadius: 60 }}
              />
            </div>
            <div className="w-[61px] h-[34px]">
              <LoadingPlaceHolder
                extraStyles={{ height: "100%", borderRadius: 60 }}
              />
            </div>
          </div>
          <div className="w-[230px] h-[34px] mt-2">
            <LoadingPlaceHolder
              extraStyles={{ height: "100%", borderRadius: 60 }}
            />
          </div>
          <div className="w-[50%] h-[24px] mt-6">
            <LoadingPlaceHolder
              extraStyles={{ height: "100%", borderRadius: 20 }}
            />
          </div>
          <div className="w-[50%] h-[88px] mt-6">
            <LoadingPlaceHolder
              extraStyles={{ height: "100%", borderRadius: 20 }}
            />
          </div>
          {speakers ? (
            <div className="w-full text-[16px] leading-[18px] mt-6">
              <div className="w-[108px] h-[18px]">
                <LoadingPlaceHolder
                  extraStyles={{ height: "100%", borderRadius: 20 }}
                />
              </div>
              <div className="w-full mt-6 flex space-x-3">
                <div className="w-[30%] h-[60px]">
                  <LoadingPlaceHolder
                    extraStyles={{ height: "100%", borderRadius: 20 }}
                  />
                </div>
                <div className="w-[30%] h-[60px]">
                  <LoadingPlaceHolder
                    extraStyles={{ height: "100%", borderRadius: 20 }}
                  />
                </div>
              </div>
            </div>
          ) : null}
          {partners ? (
            <div className="w-full text-[16px] leading-[18px] mt-6">
              <div className="w-[108px] h-[18px]">
                <LoadingPlaceHolder
                  extraStyles={{ height: "100%", borderRadius: 20 }}
                />
              </div>
              <div className="w-full mt-6 flex space-x-3">
                <div className="w-[30%] h-[60px]">
                  <LoadingPlaceHolder
                    extraStyles={{ height: "100%", borderRadius: 20 }}
                  />
                </div>
                <div className="w-[30%] h-[60px]">
                  <LoadingPlaceHolder
                    extraStyles={{ height: "100%", borderRadius: 20 }}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default LoadingCard;
