import React from "react";
import { RichText, Image, Link } from "@sitecore-jss/sitecore-jss-react";
import CTA from "../../../Common/CTA";

const FullWidth = ({ lowCarbon, fields, params }) => {
  return (
    <>
      <section className="relative">
        <div className=" py-24  flex items-center min-h-[490px] relative">
          {!lowCarbon && lowCarbon !== null && (
            <div
              className="  bg-cover bg-center flex items-center md:bg-fixed h-full absolute top-0 start-0 w-full "
              style={{
                backgroundImage: `url(${fields?.BackgroundImage?.value?.src})`,
              }}
            />
          )}
          <div className="absolute top-0 start-0 w-full dark:w-full h-full bg-gradient-to-r hidden dark:block low-carbon-yellow-gradient" />

          <div className="container">
            {(fields?.Title?.value || fields?.Description?.value) && (
              <>
                <div
                  className="absolute top-0 start-0 w-full md:block hidden dark:w-full h-full dark:hidden"
                  style={{ background: `${params?.gradient}` }}
                />
                <div
                  className="absolute top-0 start-0 w-full md:hidden block dark:w-full h-full"
                  style={{ background: `${params?.MobileGradient}` }}
                />
              </>
            )}
            <div className="relative w-full">
              <div className="md:flex md:flex-row">
                <div className="basis-full md:basis-5/12">
                  <h3
                    className={`mb-6 ${
                      params?.TextColor === "light"
                        ? "text-white"
                        : "text-bluedark"
                    } font-[500]`}
                  >
                    <RichText field={fields?.Title} />
                  </h3>
                  <div
                    className={`mb-8 ${
                      params?.TextColor === "light"
                        ? "text-white"
                        : "text-bluedark"
                    } text-lg md:text-xl dark:text-bluedark`}
                  >
                    <RichText field={fields?.Description} />
                  </div>
                  {fields?.CTAbutton?.value?.href && (
                    <Link field={fields?.CTAbutton}>
                      <CTA className="flex w-fit items-center">
                        {fields?.CTAbutton?.value?.text}
                      </CTA>
                    </Link>
                  )}
                </div>
                <div className="basis-full md:basis-4/12 ms-auto me-auto mt-10">
                  <Image field={fields?.Image} className="w-full relative" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FullWidth;
