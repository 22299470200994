import React, { useEffect, useRef, useState } from "react";
import UseScrollBlock from "./UseScrollBlock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import { Image, Link } from "@sitecore-jss/sitecore-jss-react";
import ReactPlayer from "react-player";
import parse from "html-react-parser";
import CTA from "./CTA";
import { Autoplay, Mousewheel, Pagination } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

const Takeover = function ({ siteSettings, onClose }) {
  console.log("====================");
  console.log(siteSettings);
  const swiperRef = useRef();
  const paginationRef = useRef();
  const bottomNavRef = useRef();
  const [swiper, setSwiper] = useState();
  const [blockScroll, unlockScroll] = UseScrollBlock();
  const [currentIndex, setCurrentIndex] = useState(1);
  useEffect(() => {
    blockScroll();
  });
  const pagination = {
    clickable: true,
    el: ".takeover .pagination",
  };
  console.log(swiper);
  function onSlideChange(swiper, index) {
    setCurrentIndex(swiper.realIndex + 1);
  }
  return (
    <>
      <div className="takeover overflow-y-auto min-h-screen w-screen z-[60] h-full fixed top-0 left-0 bg-brand">
        <div
          className="py-10 absolute w-full h-auto top-0 left-0 z-[90]"
          style={{
            background:
              "linear-gradient(180deg, #173330 0%, rgba(23, 51, 48, 0.00) 100%)",
          }}
        >
          <div className="container">
            <div className="flex justify-between">
              <div className="flex items-center gap-6">
                <Link field={siteSettings?.SecondaryLogoCTALink}>
                  <Image
                    field={siteSettings?.SecondaryLogo}
                    className="h-10 md:h-12 lg:h-14"
                  />
                </Link>
                <Link field={siteSettings?.PrimaryLogoCTALink}>
                  <Image
                    field={siteSettings?.PrimaryLogo}
                    className="h-10 md:h-12 lg:h-14"
                  />
                </Link>
              </div>
              <span
                className="     text-white  flex justify-center items-center cursor-pointer"
                onClick={() => {
                  onClose();
                  unlockScroll();
                }}
              >
                <FontAwesomeIcon icon={faX} className="text-2xl font-bold" />
              </span>
            </div>
          </div>
        </div>
        <Swiper
          slidesPerView={1}
          onInit={(s) => setSwiper(s)}
          ref={swiperRef}
          autoplay={true}
          pagination={pagination}
          loop={true}
          speed={7000}
          modules={[Autoplay, Pagination]}
          onSlideChange={onSlideChange}
        >
          {siteSettings?.SlideList?.map((slide, index) => {
            return (
              <SwiperSlide
                key={index}
                className="overflow-auto h-full md:h-screen w-screen relative "
              >
                <div className="overflow-hidden">
                  <div className="imageIframe">
                    {slide.fields?.Video?.value?.href && (
                      <iframe
                        className="w-full h-screen object-cover"
                        src={`${slide.fields?.Video?.value?.href}?controls=0&showinfo=0&rel=0&autoplay=1&loop=1&mute=1&modestbranding=1`}
                      />
                    )}
                    {!slide.fields?.Video?.value?.href && (
                      <>
                        <Image
                          className="hidden md:block w-screen h-screen object-cover object-center z-0 relative"
                          field={slide.fields?.BackgroundImage}
                        />
                        <Image
                          className="block md:hidden w-screen h-screen object-cover object-center z-0 relative"
                          field={
                            slide.fields?.BackgroundMobileImage?.value?.src
                              ? slide.fields?.BackgroundMobileImage
                              : slide.fields?.BackgroundImage
                          }
                        />
                      </>
                    )}
                  </div>
                  <div
                    className="py-10 relative md:absolute w-full h-auto mb-[-250px] md:mb-0 bottom-[250px] md:bottom-0 left-0 z-[90]  bottomNav"
                    ref={bottomNavRef}
                  >
                    <div className="container">
                      <div className="flex flex-wrap flex-col xl:flex-row justify-between items-center  -mx-4 ">
                        <div className=" basis-full lg:basis-4/12 px-4 mb-4 xl:mb-0">
                          <p className="text-white mb-4">
                            {parse(slide.fields?.Description?.value)}
                          </p>
                          {slide?.fields?.CTA?.value?.href && (
                            <Link field={slide?.fields?.CTA}>
                              <CTA>{slide?.fields?.CTA?.value?.text}</CTA>
                            </Link>
                          )}
                        </div>
                        <div className="flex gap-6 basis-full w-full  xl:basis-8/12 xl:justify-end px-4 flex-wrap">
                          {slide?.fields?.Cards?.map((card, index) => {
                            return (
                              <Link
                                className="cursor-pointer inline-block w-full md:w-auto"
                                key={index}
                                field={card?.fields?.CTALink}
                              >
                                <div className="bg-white flex w-full md:w-[370px] p-4 gap-4 items-center">
                                  <Image
                                    field={card.fields?.Image}
                                    className="max-w-[150px]"
                                  />

                                  <div className="text-brand">
                                    {parse(card.fields?.Title?.value)}
                                  </div>
                                </div>
                              </Link>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
          {siteSettings?.SlideList?.length > 1 && (
            <div
              className="container absolute w-full mx-auto right-0 left-0  z-[50] text-right"
              ref={paginationRef}
              style={{ top: `${bottomNavRef?.current?.offsetTop}px` }}
            >
              <div className="flex justify-end items-center gap-2 -mx-2">
                <span className="text-white">0{currentIndex}</span>
                <span className="h-0.5 w-[46px] bg-white"></span>
                <div>
                  <div className="pagination" />
                </div>
              </div>
            </div>
          )}
        </Swiper>
        {/* <div className="overflow-y-auto">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. In luctus,
            ex eu sagittis faucibus, ligula ipsum sagittis magna, et imperdiet
            dolor lectus eu libero. Vestibulum venenatis eget turpis sed
            faucibus. Maecenas in ullamcorper orci, eu ullamcorper sem. Etiam
            elit ante, luctus non ante sit amet, sodales vulputate odio. Aenean
            tristique nisl tellus, sit amet fringilla nisl volutpat cursus.
            Quisque dignissim lectus ac nunc consectetur mattis. Proin vel
            hendrerit ipsum, et lobortis dolor. Vestibulum convallis, nibh et
            tincidunt tristique, nisl risus facilisis lectus, ut interdum orci
            nisl ac nunc. Cras et aliquam felis. Quisque vel ipsum at elit
            sodales posuere eget non est. Fusce convallis vestibulum dolor non
            volutpat. Vivamus vestibulum quam ut ultricies pretium.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. In luctus,
            ex eu sagittis faucibus, ligula ipsum sagittis magna, et imperdiet
            dolor lectus eu libero. Vestibulum venenatis eget turpis sed
            faucibus. Maecenas in ullamcorper orci, eu ullamcorper sem. Etiam
            elit ante, luctus non ante sit amet, sodales vulputate odio. Aenean
            tristique nisl tellus, sit amet fringilla nisl volutpat cursus.
            Quisque dignissim lectus ac nunc consectetur mattis. Proin vel
            hendrerit ipsum, et lobortis dolor. Vestibulum convallis, nibh et
            tincidunt tristique, nisl risus facilisis lectus, ut interdum orci
            nisl ac nunc. Cras et aliquam felis. Quisque vel ipsum at elit
            sodales posuere eget non est. Fusce convallis vestibulum dolor non
            volutpat. Vivamus vestibulum quam ut ultricies pretium.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. In luctus,
            ex eu sagittis faucibus, ligula ipsum sagittis magna, et imperdiet
            dolor lectus eu libero. Vestibulum venenatis eget turpis sed
            faucibus. Maecenas in ullamcorper orci, eu ullamcorper sem. Etiam
            elit ante, luctus non ante sit amet, sodales vulputate odio. Aenean
            tristique nisl tellus, sit amet fringilla nisl volutpat cursus.
            Quisque dignissim lectus ac nunc consectetur mattis. Proin vel
            hendrerit ipsum, et lobortis dolor. Vestibulum convallis, nibh et
            tincidunt tristique, nisl risus facilisis lectus, ut interdum orci
            nisl ac nunc. Cras et aliquam felis. Quisque vel ipsum at elit
            sodales posuere eget non est. Fusce convallis vestibulum dolor non
            volutpat. Vivamus vestibulum quam ut ultricies pretium.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. In luctus,
            ex eu sagittis faucibus, ligula ipsum sagittis magna, et imperdiet
            dolor lectus eu libero. Vestibulum venenatis eget turpis sed
            faucibus. Maecenas in ullamcorper orci, eu ullamcorper sem. Etiam
            elit ante, luctus non ante sit amet, sodales vulputate odio. Aenean
            tristique nisl tellus, sit amet fringilla nisl volutpat cursus.
            Quisque dignissim lectus ac nunc consectetur mattis. Proin vel
            hendrerit ipsum, et lobortis dolor. Vestibulum convallis, nibh et
            tincidunt tristique, nisl risus facilisis lectus, ut interdum orci
            nisl ac nunc. Cras et aliquam felis. Quisque vel ipsum at elit
            sodales posuere eget non est. Fusce convallis vestibulum dolor non
            volutpat. Vivamus vestibulum quam ut ultricies pretium.
          </p>
        </div> */}
      </div>
    </>
  );
};
export default Takeover;
