import React from "react";

const BreadCrumb = ({ fields }) => {
  let links = [],
    item = fields?.data?.contextItem;
  let canContinue = true;
  while (canContinue) {
    if (item?.IsProperPage?.value === "1") {
      links.unshift({
        title: item.title,
        url: item.url,
      });
      item = item.parent;
    } else {
      canContinue = false;
    }
  }

  console.log(links, "///////");

  return (
    <div className="bg-blueDark-50 py-[8px] uppercase ">
      <div className="container">
        <ul className="list-none flex gap-[8px] items-center flex-wrap ms-0 ">
          {links?.map((link, i) => {
            return (
              <li
                key={i}
                className="flex justify-center items-center gap-[8px]"
              >
                <a
                  href={link.url || ""}
                  className={`text-[16px] text-blueDark-100 font-[500]  leading-[18px] ${
                    i < links?.length - 1 ? "underline" : "pointer-events-none "
                  }`}
                >
                  {link.title.toUpperCase()}
                </a>

                {i !== links?.length - 1 && (
                  <svg
                    className="rtl:scale-[-1]"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="23"
                    viewBox="0 0 24 23"
                    fill="none"
                  >
                    <path
                      d="M8 19.5L16 11.5L8 3.5"
                      stroke="#118170"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default BreadCrumb;
