import { Image, RichText } from "@sitecore-jss/sitecore-jss-react";
import React, { useRef } from "react";
import Slider from "react-slick";

import arrowRightDark from "../../../../assets/images/arrow-left-dark.svg";
import arrowLeftDark from "../../../../assets/images/arrow-right-dark.svg";

const HighLights = ({ fields, isRtl }) => {
  const eventsRef = useRef(null);

  const eventsSetting = {
    dots: false,
    arrows: false,
    swipeToSlide: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    rtl: isRtl === "true" ? true : false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          rows: 3,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const toEventPrev = () => {
    eventsRef && eventsRef.current.slickPrev();
  };

  const toEventNext = () => {
    eventsRef && eventsRef.current.slickNext();
  };

  return (
    <div className="bg-taupe-400 relative md:py-[56px] py-[56px]">
      <div className="md:px-20 px-6">
        <div className="flex items-center justify-between">
          <RichText
            field={fields?.Title}
            className="text-brand font-[500] text-[40px]"
          />
          <div className="items-center gap-4 md:flex rtl:md:flex-row-reverse hidden">
            <img
              src={arrowLeftDark}
              onClick={toEventPrev}
              className="cursor-pointer px-6 py-3 border-2 border-bluedark rounded-full"
              alt=""
            />
            <img
              src={arrowRightDark}
              onClick={toEventNext}
              className="cursor-pointer px-6 py-3 border-2 border-bluedark rounded-full"
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="mt-6 w-full overflow-x-clip">
        <div className="md:-me-20 md:ps-20 ps-6 md:pe-0 pe-6">
          <Slider {...eventsSetting} swipeToSlide={true} ref={eventsRef}>
            {fields?.SelectMedia.map((item, index) => (
              <div
                key={index}
                className="px-4 md:py-0 py-4"
                dir={isRtl === "true" ? "rtl" : "ltr"}
              >
                <Image
                  field={item.fields?.Image}
                  alt=""
                  className="h-[320px] object-cover"
                  key={index}
                />
                <RichText
                  field={item.fields?.Description}
                  className="mt-8 text-bluedark text-base"
                />
              </div>
            ))}
          </Slider>
        </div>
        <div className="items-center justify-end gap-4 md:hidden flex px-6 mt-6">
          <img
            src={arrowLeftDark}
            onClick={toEventPrev}
            className="cursor-pointer px-6 py-3 border-2 border-bluedark rounded-full"
            alt=""
          />
          <img
            src={arrowRightDark}
            onClick={toEventNext}
            className="cursor-pointer px-6 py-3 border-2 border-bluedark rounded-full"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default HighLights;
