import React from "react";
import { Image, Text } from "@sitecore-jss/sitecore-jss-react";
import parse from "html-react-parser";
const Testimonial = ({ fields, params }) => (
  <>
    {(params?.Variant === "Default" || !params?.Variant) && (
      <>
        <div className="container">
          <div className="flex justify-center w-full">
            <div className="basis-full md:basis-9/12 lg:basis-8/12 ">
              <div className="py-4 md:py-8  text-center">
                <Image
                  className="w-16 inline-block"
                  field={fields?.Cards[0]?.fields?.ProfileImage}
                />
                <h5 className="text-brand my-4">
                  {parse(fields?.Cards[0]?.fields?.Description?.value)}
                </h5>
                <p className="font-bold">
                  {parse(fields?.Cards[0]?.fields?.Title?.value)}
                </p>
                <p>{parse(fields?.Cards[0]?.fields?.Subtitle?.value)}</p>
              </div>
            </div>
          </div>
        </div>
      </>
    )}
  </>
);

export default Testimonial;
