import React from "react";
import { RichText } from "@sitecore-jss/sitecore-jss-react";

const SingleColumn = ({ fields, params }) => {
  return (
    <section
      className={`py-20  bg-${params?.BackgroundColor}`}
      style={{ background: params?.gradient }}
    >
      <div className="container">
        <h3
          className={`${
            params?.TextColor
              ? params?.TextColor === "light"
                ? "text-white"
                : "text-blueDark-500"
              : "text-white"
          }`}
        >
          <RichText field={fields?.Title} />
        </h3>
        <div
          className={`${
            params?.TextColor
              ? params?.TextColor === "light"
                ? "text-white"
                : "text-blueDark-500"
              : "text-white"
          } text-lg md:text-xl ms-auto me-auto mt-5`}
        >
          <RichText field={fields?.Description} />
        </div>
        {fields?.CTAbutton?.value?.href ? (
          <a
            href={fields?.CTAbutton?.value?.href}
            target="_blank"
            rel="noreferrer"
            className={`w-fit mt-8 px-[30px] py-4  flex space-x-[10px] rtl:space-x-reverse items-center text-blueDark-500 text-[16px] leading-[18px] font-[500] hover:bg-gradient-to-b hover:from-[#CF4800] hover:via-[#F1B460] hover:to-[#ffffff] hover:text-blueDark-500 ${
              params?.BackgroundColor === "white"
                ? "bg-tumeric-200"
                : "bg-white"
            }`}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.4011 5.6211L10.7073 15.6746L12.2749 14.7695C13.0298 11.9521 14.6518 5.89879 14.8719 5.07764C15.1601 4.00205 15.0324 3.79553 13.9668 3.51002L4.27494 0.913089L2.70731 1.81816L12.7608 4.51199L1.02876 11.2855L1.39167 12.5541L13.4008 5.62061L13.4011 5.6211Z"
                fill="currentColor"
              />
            </svg>
            <span>{fields?.CTAbutton?.value?.text}</span>
          </a>
        ) : null}
      </div>
    </section>
  );
};

export default SingleColumn;
