import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import { RichText, Image, Link } from "@sitecore-jss/sitecore-jss-react";
import CTA from "../../../Common/CTA";

export default function Tours(fields) {
  const [lowCarbon, setLowCarbon] = useState(null);
  useEffect(() => {
    setLowCarbon(window.localStorage.getItem("low-carbon-mode") === "true");
  }, []);
  return (
    <>
      {lowCarbon !== null && (
        <section className="container bg-white  pt-20">
          <div>
            <h3
              className="mb-6 text-blueDark-500 font-[500] uppercase "
              dangerouslySetInnerHTML={{
                __html: fields?.fields?.data?.GQLSource?.title?.value,
              }}
            ></h3>
            <div className="mt-10 flex flex-wrap gap-y-5 justify-start -mx-3">
              {fields?.fields?.data?.GQLResult?.results?.items.map(
                (item, index) => {
                  let AddionalData =
                    JSON.parse(item?.item?.ListValues?.value) || {};
                  return (
                    <div
                      key={index}
                      className="mmb-6 md:mb-14 basis-full md:basis-6/12 lg:basis-4/12 px-3  text-base  relative flex flex-col justify-start"
                    >
                      <div>
                        {!lowCarbon && (
                          <Image
                            field={item?.item?.Image}
                            loading="lazy"
                            className="h-[310px] object-cover w-full"
                          />
                        )}
                        <div className="hidden dark:block h-[310px] object-cover dark:bg-yellow-gradient" />
                        <div className="flex gap-2 absolute top-0 mt-2 ms-2">
                          {item?.item?.StartDate?.rendered && (
                            <div className="text-tumeric-300 px-3 py-1 bg-tumeric-100 rounded-full text-base font-medium">
                              {new Date(
                                item?.item?.StartDate?.rendered
                              ).getDate()}{" "}
                              {new Date(
                                item?.item?.StartDate?.rendered
                              ).toLocaleString("default", {
                                month: "short",
                              })}{" "}
                              {new Date(item?.item?.StartDate?.rendered)
                                .getFullYear()
                                .toString()
                                .slice(-2)}
                            </div>
                          )}
                          {item?.item?.EndDate?.rendered && (
                            <div className="text-tumeric-300 px-3 py-1 bg-tumeric-100 rounded-full text-base font-medium">
                              {new Date(
                                item?.item?.EndDate?.rendered
                              ).getDate()}{" "}
                              {new Date(
                                item?.item?.EndDate?.rendered
                              ).toLocaleString("default", {
                                month: "short",
                              })}{" "}
                              {new Date(item?.item?.EndDate?.rendered)
                                .getFullYear()
                                .toString()
                                .slice(-2)}
                            </div>
                          )}
                        </div>
                        <div>
                          <RichText
                            field={item?.item?.Title}
                            className="text-xl text-brand font-bold my-4 "
                          />
                        </div>
                      </div>
                      <div>
                        <div className="mb-4">
                          {item?.item?.ListValues?.value &&
                            Object.keys(
                              JSON.parse(item?.item?.ListValues?.value)
                            )?.map((item, index) => {
                              console.log(item, ":", AddionalData[item]);
                              return (
                                <div className="flex" key={index}>
                                  <span className="font-medium basis-4/12">
                                    {item}:
                                  </span>
                                  <span className="basis-8/12">
                                    {parse(AddionalData[item])}
                                  </span>
                                </div>
                              );
                            })}
                        </div>
                        <RichText field={item?.item?.Description} />
                        {item?.item?.CTA?.url && (
                          <CTA className=" mt-4 flex w-fit items-center">
                            <a href={item?.item?.CTA?.url}>
                              {item?.item?.CTA?.text}
                            </a>
                          </CTA>
                        )}
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </section>
      )}
    </>
  );
}
