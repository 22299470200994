import React from "react";
import { SwiperSlide, Swiper } from "swiper/react";
import {
  RichText,
  Image,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import Location from "../../../../assets/icons/location.svg";
import TranslatedWord from "../../../Common/DictionaryTexts";

const EventDetails = ({ sitecoreContext }) => {
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const content = sitecoreContext?.route?.fields;
  const dd = parseInt(content?.EventDate?.value?.split("-")[2]);
  const mm = month[parseInt(content?.EventDate?.value?.split("-")[1]) - 1];
  console.log(content, "content");
  return (
    <section className="container py-12">
      <Swiper className="relative" spaceBetween={8} slidesPerView="auto">
        {content?.EventTheme?.map((item, index) => (
          <SwiperSlide key={index} className={`!w-max`}>
            <div className={`bg-blueDark-50 w-fit px-3 py-2 rounded-full flex`}>
              <div className="flex items-center text-blueDark-100">
                <RichText
                  field={item?.fields?.Value}
                  className="font-medium leading-[18px]"
                />
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="md:flex w-full mt-6">
        <div className="md:w-10/12 w-full">
          <RichText
            field={content?.EventTitle}
            className="text-blueDark-500 text-[40px] font-medium leading-[40.8px] uppercase"
          />
        </div>
        <div className=" text-blueDark-500 p-[5px] bg-sabkha md:ms-auto md:mt-0 mt-2 !min-w-max w-fit h-fit">
          <p className="text-sm font-normal">
            {dd} {mm}
          </p>
          <p className="text-[32px] leading-[32px] font-medium">
            {content?.EventStartTime?.value?.slice(0, 2)}:
            {content?.EventStartTime?.value?.slice(2, 4)}-
            {content?.EventEndTime?.value.slice(0, 2)}:
            {content?.EventEndTime?.value.slice(2, 4)}{" "}
            <span className="text-[11px] leading-[15px] font-normal">
              {content?.AdditionalData?.fields?.TimeZoneLabel?.value}
            </span>
          </p>
        </div>
      </div>
      <RichText
        field={content?.EventDescription}
        className="text-blueDark-500 text-xl leading-[24px] font-normal mt-6"
      />
      {content?.EventSpeakers?.length > 0 && (
        <div>
          <RichText
            field={content?.AdditionalData?.fields?.SpeakersLabel}
            className="text-blueDark-500 text-xl leading-[24px] font-bold mt-6"
          />
          <div className="mt-6">
            <Swiper className="relative" spaceBetween={16} slidesPerView="auto">
              {content?.EventSpeakers.map((item, index) => (
                <SwiperSlide key={index} className={`!w-max`}>
                  <div className={`flex items-center min-w-[200px]`}>
                    <Image
                      field={item?.fields?.Image}
                      className="rounded-full w-14 h-14 me-2"
                    />
                    <div>
                      <RichText
                        field={item?.fields?.Value}
                        className=" text-blueDark-500 text-xl font-normal"
                      />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      )}
      {content?.EventOrganizer?.value && (
        <div>
          <RichText
            field={content?.AdditionalData?.fields?.OrganiserLabel}
            className="text-blueDark-500 text-xl leading-[24px] font-bold mt-6"
          />
          <div className="mt-6 flex">
            <RichText
              field={content?.EventOrganizer}
              className="text-blueDark-500 text-xl !leading-[30px] font-normal"
            />
          </div>
        </div>
      )}
      {content?.EventVenue?.length > 0 &&
        content?.EventVenue[0]?.fields?.Value?.value && (
          <div>
            <p className="text-blueDark-500 text-xl leading-[24px] font-bold mt-6">
              <TranslatedWord EnWord="Venue/Hub" />
            </p>
            <div className="mt-6 flex">
              {content?.EventVenue?.map((venue, index) => (
                <RichText
                  key={index}
                  field={venue?.fields?.Value}
                  className="text-blueDark-500 text-xl !leading-[30px] font-normal me-4"
                />
              ))}
            </div>
          </div>
        )}
      {content?.EventCategoryType?.length > 0 &&
        content?.EventCategoryType[0]?.fields?.Value?.value && (
          <div>
            <p className="text-blueDark-500 text-xl leading-[24px] font-bold mt-6">
              <TranslatedWord EnWord="Event Type" />
            </p>
            <div className="mt-6 flex">
              {content?.EventCategoryType?.map((category, index) => (
                <RichText
                  key={index}
                  field={category?.fields?.Value}
                  className="text-blueDark-500 text-xl !leading-[30px] font-normal me-4"
                />
              ))}
            </div>
          </div>
        )}
      {content?.EventPartners?.length > 0 && (
        <div>
          <RichText
            field={content?.AdditionalData?.fields?.PartnersLabel}
            className="text-blueDark-500 text-xl leading-[24px] font-bold mt-6"
          />
          <div className="mt-6">
            <Swiper className="relative" spaceBetween={16} slidesPerView="auto">
              {content?.EventPartners.map((item, index) => (
                <SwiperSlide key={index} className={`!w-max`}>
                  <div className="text-blueDark-500 text-xl !leading-[30px] font-normal">
                    {item?.fields?.Image?.value?.src ? (
                      <Image field={item?.fields?.Image} className={`h-full`} />
                    ) : (
                      item?.fields?.Value?.value
                    )}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      )}
      {content?.EventLocation?.value && (
        <div>
          <RichText
            field={content?.AdditionalData?.fields?.LocationLabel}
            className="text-blueDark-500 text-xl leading-[24px] font-bold mt-6"
          />
          <div className="mt-6 flex items-center">
            <div className="flex items-center">
              <img src={Location} />
              <RichText
                field={content?.EventLocation}
                className="text-blueDark-500 text-base leading-[18px] font-medium ms-1"
              />
            </div>
            {/* <div className="flex items-center ms-7">
                  <img src={ Eye } />
                  <p className="text-blueDark-500 text-base leading-[18px] font-medium ms-1">
                      { content?.EventIsPublicEvent?.value ? (
                          'Public'
                      ):(
                          'Private'
                      )}
                  </p>
              </div> */}
          </div>
        </div>
      )}
      {/* <div>
          { content?.EventIsPublicEvent?.value && (
              <div className="mt-5 bg-tumeric-100 w-full flex-wrap gap-10 flex justify-between px-10 py-8 md:text-start text-center">
                  <h4 className="text-bluedark font-[500]">
                      {content?.AdditionalData?.fields?.RegistrationBoxLabel?.value}
                  </h4>
                  <div className="md:mx-0 mx-auto ">
                      <CTA
                          className="flex w-fit items-center ms-auto"
                          link={content?.EventUsefullLink}
                          download={false}
                      >
                          <Link field={content?.EventUsefullLink} />
                      </CTA>
                  </div>
              </div>
          )}
      </div> */}
    </section>
  );
};

export default withSitecoreContext()(EventDetails);
