import React from "react";

import { useEffect, useState } from "react";
import Switch from "react-switch";
import { Tooltip } from "react-tooltip";

function is_server() {
  return !(typeof window !== "undefined" && window.document);
}

export default function ModeSwitcher(props) {
  const { offColor = "#ffffff", id, content, className } = props;

  const [lowModeEnabled, setLowModeEnabled] = useState(false);

  useEffect(() => {
    // if (!is_server()) {
    //   const LOW_CARBON_MODE = localStorage.getItem("low-carbon-mode");
    //   handleLowModeSwitch(LOW_CARBON_MODE === "true" ? true : false);
    // }
    window.dataLayer = window.dataLayer || [];

    if (!is_server()) {
      if (window.localStorage.getItem("low-carbon-mode") === "true") {
        setLowModeEnabled(true);
        document.documentElement.setAttribute("data-theme", "low-carbon");
      } else {
        document.documentElement.removeAttribute("data-theme");
      }
    }
  }, []);

  function handleLowModeSwitch(val) {
    if (!is_server()) {
      localStorage.setItem("low-carbon-mode", val?.toString());
      // setLowModeEnabled(val);
      window.dataLayer.push({
        event: "carbon_mode_switch",
        status: val,
      });
      window.location.reload();
    }
  }
  return (
    <div
      id={id}
      className={`w-[250px] items-center basis-0 lg:basis-auto flex-wrap text-center order-first lg:order-none ${className}`}
    >
      {/* <Tooltip
        anchorId={id}
        html={content.MODE_SWITCHER_TOOLTIP}
        place="top"
        className="hidden lg:block"
      /> */}
      <Switch
        onChange={handleLowModeSwitch}
        checked={lowModeEnabled}
        checkedIcon={false}
        uncheckedIcon={false}
        offColor={offColor}
        onColor="#12574b"
        className=""
        height={24}
        width={42}
        uncheckedHandleIcon={
          <div className="relative top-[3px] start-[5px]">
            <svg
              width="8"
              height="12"
              viewBox="0 0 10 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.619833 9.5904H3.38689L1.33792 17.2566C1.26919 17.5241 1.40032 17.8037 1.65655 17.9346C1.7503 17.9762 1.84405 18 1.94383 18C2.125 18 2.29985 17.9285 2.42495 17.7859L9.85241 9.37597C10.0086 9.19745 10.0459 8.95377 9.93983 8.73965C9.83976 8.53155 9.61487 8.39465 9.37129 8.39465H7.11007L8.37802 0.687084C8.42173 0.419577 8.2719 0.157816 8.01567 0.0507614C7.75944 -0.0562936 7.45346 0.00914295 7.2786 0.217235L0.138987 8.62084C-0.0108434 8.79936 -0.0421828 9.04304 0.057888 9.25114C0.164003 9.45348 0.382564 9.59038 0.620096 9.59038L0.619833 9.5904ZM6.78487 2.6913L5.76053 8.9004C5.72918 9.07289 5.78555 9.24539 5.90431 9.37627C6.02308 9.50715 6.19793 9.58437 6.3791 9.58437H8.02803L3.21833 15.0263L4.79253 9.13233C4.84257 8.95381 4.79885 8.76352 4.68009 8.62086C4.56132 8.4782 4.38015 8.38895 4.18661 8.38895H1.92539L6.78487 2.6913Z"
                fill="#1F7B6B"
              />
            </svg>
          </div>
        }
        checkedHandleIcon={
          <div className="relative top-[3px] start-[5px]">
            <svg
              width="8"
              height="12"
              viewBox="0 0 10 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.619833 9.5904H3.38689L1.33792 17.2566C1.26919 17.5241 1.40032 17.8037 1.65655 17.9346C1.7503 17.9762 1.84405 18 1.94383 18C2.125 18 2.29985 17.9285 2.42495 17.7859L9.85241 9.37597C10.0086 9.19745 10.0459 8.95377 9.93983 8.73965C9.83976 8.53155 9.61487 8.39465 9.37129 8.39465H7.11007L8.37802 0.687084C8.42173 0.419577 8.2719 0.157816 8.01567 0.0507614C7.75944 -0.0562936 7.45346 0.00914295 7.2786 0.217235L0.138987 8.62084C-0.0108434 8.79936 -0.0421828 9.04304 0.057888 9.25114C0.164003 9.45348 0.382564 9.59038 0.620096 9.59038L0.619833 9.5904ZM6.78487 2.6913L5.76053 8.9004C5.72918 9.07289 5.78555 9.24539 5.90431 9.37627C6.02308 9.50715 6.19793 9.58437 6.3791 9.58437H8.02803L3.21833 15.0263L4.79253 9.13233C4.84257 8.95381 4.79885 8.76352 4.68009 8.62086C4.56132 8.4782 4.38015 8.38895 4.18661 8.38895H1.92539L6.78487 2.6913Z"
                fill="#979797"
              />
            </svg>
          </div>
        }
      />
      <label
        className=" font-[500] leading-none mt-2 lg:mt-0 mx-2 text-start text-xs"
        dangerouslySetInnerHTML={{
          __html: lowModeEnabled
            ? content?.SwitchtoFullExperience?.value
            : content?.SwitchtoLowVersion?.value,
        }}
      ></label>
    </div>
  );
}
