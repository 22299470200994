import React from "react";
import {
  RichText,
  Image,
  Link,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import CTA from "../../../Common/CTA";

const FullwidthTwoColumn = ({ sitecoreContext, fields, params, lowCarbon }) => {
  const position = fields?.BackgroundImagePosition?.displayName;
  const backgroundColor = params?.BackgroundColor;

  return (
    <>
      {lowCarbon !== null && (
        <section className=" bg-white relative ">
          <div
            className={`h-full bg-${backgroundColor} ${
              params?.BackgroundColor === "brand" && "gradient-green"
            }`}
          >
            <div
              className={`lg:flex ${
                position === "Left"
                  ? "flex-row-reverse flex-wrap-reverse"
                  : "flex-wrap"
              } items-center `}
            >
              <div
                className={`lg:w-4/12 ${
                  position === "Left" ? "md:pe-20" : "md:ps-20"
                }  px-6 h-full py-20 flex flex-col justify-center`}
              >
                <h3
                  className={`mb-11 font-[500] uppercase ${
                    backgroundColor === "brand" ? "text-white" : "text-brand"
                  }`}
                >
                  <RichText field={fields?.Title} />
                </h3>
                <div
                  className={`md:mb-8 text-lg md:text-xl ${
                    backgroundColor === "brand" ? "text-white" : "text-bluedark"
                  }`}
                >
                  <RichText field={fields?.Description} />
                </div>
                <div>
                  {fields?.CTAbutton?.value?.href && (
                    <Link
                      field={fields?.CTAbutton}
                      className="mt-5 inline-block"
                    >
                      <CTA
                        color={
                          sitecoreContext?.route?.fields?.PageTheme?.value ===
                          "Blue"
                            ? !!(fields?.CTAbutton?.value?.linktype === "media")
                              ? "blueDownLoad"
                              : "blue"
                            : ""
                        }
                        download={
                          !!(fields?.CTAbutton?.value?.linktype === "media")
                        }
                      >
                        {fields?.CTAbutton?.value?.text}
                      </CTA>
                    </Link>
                  )}
                </div>
              </div>
              <div
                className={`lg:w-7/12 md:mt-0 text-end lg:absolute ${
                  position === "Left" ? "start-0" : "end-0"
                } h-full top-0 dark:bg-green-gradient `}
              >
                {!lowCarbon && (
                  <Image
                    field={fields?.BackgroundImage}
                    className="w-full h-full object-cover dark:opacity-0"
                    loading="lazy"
                  />
                )}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default withSitecoreContext()(FullwidthTwoColumn);
