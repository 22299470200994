import React from "react";
import { Image, Link, Text } from "@sitecore-jss/sitecore-jss-react";
import FourTwoAllotment from "./FourTwoAllotment";

const CollectiveLogos = ({ fields, params }) => {
  return (
    <>
      {params?.Variant === "FourTwoAllotment" && (
        <FourTwoAllotment fields={fields} params={params} />
      )}
      {params.Variant === "Default" ||
        (!params?.Variant && (
          <>
            <div className={params.BackgroundColor}>
              <div className="container my-7 md:my-14">
                {fields?.Title?.value && (
                  <h3 className="text-brand uppercase mb-7 md:mb-14">
                    {fields?.Title?.value}
                  </h3>
                )}
                <div className="flex flex-wrap w-full box-border -mx-3">
                  {fields?.SelectMedia?.map((item, i) => {
                    return (
                      <div
                        key={i}
                        className="px-3 basis-full md:basis-6/12 lg:basis-3/12 mb-6 lg:mb-0"
                      >
                        <div className="h-[350px] flex flex-col justify-center bg-taupe-400 px-4 items-center">
                          <p className="text-brand font-bold relative -top-10">
                            <Text field={item.fields?.Title} />
                          </p>
                          <a
                            href={item.fields?.CTALink?.value?.href || null}
                            target={item.fields?.CTALink?.value?.target}
                          >
                            <Image field={item.fields?.Image} />
                          </a>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        ))}
    </>
  );
};

export default CollectiveLogos;
