import React, { useEffect, useState } from "react";
import { Parallax } from "react-scroll-parallax";
import { Link, Text, RichText, Image } from "@sitecore-jss/sitecore-jss-react";
import CTA from "../../../Common/CTA";
import TopAlignedText from "./TopAlignedText";
import StackedTextAndImage from "./StackedTextAndImage";
import PlaceholderComponent from "../../../Common/PlaceholderComponent";
import ThreeColumn from "./ThreeColumn";
import SingleColumn from "./SingleColumn";
import TwoColumn from "./TwoColumn";
import Accordion from "./Accordion";
import ShortHeight from "./ShortHeight";
import { Helmet } from "react-helmet";

const HeroBanner = ({ fields, params }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [lowCarbon, setLowCarbon] = useState(null);

  useEffect(() => {
    setLowCarbon(window.localStorage.getItem("low-carbon-mode") === "true");

    if (window.outerWidth <= 768) {
      setIsMobile(true);
    }
  });
  const BgStyle = {
    backgroundImage: `url(${fields?.BackgroundImage?.value?.src})`,
  };
  return (
    <>
      {(params?.Variant === "Default" || !params?.Variant) && (
        <>
          {lowCarbon !== null && (
            <section
              className={`relative pt-16 bg-${params?.BackgroundColor} bg-fixed bg-cover dark:bg-0`}
              style={BgStyle}
            >
              <Helmet>
                <meta name="image" content={fields?.Image?.value?.src} />
                <meta name="og:image" content={fields?.Image?.value?.src} />
              </Helmet>
              <div className="absolute top-0 start-0 w-full h-[532px] lg:h-[456px]  bg-gradient-to-b from-10% to-[rgba(0,0,0,0)] to-90% dark:from-brand dark:to-[#282016] dark:z-[-1]"></div>
              <Parallax
                speed={10}
                translateY={["10px", "200px"]}
                className="absolute end-0 hidden md:block"
              >
                <Image field={fields?.Image} />
              </Parallax>
              <div className="container relative z-100">
                <div className="min-h-[900px] flex flex-col justify-center  text-center">
                  <div className="mb-7 md:mb-14">
                    <h1 className="text-white mb-2">
                      <RichText field={fields?.Title} />
                    </h1>
                    <p className="text-lg md:text-xl font-normal text-white">
                      <Text field={fields?.Subtitle} />
                    </p>
                  </div>
                  <div className="flex justify-center mb-8 md:mb-16">
                    <div className="basis-full md:basis-6/12">
                      <div className="text-white text-lg md:text-xl">
                        <RichText field={fields?.Description} />
                      </div>
                    </div>
                  </div>
                  <div>
                    {fields?.CTALink?.value?.href && (
                      <Link field={fields?.CTALink}>
                        <CTA>{fields?.CTALink?.value?.text}</CTA>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </section>
          )}
        </>
      )}
      {params?.Variant === "TopAlignedText" && (
        <TopAlignedText
          lowCarbon={lowCarbon}
          fields={fields}
          params={params}
          isMobile={isMobile}
        />
      )}
      {params?.Variant === "StackedTextAndImage" && (
        <StackedTextAndImage fields={fields} params={params} />
      )}
      {params?.Variant &&
        [
          "TopAlignedText",
          "StackedTextAndImage",
          "Default",
          "ThreeColumn",
          "SingleColumn",
          "TwoColumn",
          "Accordion",
          "ShortHeight",
        ].indexOf(params?.Variant) === -1 && (
          <PlaceholderComponent title={`HeroBanner / ${params?.Variant}`} />
        )}
      {params?.Variant === "ThreeColumn" && (
        <ThreeColumn fields={fields} params={params} />
      )}
      {params?.Variant === "SingleColumn" && (
        <SingleColumn fields={fields} params={params} />
      )}
      {params?.Variant === "TwoColumn" && (
        <TwoColumn fields={fields} params={params} />
      )}
      {params?.Variant === "Accordion" && (
        <Accordion fields={fields} params={params} />
      )}
      {params?.Variant === "ShortHeight" && (
        <ShortHeight fields={fields} params={params} />
      )}
    </>
  );
};

export default HeroBanner;
