import { Image } from "@sitecore-jss/sitecore-jss-react";
import React from "react";

const SponsorCard = ({ data }) => {
  return (
    <a
      href={data.CTALink?.url}
      className="h-full flex flex-col items-center justify-between p-4 text-blueDark-500 hover:bg-[rgba(190,226,213,0.10)] hover:text-blueDark-100 cursor-pointer rounded-[16px]"
    >
      {data.LogoImage?.src ? (
        <Image field={data.LogoImage} />
      ) : (
        <div className="w-[145px] h-[75px] bg-black bg-opacity-5" />
      )}
      <div className="min-h-[50px] text-center text-[20px] leading-[24px] font-[500] mt-3">
        {data.Title?.value}
      </div>
    </a>
  );
};

export default SponsorCard;
