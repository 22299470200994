import React, { useEffect, useRef, useState } from "react";
import { Text, Image, RichText } from "@sitecore-jss/sitecore-jss-react";
import { Helmet } from "react-helmet";
import VideoModal from "../../../../Common/VideoModal";
import CTA from "../../../../Common/CTA";

const FullWidth = ({ fields, params, isMobile, lowCarbon }) => {
  const [showModal, setShowModal] = useState(false);

  const videoRef = useRef(null);
  return (
    <div className="  relative">
      {showModal && (
        <VideoModal
          video={fields?.Video}
          poster={fields?.BackgroundImage}
          onClose={() => setShowModal(false)}
          isIframe={fields?.IsIframe?.value}
        />
      )}
      <div className="lg:flex flex-wrap  relative lg:min-h-[562px] min-h-[720px]">
        {!lowCarbon && lowCarbon !== null && (
          <div
            className="bg-no-repeat bg-cover bg-center absolute top-0 start-0 h-full w-full"
            style={{
              backgroundImage: `url(${
                isMobile && fields?.BackgroundMobileImage?.value?.src
                  ? fields?.BackgroundMobileImage?.value?.src
                  : fields?.BackgroundImage?.value?.src
              })`,
            }}
          />
        )}
        <div className="hidden dark:block absolute w-full h-full   top-0 start-0 bg-green-gradient" />
        <div className="container h-full ">
          <div className="flex flex-wrap">
            <div className="lg:basis-7/12 basis-full h-full py-12 lg:py-24  z-10">
              <div
                className="dark:hidden lg:block hidden absolute w-full h-full top-0 start-0 "
                style={{ background: `${params?.gradient}` }}
              />
              <div
                className="dark:hidden lg:hidden block absolute w-full h-full top-0 start-0 "
                style={{ background: `${params?.MobileGradient}` }}
              />
              <div className="flex lg:w-9/12 w-full h-full items-center relative">
                <div>
                  <h3 className="text-white  lg:text-4xl xl:text-[56px] xl:leading-[55px] font-[500]">
                    <RichText field={fields?.Title} />
                  </h3>
                  <div className="my-6 md:my-14 text-lg md:text-xl text-white">
                    <RichText field={fields?.Description} />
                  </div>
                  {fields?.CTA?.value?.href && (
                    <a href={fields?.CTA?.value?.href}>
                      <CTA>{fields?.CTA?.value?.text}</CTA>
                    </a>
                  )}
                </div>
              </div>
            </div>
            <div className="lg:basis-5/12 basis-full  font-bold text-grey-900 md:mt-0 mt-10">
              <div className="video md:pb-0 h-full relative flex items-center justify-center z-10">
                <svg
                  className="cursor-pointer text-tumeric-200 hover:text-brand"
                  width="80"
                  height="80"
                  viewBox="0 0 80 80"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => setShowModal(true)}
                >
                  <rect width="80" height="80" rx="40" fill="currentColor" />
                  <circle cx="40" cy="40" r="39.5" stroke="currentColor" />
                  <path
                    d="M33 27.8756L54 40L33 52.1244L33 27.8756Z"
                    fill="white"
                    stroke="currentColor"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullWidth;
