export default function Error() {
  return (
    <>
      <section className="bg-white">
        <div className="w-full flex md:flex-row flex-col relative overflow-hidden pt-[200px] pb-[60px]">
          <div className="md:w-10/12 w-11/12 min-h-[600px] md:px-20 px-5 py-32 me-auto ms-auto text-center bg-brand">
            <h3 className="text-white font-bold text-[150px]">
              <div>500</div>
            </h3>
            <div className="mt-14 text-3xl font-bold text-white">
              <div>UNKNOWN ERROR HAPPEND</div>
            </div>
            <div className="mt-6">
              <button className="inline-flex border-0 items-center  group cursor-pointer bg-lime-green-900 hover:bg-brand hover:text-white  font-bold relative z-10  py-3 px-8  w-fit">
                <a href="/en/" className="" title="" target="">
                  Go Back
                </a>
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
