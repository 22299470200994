import React from "react";
import { Image, RichText } from "@sitecore-jss/sitecore-jss-react";

const FirstCardFullWidth = ({ fields }) => {
  return (
    <section className="morpheus-den-gradient color-block pt-10 md:pt-20">
      <div className="container">
        <div className=" text-white md:w-[71%]">
          <div className="row text-brand  pt-5 md:px-0 md:pt-0">
            <h2 className="mb-6 text-[56px] text-blueDark-100 font-[500]">
              <RichText field={fields?.Title} />
            </h2>
          </div>
          <div className="md:grid md:grid-cols-2 md:gap-10 mt-10">
            {fields?.cards?.map((item, index) => {
              return (
                <div
                  className={index === 0 ? `col-span-2` : "col-span-1"}
                  key={index}
                >
                  <div className="card">
                    <Image
                      field={item?.fields?.Image}
                      className="md:h-[376px]"
                    />
                    <div className="card-img-overlay mt-5 px-5 md:px-0">
                      <h4 className="mb-6 md:text-[20px] text-[16px] text-blueDark-100 font-[500]">
                        <RichText field={item?.fields?.Title} />
                      </h4>
                      <div className="mb-6 text-bluedark">
                        <RichText field={item?.fields?.Description} />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FirstCardFullWidth;
