import React, { useEffect, useRef, useState } from "react";
import { SwiperSlide, Swiper } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import { RichText, Image, Text, Link } from "@sitecore-jss/sitecore-jss-react";
import arrowLeft from "../../../../assets/icons/arrow-left-dark.svg";
import arrowRight from "../../../../assets/icons/arrow-right-dark.svg";
import CTA from "../../../Common/CTA";
import ReactPlayer from "react-player/lazy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import VideoModal from "../../../Common/VideoModal";

const Promo = ({ params, fields, isRTL, isLowCarbon }) => {
  console.log(isLowCarbon);
  const [hideNav, setHideNav] = useState(false);
  const [swiper, setSwiper] = useState();
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const swiperRef = useRef(null);

  useEffect(() => {
    if (
      window.innerWidth >= 1280 &&
      fields?.data?.GQLResult?.results?.items?.length <= 3
    ) {
      setHideNav(true);
    }
  });

  return (
    <section className={`carousel-promo bg-${params?.BackgroundColor}`}>
      <div className="text-blueDark-500 container py-14 ">
        <div className="flex flex-row justify-between items-center flex-wrap lg:flex-none">
          <h3 className="inline text-[56px] leading-[102%] font-medium">
            <RichText field={fields?.Title} />
          </h3>

          <div className=" md:w-fit inline-flex rtl:flex-row-reverse w-fit mt-8 lg:mt-0 items-center md:justify-end justify-between gap-6">
            <button
              ref={prevRef}
              className={`${isRTL === "true" ? "rotate-180" : ""} `}
            >
              <img src={arrowLeft} alt="arrow-prev" />
            </button>
            <button
              ref={nextRef}
              className={`${isRTL === "true" ? "rotate-180" : ""} `}
            >
              <img src={arrowRight} alt="arrow-next" />
            </button>
          </div>
        </div>
        <div className="text-[20px] leading-[24px] mt-4">
          <Text field={fields?.SubTitle} />
        </div>
        <div className="mt-4">
          <Swiper
            ref={swiperRef}
            dir={isRTL ? "rtl" : "ltr"}
            className="w-full relative "
            spaceBetween={24}
            autoplay={!!params?.Autoplay}
            loop={!!params?.Autoplay}
            speed={parseInt(params.Autoplay) || 5000}
            slidesPerView="auto"
            modules={[Navigation, Autoplay]}
            navigation={{
              prevEl: prevRef.current,
              nextEl: nextRef.current,
            }}
            onInit={(s) => setSwiper(s)}
            pagination={{
              type: "fraction",
            }}
          >
            {fields?.Carousel?.map((item, index) => (
              <SwiperSlide key={index} className={`!w-[302px]`}>
                {item?.fields?.CTA?.value?.href && params?.enablecta && (
                  <a
                    href={item?.fields?.CTA?.value?.href}
                    className="hover:text-brand"
                  >
                    <PromoCard
                      item={item}
                      params={params}
                      isLowCarbon={isLowCarbon}
                    />
                  </a>
                )}
                {(!item?.fields?.CTA?.value?.href || !params?.enablecta) && (
                  <PromoCard
                    item={item}
                    params={params}
                    isLowCarbon={isLowCarbon}
                  />
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        {fields?.CTA?.value?.href && (
          <div className="flex justify-center mt-6">
            <Link field={fields?.CTA}>
              <CTA>{fields?.CTA?.value?.text}</CTA>
            </Link>
          </div>
        )}
      </div>
    </section>
  );
};

export default Promo;

const PromoCard = function ({ item, params, isLowCarbon }) {
  const [showModal, setShowModal] = useState(false);
  console.log(isLowCarbon);
  return (
    <div className="h-full text-start">
      <div>
        {item?.fields?.Image?.value?.src && (
          <div className="relative flex items-center justify-center">
            <div
              className={`min-h-[196px] w-full relative flex items-center justify-center ${
                isLowCarbon ? "bg-green-gradient" : ""
              }`}
            >
              {isLowCarbon !== null && !isLowCarbon && (
                <>
                  <Image
                    field={item?.fields?.Image}
                    className={`w-full   ${
                      params?.SquareImages ? " " : "h-[196px] object-cover"
                    }`}
                  />
                  {item?.fields?.Video?.value?.href && (
                    <div className="absolute ">
                      <div
                        onClick={() => setShowModal(true)}
                        className="flex bg-[rgba(0,0,0,0.4)] items-center justify-center rounded-full border w-14 h-14 cursor-pointer hover:border-brand group border-1 border-white"
                      >
                        <FontAwesomeIcon
                          className=" text-white text-2xl group-hover:text-brand"
                          icon={faPlay}
                        />
                      </div>
                      {showModal && (
                        <VideoModal
                          video={item?.fields?.Video}
                          poster={item?.fields?.Image}
                          onClose={() => setShowModal(false)}
                        />
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}

        {item?.fields?.SubTitle?.value && (
          <div className="w-fit bg-greelight uppercase p-[6px]">
            <h3 className="w-fit !text-[20px] leading-[100%] font-medium">
              <Text field={item?.fields?.SubTitle} />
            </h3>
          </div>
        )}
        <h4 className="text-[32px] leading-[102%] font-[500] mt-4">
          <RichText field={item?.fields?.Title} />
        </h4>
        <div className="mt-4 font-[16px] leading-[18px]">
          <RichText field={item?.fields?.Description} />
        </div>
      </div>
      {item?.item?.CTA?.url && (
        <a
          href={item?.item?.CTA?.url}
          target={item?.item?.CTA?.linktype === "external" ? "_blank" : "_self"}
          rel="noreferrer"
        >
          <CTA
            color="brand"
            className="flex w-full  justify-center items-center mt-6 "
            external={item?.item?.CTA?.linktype === "external"}
          >
            {item.item?.CTA?.text}
          </CTA>
        </a>
      )}
    </div>
  );
};
