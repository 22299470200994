import React, { useEffect } from "react";

const Modal = (props) => {
  const { open, style = "light", children, onCloseModal } = props;

  useEffect(() => {
    const $body = document.querySelector("body");
    if (open) {
      $body.style.overflow = "hidden";
    } else {
      $body.style.overflow = "auto";
    }
  }, [open]);

  return (
    <>
      {open && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[999] outline-none focus:outline-none">
            <div className={`relative w-auto my-6 mx-4 md:mx-auto max-w-4xl`}>
              <div
                className={`border-0 shadow-lg relative flex flex-col w-full ${
                  style === "dark" ? "bg-brand" : "bg-white"
                } outline-none focus:outline-none`}
              >
                <button
                  className="absolute end-4 top-2 z-40 p-1 ms-auto bg-transparent border-0 text-grey-900 float-end text-3xl leading-none font-normal outline-none focus:outline-none"
                  onClick={() => onCloseModal()}
                >
                  <span
                    className={`${
                      style === "dark" ? "text-white" : "text-grey-900"
                    } h-6 w-6 text-lg md:text-xl focus:outline-none`}
                  >
                    ×
                  </span>
                </button>
                <div className="relative p-6 flex-auto">{children}</div>
              </div>
            </div>
          </div>
          <div className=" opacity-60 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
    </>
  );
};

export default Modal;
