import React from "react";
import { SwiperSlide, Swiper } from "swiper/react";
import {
  RichText,
  Image,
  Link,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import CTA from "../../../Common/CTA";

const FoodandBeverageDetails = ({ sitecoreContext }) => {
  const content = sitecoreContext?.route?.fields;

  return (
    <section className="container py-12">
      <div className="md:flex">
        <div className="md:w-9/12 w-full md:pe-10">
          {content?.Image?.value?.src && <Image field={content?.Image} />}
          <RichText
            field={content?.pageTitle}
            className="uppercase text-blueDark-500 text-[40px] leading-[40.8px] font-medium mt-4"
          />
          <Swiper
            className="relative mt-8"
            spaceBetween={8}
            slidesPerView="auto"
          >
            <SwiperSlide className={`!w-max`}>
              {content?.Venue?.fields?.Value?.value && (
                <div
                  className={`bg-blueDark-50 w-fit px-3 py-2 rounded-full flex`}
                >
                  <div className="flex items-center text-blueDark-100">
                    <RichText
                      field={content?.Venue?.fields?.Value}
                      className="font-medium leading-[18px]"
                    />
                  </div>
                </div>
              )}
            </SwiperSlide>
          </Swiper>
          <RichText
            field={content?.content}
            className="text-blueDark-500 text-[20px] leading-5 font-normal mt-6"
          />
        </div>
        {/* <div className="md:w-3/12 w-full md:ps-5 md:mt-0 mt-10">
                <RichText field={content?.AdditionalData?.fields?.OpeningHoursLabel} className="text-blueDark-500 text-[20px] leading-5 font-bold" />
                <p className="text-blueDark-100 text-[20px] leading-5 font-normal mt-6">OPEN</p>
                <p className="text-blueDark-500 text-[20px] leading-5 font-normal mt-6"><span className="font-medium">Monday</span> {content?.StartTime?.value}-{content?.EndTime?.value}</p>
                <p className="text-blueDark-500 text-[20px] leading-5 font-normal"><span className="font-medium">Tuesday</span> {content?.StartTime?.value}-{content?.EndTime?.value}</p>
                <p className="text-blueDark-500 text-[20px] leading-5 font-normal"><span className="font-medium">Wednesday</span> {content?.StartTime?.value}-{content?.EndTime?.value}</p>
                <p className="text-blueDark-500 text-[20px] leading-5 font-normal"><span className="font-medium">Thursday</span> {content?.StartTime?.value}-{content?.EndTime?.value}</p>
                <p className="text-blueDark-500 text-[20px] leading-5 font-normal"><span className="font-medium">Friday</span> {content?.StartTime?.value}-{content?.EndTime?.value}</p>
                <p className="text-blueDark-500 text-[20px] leading-5 font-normal"><span className="font-medium">Saturday</span> {content?.StartTime?.value}-{content?.EndTime?.value}</p>
                <p className="text-blueDark-500 text-[20px] leading-5 font-normal"><span className="font-medium">Sunday</span> {content?.StartTime?.value}-{content?.EndTime?.value}</p>
                <RichText field={content?.AdditionalData?.fields?.AveragePriceLabel} className="text-blueDark-500 text-[20px] leading-5 font-bold mt-6" />
                <p className="text-blueDark-500 text-[20px] leading-5 font-medium mt-6">24 AED</p>
                <RichText field={content?.AdditionalData?.fields?.CapacityLabel} className="text-blueDark-500 text-[20px] leading-5 font-bold mt-6" />
                <p className="text-blueDark-500 text-[20px] leading-5 font-medium mt-6">100 People</p>
            </div> */}
      </div>
      {/* <div className="mt-14">
            <div className="bg-tumeric-100 w-full flex-wrap gap-10 flex justify-between px-10 py-8 md:text-start text-center">
                <h4 className="text-bluedark font-[500]">
                    {content?.AdditionalData?.fields?.DownloadOurMenuLabel?.value}
                </h4>
                <div className="md:mx-0 mx-auto ">
                    <CTA
                        className="flex w-fit items-center ms-auto"
                        link={content?.EventUsefullLink}
                        download={true}
                    >
                        <Link field={content?.EventUsefullLink} />
                    </CTA>
                </div>
            </div>
        </div> */}
      {JSON.parse(content?.LocationDetails?.value)?.map((item, index) => (
        <div
          key={index}
          className={`${index === 0 ? "mt-14" : "mt-6"} md:flex gap-x-6`}
        >
          {item?.Location && (
            <div className="w-[250px] md:mt-0 mt-6">
              <RichText
                field={content?.AdditionalData?.fields?.LocationLabel}
                className="text-blueDark-500 text-[20px] leading-5 font-bold"
              />
              <p className="text-blueDark-500 text-[20px] leading-5 font-normal mt-6">
                {item?.Location}
              </p>
            </div>
          )}
          {item?.OpenDate && (
            <div className="min-w-[195px]">
              <RichText
                field={content?.AdditionalData?.fields?.OpenDateLabel}
                className="text-blueDark-500 text-[20px] leading-5 font-bold"
              />
              <p className="text-blueDark-500 text-[20px] leading-5 font-normal mt-6">
                {item?.OpenDate}
              </p>
            </div>
          )}
          {item?.OpenTime && (
            <div className="min-w-[195px] md:mt-0 mt-6">
              <RichText
                field={content?.AdditionalData?.fields?.OpenTimesLabel}
                className="text-blueDark-500 text-[20px] leading-5 font-bold"
              />
              <p className="text-blueDark-500 text-[20px] leading-5 font-normal mt-6">
                {item?.OpenTime}
              </p>
            </div>
          )}
          {item?.Zone && (
            <div className="min-w-[195px] md:mt-0 mt-6">
              <RichText
                field={content?.AdditionalData?.fields?.ZoneLabel}
                className="text-blueDark-500 text-[20px] leading-5 font-bold"
              />
              <p className="text-blueDark-500 text-[20px] leading-5 font-normal mt-6">
                {item?.Zone}
              </p>
            </div>
          )}
        </div>
      ))}
    </section>
  );
};

export default withSitecoreContext()(FoodandBeverageDetails);
