import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import { RichText, Image, Link } from "@sitecore-jss/sitecore-jss-react";
import CTA from "../../../Common/CTA";

export default function News({ fields, params }) {
  const [lowCarbon, setLowCarbon] = useState(null);
  useEffect(() => {
    setLowCarbon(window.localStorage.getItem("low-carbon-mode") === "true");
  }, []);
  console.log("fieldsssss", fields);
  return (
    <>
      {lowCarbon !== null && (
        <section className={` bg-${params?.BackgroundColor || "white"}  pt-20`}>
          <div className="container">
            <h3
              className="mb-6 text-blueDark-500 font-[500] uppercase "
              dangerouslySetInnerHTML={{
                __html: fields?.data?.GQLSource?.title?.value,
              }}
            ></h3>
            <div className="mt-10 flex flex-wrap gap-y-5 justify-center -mx-3">
              {fields?.data?.GQLResult?.results?.items.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="mmb-6 md:mb-14 basis-full md:basis-6/12 lg:basis-3/12 px-3  text-base  relative flex flex-col justify-between"
                  >
                    {!lowCarbon && (
                      <Image
                        field={item?.item?.children[0]?.children[0]?.Image}
                        loading="lazy"
                        className="h-[230px] object-cover"
                      />
                    )}
                    <div className="hidden dark:block h-[50px] object-cover " />
                    <div className="flex gap-2 absolute top-0 mt-2 ms-2">
                      {item?.item?.StartDate?.rendered && (
                        <div className="text-tumeric-300 px-3 py-1 bg-tumeric-100 rounded-full text-base font-medium">
                          {new Date(item?.item?.StartDate?.rendered).getDate()}{" "}
                          {new Date(
                            item?.item?.StartDate?.rendered
                          ).toLocaleString("default", {
                            month: "short",
                          })}{" "}
                          {new Date(item?.item?.StartDate?.rendered)
                            .getFullYear()
                            .toString()
                            .slice(-2)}
                        </div>
                      )}
                      {item?.item?.EndDate?.rendered && (
                        <div className="text-tumeric-300 px-3 py-1 bg-tumeric-100 rounded-full text-base font-medium">
                          {new Date(item?.item?.EndDate?.rendered).getDate()}{" "}
                          {new Date(
                            item?.item?.EndDate?.rendered
                          ).toLocaleString("default", {
                            month: "short",
                          })}{" "}
                          {new Date(item?.item?.EndDate?.rendered)
                            .getFullYear()
                            .toString()
                            .slice(-2)}
                        </div>
                      )}
                    </div>
                    <div className="">
                      <RichText
                        field={item?.item?.children[0]?.children[0]?.Title}
                        className="text-brand font-bold mt-5"
                      />
                      <br />
                      <RichText field={item?.item?.Description} />
                    </div>
                    {item?.item?.CTA?.url && (
                      <CTA className=" mt-4 flex w-fit items-center">
                        <a href={item?.item?.CTA?.url}>
                          {item?.item?.CTA?.text}
                        </a>
                      </CTA>
                    )}
                  </div>
                );
              })}
              {fields?.data?.GQLSource?.CTA?.url && (
                <CTA
                  className="flex w-fit items-center mb-6 md:mb-14 "
                  color="brand"
                >
                  <a href={fields?.data?.GQLSource?.CTA?.url}>
                    {fields?.data?.GQLSource?.CTA?.text}
                  </a>
                </CTA>
              )}
            </div>
          </div>
        </section>
      )}
    </>
  );
}
