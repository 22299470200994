import React from "react";
import {
  RichText,
  Link,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import CTA from "../../../../Common/CTA";

const CTABar = ({ sitecoreContext, fields, params }) => {
  return (
    <section
      className={`${
        params?.BackgroundColor === "brand" ? "bg-brand" : "bg-white"
      } py-5 pb-10 `}
    >
      <div className="container">
        <div className="mt-5 bg-tumeric-100 w-full flex-wrap gap-10 flex justify-between px-10 py-8">
          <h4 className="text-bluedark font-[500]">
            <RichText field={fields?.Title} />
          </h4>
          <div>
            <Link field={fields?.CTAbutton}>
              <CTA
                className="flex w-fit items-center ms-auto"
                download={fields?.CTAbutton?.value?.linktype === "media"}
                external={fields?.CTAbutton?.value?.linktype === "external"}
                color={
                  sitecoreContext?.route?.fields?.PageTheme?.value === "Blue" &&
                  "blueDownLoad"
                }
              >
                <span>{fields?.CTAbutton?.value?.text}</span>
              </CTA>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withSitecoreContext()(CTABar);
