import { RichText, Text } from "@sitecore-jss/sitecore-jss-react";
import React from "react";

const MulticardListWithSubCardsVariant = ({ fields }) => {
  return (
    <div className="bg-white py-14">
      <div className="container">
        <h3 className="text-[56px] leading-[102%] font-medium text-brand uppercase">
          {fields?.Title?.value}
        </h3>
        {fields?.SelectedPrimaryCards?.slice(0, 1).map((card) => (
          <div key={card.id} className="mt-14">
            <div className="w-fit p-2 rounded-full bg-wadi text-blueDark-600 text-[16px] leading-[18px] font-medium">
              <Text field={card.fields?.TimeSlot} />
            </div>
            <h3 className="mt-4 text-[32px] leading-[102%] font-medium uppercase text-blueDark-500">
              <RichText field={card.fields?.Title} />
            </h3>
            <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-4 gap-y-8">
              {card.fields?.SelectedSubCards?.map((subCard) => (
                <div key={subCard.id}>
                  <div className="text-brand text-center text-[20px] leading-[24px] font-medium">
                    <Text field={subCard.fields?.Title} />
                  </div>
                  <div className="mt-4 text-blueDark-500 text-center text-[16px] leading-[20px]">
                    <Text field={subCard.fields?.Description} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MulticardListWithSubCardsVariant;
