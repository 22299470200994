import React from "react";
import {
  Image,
  RichText,
  Link,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import CTA from "../../../Common/CTA";

const Poloroid = ({ sitecoreContext, fields }) => {
  return (
    <section className="morpheus-den-gradient color-block">
      <div className="container">
        <div className="md:grid md:grid-cols-2 mt-10">
          {fields?.cards?.map((item, index) => {
            return (
              <div className={"col-span-1 md:px-4 mt-5 pb-10"} key={index}>
                <div
                  className={`bg-gradient-to-r from-10% to-80% ${
                    index === 0
                      ? "dark:from-[#010B35] dark:to-[#3F5A75]"
                      : "dark:from-[#959F7F] dark:to-[#CECBD1]"
                  }`}
                >
                  <Image
                    field={item?.fields?.BackgroundImage}
                    className="w-full dark:opacity-0"
                  />
                </div>
                <div className="mt-5 px-5 md:px-0">
                  <h3
                    className={`mb-6 text-5xl ${
                      sitecoreContext?.route?.fields?.PageTheme?.value ===
                      "Blue"
                        ? "text-blueDark-500"
                        : "text-brand"
                    } font-[500]`}
                  >
                    <RichText field={item?.fields?.Title} />
                  </h3>
                  <div className="mb-6 text-xl text-bluedark">
                    <RichText field={item?.fields?.Description} />
                  </div>
                  <div className="mt-6">
                    {item?.fields?.CTAbutton?.value?.href && (
                      <Link field={item?.fields?.CTAbutton}>
                        <CTA
                          className={`flex w-fit items-center`}
                          color={
                            sitecoreContext?.route?.fields?.PageTheme?.value ===
                            "Blue"
                              ? "blue"
                              : index === 0
                              ? "blueDark"
                              : "brand"
                          }
                        >
                          {item?.fields?.CTAbutton?.value?.text}
                        </CTA>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default withSitecoreContext()(Poloroid);
