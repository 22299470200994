import React from "react";

const BackgroundVideo = ({ fields, params }) => {

  return (
    <>
      <div>
        <h1>Background Video</h1>
      </div>
    </>
  );
};

export default BackgroundVideo;
