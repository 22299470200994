import React from "react";
import { RichText, Link, Image } from "@sitecore-jss/sitecore-jss-react";
import CTA from "../../../Common/CTA";

const TwoColumn = ({ fields, lowCarbon }) => {
  return (
    <>
      {lowCarbon !== null && (
        <section className="container">
          <div className=" md:px-10 px-5 py-16 md:gap-0 gap-10 flex md:flex-row flex-col bg-blueDark-100 my-10">
            <div className="w-full md:px-5">
              <h2 className="md:text-[56px] md:leading-[56px] text-4xl mb-6 text-white font-[500]">
                <RichText field={fields?.Title} />
              </h2>
              <div className="mt-4 text-lg md:text-xl text-white">
                <RichText field={fields?.Description} />
              </div>
              {fields?.CTAbutton?.value?.href && (
                <div className="text-bluedark mt-5">
                  <Link field={fields?.CTAbutton}>
                    <CTA className="flex w-fit items-center">
                      {fields?.CTAbutton?.value?.text}
                    </CTA>
                  </Link>
                </div>
              )}
            </div>
            <div className="md:w-1/2 w-full flex justify-end dark:bg-yellow-gradient">
              {!lowCarbon && (
                <Image
                  field={fields?.BackgroundImage}
                  className="w-full h-full object-cover dark:opacity-0"
                  alt=""
                />
              )}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default TwoColumn;
