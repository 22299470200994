import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import ImageCard from "./imageCard";
import TranslatedWord from "../../../Common/DictionaryTexts";
import LoadingCard from "./loadingCard";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";

const GetImagesList = gql`
  query GetImages(
    $datasource: String!
    $language: String!
    $first: Int!
    $after: String!
    $sortBy: String!
    $filters: [ItemSearchFieldQuery]
  ) {
    GQLResult: extendedsearch(
      first: $first
      after: $after
      rootItem: $datasource
      language: $language
      sortBy: $sortBy
      sortDesc: true
      index: "cop28_website_{database}_index"
      fieldsEqual: [
        { name: "_template", value: "99b4aff59276433890627751819c6f9f" }
      ]
      fieldsContains: $filters
    ) {
      results {
        items {
          item {
            name
            imagetype: field(name: "ImageTypes") {
              value
              ... on MultilistField {
                targetItems {
                  value: field(name: "Value") {
                    value
                  }
                }
              }
            }
            Image: field(name: "Image") {
              ... on ImageField {
                src
                alt
              }
            }
            StartDate: field(name: "StartDate") {
              rendered
            }
            EndDate: field(name: "EndDate") {
              rendered
            }
            Description: field(name: "Description") {
              value
            }
          }
        }
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
      }
    }
    # GQLSource: item(path: $datasource) {
    #   viewAllUrl: url
    #   title: field(name: "Title") {
    #     value
    #   }
    #   Subtitle: field(name: "Description") {
    #     value
    #   }
    #   CTA: field(name: "CTALink") {
    #     ... on LinkField {
    #       url
    #       text
    #     }
    #   }
    # }
  }
`;
const itemsPerPage = [1, 1, 1, 1, 1, 1, 1, 1];

const ImagesListing = function (props) {
  const zoomRef = React.useRef(null);
  const contextFields = props.sitecoreContext?.route?.fields;
  const filters = contextFields?.Filters || [];
  const [selectedFilter, set_selectedFilter] = useState(0);
  const [currentLoadedPage, set_currentLoadedPage] = useState(0);
  const [currentViewPage, set_currentViewPage] = useState(0);
  const [fetching, set_fetching] = useState(false);
  const [results, set_results] = useState(
    props?.fields?.data?.GQLResult?.results?.items || []
  );
  const [hasNextPage, set_hasNextPage] = useState(
    props?.fields?.data?.GQLResult?.results?.pageInfo?.hasNextPage
  );
  const { loading, error, data, networkStatus, refetch } = useQuery(
    GetImagesList,
    {
      variables: {
        datasource: props.rendering.dataSource,
        language: "en",
        keyword: "",
        first: itemsPerPage.length,
        after: "0",
        sortBy: "StartDate",
        $fieldsEqual: [{ name: "IsGlobalEventDetailPage", value: "1" }],
      },
      notifyOnNetworkStatusChange: true, // This is an importantflag that makes loading status get changed when refetch
    }
  );
  /** networkStatus ---------
    1: loading
    2: setVariables
    3: fetchMore
    4: refetch
    5: poll
    6: ready
  */
  const [isFirstLoad, set_isFirstLoad] = useState(true);
  const [showLoadMoreButton, set_showLoadMoreButton] = useState(false);
  const [showHideButton, set_showHideButton] = useState(false);
  const [filterModalShow, set_filterModalShow] = useState(false);
  const [selectedImage, set_selectedImage] = useState(null);

  const loadMore = () => {
    if (currentLoadedPage === currentViewPage) {
      refetchResults(currentLoadedPage + 1);
    } else {
      set_currentViewPage(currentViewPage + 1);
    }
  };

  const loadLess = () => {
    set_currentViewPage(0);
  };

  const clearFilter = () => {
    set_selectedFilter(0);
    set_currentLoadedPage(0);
    set_currentViewPage(0);
  };

  const refetchResults = (currentLoadedPage) => {
    set_fetching(true);
    if (!currentLoadedPage) {
      set_results([]);
      set_hasNextPage(false);
    }
    set_currentLoadedPage(currentLoadedPage);
    set_currentViewPage(currentLoadedPage);

    const _filters = [];
    if (filters[selectedFilter]?.fields?.Value.value !== "All images") {
      _filters.push({
        name: "ImageTypes",
        value: `${filters[selectedFilter]?.id?.replace(
          /[^a-zA-Z0-9{}]+/g,
          ""
        )}`,
      });
    }
    console.log("=============== Fetching the GQL Results ===============\n");
    refetch({
      keyword: "",
      first: itemsPerPage.length,
      after: (itemsPerPage.length * currentLoadedPage).toString(),
      sortBy: "StartDate",
      filters: _filters,
    });
  };

  useEffect(() => {
    if (!isFirstLoad) {
      refetchResults(0);
    }
  }, [selectedFilter]);

  useEffect(() => {
    if (isFirstLoad && hasNextPage) {
      set_showHideButton(false);
      set_showLoadMoreButton(true);
      return;
    }
    if (!isFirstLoad && !fetching) {
      if (!hasNextPage) {
        if (currentLoadedPage === 0) {
          set_showLoadMoreButton(false);
          set_showHideButton(false);
          return;
        }
        if (currentLoadedPage === currentViewPage) {
          set_showHideButton(true);
          set_showLoadMoreButton(false);
          return;
        } else {
          set_showHideButton(false);
          set_showLoadMoreButton(true);
          return;
        }
      } else {
        set_showLoadMoreButton(true);
        set_showHideButton(false);
        return;
      }
    } else {
      set_showHideButton(false);
      set_showLoadMoreButton(false);
      return;
    }
  }, [
    results,
    isFirstLoad,
    fetching,
    hasNextPage,
    currentLoadedPage,
    currentViewPage,
  ]);

  useEffect(() => {
    if (!loading) {
      if (isFirstLoad) {
        console.log();
        set_isFirstLoad(false);
      } else {
        console.log(
          "=============== Finished Fetching ===============",
          data?.GQLResult?.results?.items?.length
        );
        set_hasNextPage(data?.GQLResult?.results?.pageInfo?.hasNextPage);
        const newResults = data?.GQLResult?.results?.items || [];
        if (currentLoadedPage) {
          set_results([...results, ...newResults]);
        } else {
          set_results(newResults);
        }
        set_fetching(false);
      }
    }
  }, [loading]);

  return (
    <div className="py-14">
      <div className="container">
        <div className="flex md:flex-row flex-col md:items-center items-start justify-between">
          <h3 className="text-brand font-[500] text-[40px]">
            {props?.params?.ComponentTitle}
          </h3>
          <div className="md:w-[400px] w-full">
            <div
              className={`w-full h-full fixed start-0 top-0 z-10 ${
                filterModalShow ? "" : "hidden"
              }`}
              onClick={() => set_filterModalShow(false)}
            />
            <div className="relative w-full h-full">
              <button
                className="w-full h-full flex items-center justify-between text-[16px] leading-[18px] space-x-[10px] rtl:space-x-reverse px-4 py-3 border-b-[2px] border-blueDark-500"
                onClick={() => set_filterModalShow(!filterModalShow)}
              >
                <div>
                  {props?.params?.FilterByText}{" "}
                  <span className="font-medium">
                    {filters[selectedFilter]?.fields?.Value?.value}
                  </span>
                </div>
                <svg
                  className={`${filterModalShow ? "rotate-180" : ""}`}
                  width="32"
                  height="32"
                  viewBox="0 0 19 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.67773 6.75L9.17773 11.25L13.6777 6.75"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
              <div
                className={`absolute top-15 end-0 w-full shadow-md bg-tumeric-50 rounded-sm z-[10] py-3 px-[22px] ${
                  filterModalShow ? "" : "hidden"
                }`}
              >
                {filters.map((item, index) => (
                  <div
                    key={`zone_${index}`}
                    className="w-full flex space-x-2 rtl:space-x-reverse items-center cursor-pointer mt-2 first:mt-0"
                    onClick={() => {
                      set_selectedFilter(index);
                      set_filterModalShow(false);
                    }}
                  >
                    <div>
                      {selectedFilter === index ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M20.4 21.75C21.1456 21.75 21.75 21.1456 21.75 20.4V3.6C21.75 2.85442 21.1456 2.25 20.4 2.25H3.6C2.85442 2.25 2.25 2.85441 2.25 3.6V20.4C2.25 21.1456 2.85441 21.75 3.6 21.75H20.4ZM6.23003 13.5836C5.9077 13.2613 5.9077 12.7387 6.23003 12.4164C6.55235 12.094 7.07495 12.094 7.39727 12.4164L9.88229 14.9014L16.9702 7.8134C17.2926 7.49107 17.8152 7.49107 18.1375 7.8134C18.4598 8.13573 18.4598 8.65832 18.1375 8.98065L10.4659 16.6522C10.1436 16.9746 9.62099 16.9746 9.29866 16.6522L6.23003 13.5836Z"
                            fill="#118170"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M20.25 20.25V3.75H3.75V20.25H20.25ZM21.75 20.4C21.75 21.1456 21.1456 21.75 20.4 21.75H3.6C2.85441 21.75 2.25 21.1456 2.25 20.4V3.6C2.25 2.85441 2.85442 2.25 3.6 2.25H20.4C21.1456 2.25 21.75 2.85442 21.75 3.6V20.4Z"
                            fill="#163331"
                          />
                        </svg>
                      )}
                    </div>
                    <div
                      title={item?.fields?.Value?.value}
                      className="w-[calc(100%-32px)] text-blueDark-500 truncate"
                    >
                      {item?.fields?.Value?.value}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {!results?.length && !fetching ? (
          <div className="mt-8 w-full flex flex-col items-center p-14">
            <div className="w-fit p-5 bg-blueDark-50">
              <svg
                width="72"
                height="72"
                viewBox="0 0 72 72"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="72" height="72" fill="#BEE2D5" />
                <path
                  d="M35 46C41.0751 46 46 41.0751 46 35C46 28.9249 41.0751 24 35 24C28.9249 24 24 28.9249 24 35C24 41.0751 28.9249 46 35 46Z"
                  stroke="#118170"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M48.7333 48.7333L43 43"
                  stroke="#118170"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M31 39L39 31"
                  stroke="#118170"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M31 31L39 39"
                  stroke="#118170"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="text-[32px] text-center font-[500] uppercase mt-6 text-brand">
              <TranslatedWord EnWord="No Search Results" />
            </div>
            <div className="text-[20px] font-[500] leading-[24px] mt-6 text-blueDark-500 text-center">
              <TranslatedWord EnWord="We couldn't find what you're looking for, try searching for something else." />
            </div>
            <button
              onClick={clearFilter}
              className="bg-tumeric-200 px-[30px] py-4 flex items-center space-x-[10px] rtl:space-x-reverse mt-6"
            >
              <span>
                <TranslatedWord EnWord="Reset Search" />
              </span>
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.5 2H14.5"
                  stroke="#173330"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.5 14V10"
                  stroke="#173330"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4.5 13.0002C4.74486 11.0562 5.69466 9.26965 7.16925 7.97939C8.64384 6.68912 10.5407 5.98489 12.5 6.00025C13.794 6.00162 15.0684 6.31689 16.2139 6.91901C17.3593 7.52112 18.3416 8.39212 19.0764 9.45727C19.8113 10.5224 20.2768 11.7499 20.433 13.0345C20.5892 14.3191 20.4315 15.6224 19.9733 16.8326C19.5152 18.0429 18.7703 19.1239 17.8025 19.983C16.8348 20.8421 15.6731 21.4536 14.4171 21.765C13.1611 22.0765 11.8482 22.0787 10.5912 21.7713C9.33422 21.4639 8.17054 20.8562 7.2 20.0002L4.5 17.6002"
                  stroke="#173330"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.5 17H4.5V22"
                  stroke="#173330"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        ) : null}
        <div className="mt-8 grid md:grid-cols-2 lg:grid-cols-4 grid-cols-1 gap-6">
          {results?.length
            ? results
                .slice(0, (currentViewPage + 1) * itemsPerPage.length)
                .map((item, index) => (
                  <ImageCard
                    key={`imageCard_${index}`}
                    data={item.item}
                    onSelect={() => set_selectedImage(item.item)}
                  />
                ))
            : null}
          {!isFirstLoad && fetching ? (
            <React.Fragment>
              {itemsPerPage.map((item, index) => (
                <LoadingCard key={`loading_${index}`} />
              ))}
            </React.Fragment>
          ) : null}
        </div>
        {showHideButton || showLoadMoreButton ? (
          <div className="w-full mt-14 flex justify-center">
            <button
              className="px-8 py-4 flex items-center justify-center space-x-[10px] rtl:space-x-reverse bg-brand"
              onClick={() => {
                if (showHideButton) {
                  loadLess();
                } else {
                  loadMore();
                }
              }}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={`${showHideButton ? "rotate-180" : ""}`}
              >
                <g clipPath="url(#clip0_2631_34800)">
                  <path
                    d="M1.66683 10.0003C1.66683 14.6027 5.39779 18.3337 10.0002 18.3337C14.6025 18.3337 18.3335 14.6027 18.3335 10.0003C18.3335 5.39795 14.6025 1.66699 10.0002 1.66699C5.39779 1.66699 1.66683 5.39795 1.66683 10.0003Z"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6.66683 10L10.0002 13.3333L13.3335 10"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10 6.66699L10 13.3337"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2631_34800">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                      transform="translate(20) rotate(90)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <span className="text-[16px] leading-[18px] font-[500] text-white uppercase">
                {
                  <TranslatedWord
                    EnWord={showHideButton ? "Hide" : "Load More"}
                  />
                }
              </span>
            </button>
          </div>
        ) : null}
      </div>

      {selectedImage ? (
        <Lightbox
          open={selectedImage}
          close={() => set_selectedImage(null)}
          plugins={[Zoom]}
          zoom={{ ref: zoomRef }}
          carousel={{
            finite: true,
          }}
          render={{
            buttonPrev: () => null,
            buttonNext: () => null,
          }}
          slides={[
            {
              src: selectedImage?.Image?.src,
              alt: "image 1",
            },
          ]}
        />
      ) : null}
    </div>
  );
};
export default withSitecoreContext()(ImagesListing);
