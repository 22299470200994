import React, { useEffect, useRef, useState } from "react";
import {
  Image,
  Link,
  Text,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import ModeSwitcher from "../../../Common/ModeSwitcher";
import { useNavigate } from "react-router-dom";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchItem from "../../../../assets/images/search/1.svg";
import AccessibilityIcon from "../../../../assets/icons/accessibility.svg";
import PhoneIcon from "../../../../assets/icons/phone_icon.svg";
import TicketsIcon from "../../../../assets/icons/tickets.svg";
import TicketsHoverIcon from "../../../../assets/icons/tickets-hover.svg";
import PhoneIconGreen from "../../../../assets/icons/phone_icon_green.svg";

import parser from "html-react-parser";
import UseScrollBlock from "../../../Common/UseScrollBlock";

const Header = ({ sitecoreContext, fields, params }) => {
  const navigate = useNavigate();

  const [collapsed, setCollapsed] = useState(true);
  const [activeItem, setActiveItem] = useState(null);
  const [activeSubItem, setActiveSubItem] = useState(null);
  const [activeSecondLevelItem, setActiveSecondLevelItem] = useState(null);
  const [search, setSearch] = useState(false);
  const [searchType, setSearchType] = useState("");
  const [isSmallDesktopScreen, setIsSmallDesktopScreen] = useState(false);
  const [selectedSecondLevel, setSelectedSecondLevel] = useState(null);
  const [selectedSecondLevelTop, setSelectedSecondLevelTop] = useState(0);
  const secondLevelRef = useRef(null);
  let scrollPosition = 0;
  const [lockScroll, unlockScroll] = UseScrollBlock();
  useEffect(() => {
    unlockScroll();
    if (window.innerWidth < 1285 && window.innerWidth > 1023) {
      setIsSmallDesktopScreen(true);
    }
  }, []);

  function handleMenuToggle() {
    setSearch(false);
    const $body = document.querySelector("body");
    setCollapsed(!collapsed);
    if (collapsed) {
      lockScroll();
    } else {
      unlockScroll();
    }
  }

  function handleLanguageSwitch() {
    return `${sitecoreContext.language === "en" ? "/ar" : "/en"}${
      sitecoreContext.itemPath
    }`;
  }

  const handleItemClick = (index) => {
    if (index === activeItem) {
      setActiveItem(null); // Clicking on the same item closes it
    } else {
      setActiveItem(index); // Clicking on a different item sets it as active
    }
  };

  const handleSubItemClick = (index) => {
    setActiveSubItem(index);
  };
  const handleSecondLevelItemClick = (index) => {
    console.log("index clicked", index);
    if (index === activeSecondLevelItem) {
      setActiveSecondLevelItem(null);
    } else {
      setActiveSecondLevelItem(index);
    }
  };

  const handleInput = (value) => {
    let temp = value.replace(/[^a-zA-Z0-9\u0621-\u064A\s]/g, "");
    if (temp.trim() !== "") {
      setSearchType(temp);
    } else {
      setSearchType("");
    }
  };

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      location.href = `/search-results?q=${e.target.value}`;
      setSearch(false);
    }
  };

  const handleSearchIcon = () => {
    if (searchType !== "") {
      location.href = `/search-results?q=${searchType}`;
    }
  };
  const handleAccessibilityToggle = () => {
    if (UserWay && UserWay !== undefined) {
      UserWay.widgetToggle();
    }
  };

  function getOffsetTop(element) {
    return element ? element.offsetTop + getOffsetTop(element.offsetParent) : 0;
  }
  return (
    <div className="lg:container relative ">
      <header
        data-uw-ignore-s3
        className={`absolute  ${
          params
            ? params?.BackgroundColor !== "light"
              ? "bg-brand"
              : "lg:bg-transparent"
            : "lg:bg-transparent"
        } py-4 mb-8  duration-500 start-0 w-full z-50 px-6 ${
          collapsed
            ? search
              ? "h-fit bg-bluedark"
              : ""
            : "h-screen bg-white overflow-auto"
        }`}
      >
        <nav className=" relative " data-uw-ignore-s3>
          <div
            data-uw-ignore-s3
            className="w-full py-2 flex items-start md:gap-14 lg:justify-start justify-between "
          >
            <Link
              className=" header_logo basis-4/12 md:basis-auto flex-shrink-0 md:flex-shrink-1"
              field={fields?.PrimaryLogoCTALink}
            >
              {params?.BackgroundColor === "light" ? (
                <img
                  src={params?.PrimaryLogo}
                  className="w-auto h-12  md:h-16 lg:24"
                />
              ) : collapsed ? (
                <Image
                  field={fields?.PrimaryLogo}
                  className="w-auto h-12  md:h-16 lg:24"
                />
              ) : (
                <Image
                  field={fields?.PrimaryMobileLogo}
                  className="w-auto h-12  md:h-16 lg:24"
                />
              )}
            </Link>
            <div className="grow lg:block hidden" data-uw-ignore-s3>
              <div
                data-uw-ignore-s3
                className={`flex justify-between pb-4 mb-4 border-b basis- ${
                  params?.TextColor === "dark"
                    ? "border-blueDark-500"
                    : "border-white"
                }`}
              >
                <ModeSwitcher
                  lang={sitecoreContext.language}
                  content={fields}
                  className={`hidden lg:flex ${
                    params?.TextColor === "dark"
                      ? "text-blueDark-500"
                      : "text-white"
                  }`}
                  offColor={
                    params?.TextColor === "dark" ? "#173330" : "#ffffff"
                  }
                />
                <div className="flex gap-10 items-center" data-uw-ignore-s3>
                  {fields?.TicketsCTA?.value?.href && (
                    <TicketsCTA fields={fields} />
                  )}
                  <FontAwesomeIcon
                    onClick={() => {
                      setSearch(!search);
                      setCollapsed(true);
                      setSearchType("");
                    }}
                    icon={faMagnifyingGlass}
                    style={{
                      color: !search
                        ? params?.TextColor === "dark"
                          ? "#173330"
                          : "#ffffff"
                        : "#DBFF06",
                      width: "20px",
                      height: "20px",
                      cursor: "pointer",
                    }}
                  />
                  {fields?.CallCenterDetails?.value && (
                    <CallCenterDetails fields={fields} />
                  )}
                  {!sitecoreContext?.route?.fields?.isLowcorbonEnabled
                    ?.value && (
                    <img
                      src={AccessibilityIcon}
                      className="cursor-pointer accessibilityWidgetTrigger"
                      onClick={handleAccessibilityToggle}
                    />
                  )}
                  <a
                    href={handleLanguageSwitch()}
                    className={`${
                      params?.TextColor === "dark"
                        ? "text-blueDark-500"
                        : "text-white"
                    } font-bold cursor-pointer ms-0 contents`}
                  >
                    {fields?.LanguageLable?.value}
                  </a>
                  <Link
                    target="_blank"
                    field={fields?.SecondaryLogoCTALink}
                    className="ms-0 contents"
                  >
                    {params?.BackgroundColor === "light" ? (
                      <img src={params?.SecondaryLogo} className="h-[38px]" />
                    ) : (
                      <Image
                        field={fields?.SecondaryLogo}
                        className="h-[38px]"
                      />
                    )}
                  </Link>
                </div>
              </div>
              {search ? (
                <div className="w-full border-b-2 border-white">
                  <input
                    type="text"
                    value={searchType}
                    placeholder="What can we help you with?"
                    onChange={(e) => {
                      handleInput(e.target.value);
                    }}
                    onKeyUp={handleKeypress}
                    className="h-[64px] w-full p-[16px] focus:outline-none"
                  />
                  <div
                    className="absolute end-[16px] top-[100px] w-fit cursor-pointer"
                    onClick={handleSearchIcon}
                  >
                    <FontAwesomeIcon
                      icon={faMagnifyingGlass}
                      style={{
                        color: "#163331",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  </div>
                  {/* <div className="bg-bluedark w-full text-white py-[24px] px-[16px]">
                    {searchType !== "" && (
                      <div className="pb-5">
                        {[0, 1, 2].map((itemOne) => {
                          return (
                            <div
                              className="flex items-center mt-2"
                              key={itemOne}
                            >
                              <FontAwesomeIcon
                                icon={faMagnifyingGlass}
                                style={{
                                  color: "#ffffff",
                                  width: "15px",
                                  height: "15px",
                                }}
                              />
                              <p className="ms-3">What is COP28?</p>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    Recommended Searches:
                    <ul className="flex list-none ms-0 py-5 border-b-2 border-white">
                      <li className="ms-0">What is COP28?</li>
                      <li className="ms-10">What is the Blue Zone?</li>
                      <li className="ms-10">What is the Green Zone?</li>
                      <li className="ms-10">Youth Program</li>
                      <li className="ms-10">COP28 Program</li>
                    </ul>
                    <p className="py-5">Featured</p>
                    <div className="flex">
                      {[0, 1, 2, 3].map((item) => {
                        return (
                          <div key={item} className={`${item !== 0 && "ms-5"}`}>
                            <img src={SearchItem} />
                            <p>Mission Statement</p>
                          </div>
                        );
                      })}
                    </div>
                  </div> */}
                </div>
              ) : (
                <div className="w-full" data-uw-ignore-s3>
                  <ul
                    className="w-full ms-0 flex gap-10 justify-end"
                    data-uw-ignore-s3
                  >
                    {/* Desktop Navigation */}
                    {fields?.SelectedLinks?.map((item, index) =>
                      item?.fields?.SelectCTALinks?.length ? (
                        // if Link has second level items
                        <li
                          key={index}
                          data-uw-ignore-s3
                          className="group/first-level relative flex items-center gap-4 cursor-pointer pb-8"
                          onMouseLeave={() => setSelectedSecondLevel(null)}
                        >
                          <div
                            data-uw-ignore-s3
                            className={`${
                              params?.TextColor === "dark"
                                ? "text-blueDark-500 hover:text-blueDark-100"
                                : "text-white hover:text-lime-green-900"
                            } `}
                          >
                            {item?.fields?.CTALink?.value?.href && (
                              <a
                                data-uw-ignore-s3
                                href={item?.fields?.CTALink?.value?.href}
                              >
                                {item?.fields?.Title?.value}
                              </a>
                            )}
                            {!item?.fields?.CTALink?.value?.href && (
                              <span className="inline-block whitespace-nowrap">
                                {item?.fields?.Title?.value}
                              </span>
                            )}
                          </div>
                          <div
                            data-uw-ignore-s3
                            className="group-hover/first-level:block  hidden absolute top-10 border-b-2 border-white bg-white text-blueDark-500 rounded-md drop-shadow-lg px-2 py-5 w-max -end-10 "
                          >
                            <div
                              className="max-h-[400px] overflow-y-auto overflow-x-hidden"
                              ref={secondLevelRef}
                            >
                              {item?.fields?.SelectCTALinks.map(
                                (sub, sub_i) => {
                                  let href =
                                    sub?.fields?.CTALink?.value?.href ||
                                    "#no-action";
                                  return (
                                    <div
                                      className={
                                        (activeSubItem === sub_i
                                          ? "font-semibold"
                                          : "") +
                                        ` text-base  block py-1.5 relative   group/second-level
                                  `
                                      }
                                      onMouseEnter={(e) => {
                                        setSelectedSecondLevel(sub);
                                        setSelectedSecondLevelTop(
                                          e.target?.offsetTop -
                                            secondLevelRef?.current?.scrollTop
                                        );
                                        console.log(
                                          "offsetTop is: ",
                                          getOffsetTop(e.target)
                                        );
                                        console.log(
                                          "scrollTop is: ",
                                          secondLevelRef?.current?.scrollTop
                                        );
                                      }}
                                      key={sub_i}
                                    >
                                      <a
                                        href={href}
                                        id={sub?.id}
                                        onClick={(e) => {
                                          if (
                                            e.target?.href.includes(
                                              "#no-action"
                                            )
                                          ) {
                                            e.preventDefault();
                                          }
                                          handleSubItemClick(sub_i);
                                        }}
                                        className="group-hover/second-level:font-semibold px-8 inline-block -mx-5  overflow-hidden"
                                      >
                                        {sub?.fields?.CTALink?.value?.text ||
                                          sub?.fields?.Title?.value}
                                      </a>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                            {selectedSecondLevel?.fields?.SelectCTALinks
                              ?.length ? (
                              <div
                                className={`flex gap-4  py-4 mt-4 px-7 bg-tumeric-50 h-auto  z-10  rounded-md drop-shadow-lg absolute  w-full min-w-[240px] text-blueDark-500 flex-col ${
                                  isSmallDesktopScreen &&
                                  index !== fields?.SelectedLinks?.length - 1
                                    ? "start-full"
                                    : "end-full"
                                }`}
                                style={{
                                  top: `${selectedSecondLevelTop - 24}px`,
                                }}
                              >
                                <ul className="ps-4 list-none max-h-[200px] overflow-auto over -mx-4">
                                  {selectedSecondLevel?.fields?.SelectCTALinks?.map(
                                    (thirdLevel, index) => {
                                      return (
                                        <li key={index}>
                                          <Link
                                            field={thirdLevel.fields?.CTALink}
                                            className="hover:font-semibold  inline-block mb-2"
                                          />
                                        </li>
                                      );
                                    }
                                  )}
                                </ul>
                              </div>
                            ) : null}
                          </div>
                        </li>
                      ) : (
                        <li
                          key={index}
                          data-uw-ignore-s3
                          className="group relative flex items-center gap-4 cursor-pointer pb-8"
                        >
                          <div
                            data-uw-ignore-s3
                            className={`${
                              params?.TextColor === "dark"
                                ? "text-blueDark-500 hover:text-blueDark-100"
                                : "text-white hover:text-lime-green-900"
                            }`}
                          >
                            {item?.fields?.CTALink?.value?.href && (
                              <a
                                data-uw-ignore-s3
                                href={item?.fields?.CTALink?.value?.href}
                              >
                                {item?.fields?.Title?.value}
                              </a>
                            )}
                            {!item?.fields?.CTALink?.value?.href &&
                              item?.fields?.Title?.value}
                          </div>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              )}
            </div>
            <div
              className="lg:hidden flex items-center justify-end gap-4 md:gap-8 basis-7/12 md:basis-auto flex-shrink-0 md:flex-shrink-1"
              data-uw-ignore-s3
            >
              {fields?.TicketsCTA?.value?.href && (
                <TicketsCTA fields={fields} />
              )}
              {fields?.CallCenterDetails?.value && (
                <CallCenterDetails fields={fields} />
              )}
              {!sitecoreContext?.route?.fields?.isLowcorbonEnabled?.value && (
                <img
                  src={AccessibilityIcon}
                  className="cursor-pointer accessibilityWidgetTrigger "
                  onClick={handleAccessibilityToggle}
                />
              )}
              <button
                data-uw-ignore-s3
                className={`outline-none lg:hidden navbar-toggler  x  o ${
                  collapsed ? "collapsed" : ""
                }`}
                onClick={handleMenuToggle}
                type="button"
              >
                <span
                  className={
                    params?.BackgroundColor !== "light"
                      ? "icon-bar ms-auto"
                      : collapsed
                      ? "icon-bar icon-bar-black"
                      : "icon-bar"
                  }
                ></span>
                <span
                  className={
                    params?.BackgroundColor !== "light"
                      ? "icon-bar ms-auto"
                      : collapsed
                      ? "icon-bar icon-bar-black"
                      : "icon-bar"
                  }
                ></span>
                <span
                  className={
                    params?.BackgroundColor !== "light"
                      ? "icon-bar ms-auto"
                      : collapsed
                      ? "icon-bar icon-bar-black"
                      : "icon-bar"
                  }
                ></span>
              </button>
            </div>
          </div>

          {!collapsed && (
            <div className="block lg:hidden  px-4 bg-tumeric-50 -mx-6 mt-4">
              <input
                type="text"
                placeholder="What can we help you with?"
                value={searchType}
                onChange={(e) => {
                  handleInput(e.target.value);
                }}
                onKeyUp={handleKeypress}
                className="h-[64px] w-full p-[16px] focus:outline-none bg-tumeric-50"
              />
              <div
                className="absolute end-[3px] md:top-[100px] top-[104px] w-fit"
                onClick={handleSearchIcon}
              >
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  style={{ color: "#163331", width: "20px", height: "20px" }}
                />
              </div>
              {/* <div className="bg-bluedark w-full text-white py-[24px]">
                {searchType!=='' &&
                  <div className="pb-5">
                    {[0, 1, 2].map((itemOne)=>{
                      return(
                        <div className="flex items-center mt-2" key={itemOne}>
                          <FontAwesomeIcon icon={faMagnifyingGlass} style={{color: "#ffffff", width: "15px", height: "15px"}} />
                          <p className="ms-3">What is COP28?</p>
                        </div>
                    )})}
                  </div>
                }
                <p>Recommended Searches:</p>
                <ul className="list-none ms-0 border-b-2 border-white text-[16px] leading-10 pb-5">
                  <li>What is COP28?</li>
                  <li>What is the Blue Zone?</li>
                  <li>What is the Green Zone?</li>
                  <li>Youth Program</li>
                  <li>COP28 Program</li>
                </ul>
                <p className="py-5">Featured</p>
                <div className="flex overflow-auto scrollbar-hide">
                  {[0, 1, 2, 3].map((item)=>{
                    return(
                    <div key={item} className={`${item !== 0 && 'ms-5'}`}>
                      <img src={SearchItem} className="min-w-[180px]" />
                      <p>Mission Statement</p>
                    </div>
                    )
                  })}
                </div>
              </div> */}
            </div>
          )}

          {/* Mobile Navigation */}
          {!collapsed && (
            <ul className="w-full mt-8 gap-16 lg:hidden block ms-0">
              <div className="border-b border-white">
                {fields?.SelectedLinks.map((item, index) => (
                  // first level have childs
                  <div key={index}>
                    {item?.fields?.SelectCTALinks?.length ? (
                      <li
                        onClick={() => handleItemClick(index)}
                        className="flex my-5 font-bold items-center gap-4 cursor-pointer"
                      >
                        <div className="text-blueDark-500 font-normal">
                          <Text field={item?.fields?.Title} />
                        </div>
                      </li>
                    ) : (
                      // first level doesn't have childs

                      <li className="my-2 list-none">
                        <Link
                          field={item?.fields?.CTALink}
                          key={index}
                          className="text-blueDark-500"
                        >
                          <Text field={item?.fields?.Title} />
                        </Link>
                      </li>
                    )}
                    {/* // second level looping */}
                    <div
                      className={`w-full border-b border-white flex flex-col justify-end  ${
                        activeItem !== null && activeItem === index
                          ? "block"
                          : "hidden"
                      } duration-200`}
                    >
                      {activeItem !== null && activeItem === index ? (
                        <div className="flex gap-4 flex-col mb-4">
                          {item?.fields?.SelectCTALinks.map((item, index) => (
                            <div key={index} className="ps-2">
                              {item?.fields?.CTALink?.value?.href ? (
                                <Link
                                  className={
                                    (activeItem === index ? "" : "") +
                                    " text-blueDark-500"
                                  }
                                  field={item?.fields?.CTALink}
                                >
                                  {item?.fields?.CTALink?.value?.text}
                                </Link>
                              ) : (
                                <>
                                  <a
                                    onClick={() =>
                                      handleSecondLevelItemClick(index)
                                    }
                                  >
                                    <Text
                                      field={item?.fields?.Title}
                                      className="cursor-pointer"
                                    />
                                  </a>
                                  {/* // Third Level Looping */}
                                  {activeSecondLevelItem === index && (
                                    <div className="bg-tumeric-50 flex flex-col py-4 ps-8 pe-4 -me-6 -ms-8 overflow-hidden my-2 gap-4">
                                      {item.fields?.SelectCTALinks?.map(
                                        (thirdLevel, index) => {
                                          return (
                                            <Link
                                              key={index}
                                              field={
                                                thirdLevel?.fields?.CTALink
                                              }
                                            />
                                          );
                                        }
                                      )}
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          ))}
                        </div>
                      ) : null}
                    </div>
                  </div>
                ))}
              </div>
              <div className="lg:hidden block">
                <div className="my-4"></div>
                <ModeSwitcher
                  id=""
                  lang={sitecoreContext.language}
                  content={fields}
                  className="flex flex-start w-full text-blueDark-500"
                  offColor="#F6F5F1"
                />
                <div className="mt-6">
                  <a
                    href={handleLanguageSwitch()}
                    className="text-blueDark-500 my-4 inline-block font-bold cursor-pointer ms-0 "
                  >
                    {fields?.LanguageLable?.value}
                  </a>
                  <Link
                    target="_blank"
                    field={fields?.SecondaryLogoCTALink}
                    className="ms-0 contents"
                  >
                    <Image
                      field={fields?.SecondaryLogo}
                      className="h-[50px] brightness-0 "
                    />
                  </Link>
                </div>
              </div>
            </ul>
          )}
        </nav>
      </header>
      {/* <SocialLinks /> */}
    </div>
  );
};

export default withSitecoreContext()(Header);

const CallCenterDetails = ({ fields }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="group relative ">
      <img
        src={PhoneIcon}
        className="cursor-pointer group-hover:hidden min-w-8"
        onClick={() => setIsOpen(!isOpen)}
      />
      <img
        src={PhoneIconGreen}
        className="cursor-pointer hidden group-hover:block min-w-8"
        onClick={() => setIsOpen(!isOpen)}
      />
      {isOpen && (
        <div
          className=" text-white w-[276px] bg-brand p-4 absolute -start-[138px] z-10 top-12 
  before:w-0 before:h-0 before:border-l-[10px] before:border-l-transparent 
  before:border-r-[10px] before:border-r-transparent  
  before:border-b-[10px] before:border-b-brand
  before:absolute before:-top-2 before:start-[calc(50%+5px)]"
        >
          {parser(fields?.CallCenterDetails?.value)}
        </div>
      )}
    </div>
  );
};

const TicketsCTA = ({ fields }) => (
  <a
    className="uppercase text-white flex gap-4 group items-center hover:text-lime-green-900 "
    href={fields?.TicketsCTA?.value?.href}
    target={
      fields?.TicketsCTA?.value?.linktype === "internal" ? "_self" : "_blank"
    }
    rel="noreferrer"
  >
    {/* <span className="hidden md:block">{fields?.TicketsCTA?.value?.text}</span> */}
    <img src={TicketsIcon} className="group-hover:hidden block" />
    <img src={TicketsHoverIcon} className="hidden group-hover:block" />
  </a>
);
