import React from "react";
import PlaceholderComponent from "../../../Common/PlaceholderComponent";
import MulticardListDefaultVariant from "./Default";
import MulticardListWithSubCardsVariant from "./WithSubCards";

const MulticardList = ({ fields, params }) => {
    return (
        <>
            {params?.Variant &&
                [
                    "Default",
                    "WithSubCards"
                ].indexOf(params?.Variant) === -1 && (
                    <PlaceholderComponent title={`MulticardList / ${params?.Variant}`} />
                )
            }
            {params?.Variant === "WithSubCards" && (
                <MulticardListWithSubCardsVariant
                    fields={fields}
                />
            )}
            {(params?.Variant === "Default" || !params?.Variant) && (
                <MulticardListDefaultVariant
                    fields={fields}
                />
            )}
        </>
    );
};

export default MulticardList;
