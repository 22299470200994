import React from "react";
import { Link, RichText } from "@sitecore-jss/sitecore-jss-react";
import FirstCardFullWidth from "../FirstCardFullWidth";
import Poloroid from "../Poloroid";
import CTA from "../../../Common/CTA";
import PlaceholderComponent from "../../../Common/PlaceholderComponent";
import TitleAndDescription from "../TitleAndDescription";
import ImageGallery from "../ImageGallery";
import ContainerCard from "../ContainerCard";
import CardStack from "../CardStack";

const Cards = ({ fields, params }) => {
  return (
    <>
      {params?.Variant &&
        [
          "FirstCardFullWidth",
          "Poloroid",
          "TitleAndDescription",
          "ImageGallery",
          "ContainerCard",
          "Default",
          "CardStack",
        ].indexOf(params?.Variant) === -1 && (
          <PlaceholderComponent title={`Cards / ${params?.Variant}`} />
        )}
      {params?.Variant === "FirstCardFullWidth" && (
        <FirstCardFullWidth fields={fields} />
      )}
      {params?.Variant === "Poloroid" && <Poloroid fields={fields} />}
      {params?.Variant === "TitleAndDescription" && (
        <TitleAndDescription fields={fields} />
      )}
      {params?.Variant === "ImageGallery" && <ImageGallery fields={fields} />}
      {params?.Variant === "ContainerCard" && <ContainerCard fields={fields} />}
      {params?.Variant === "CardStack" && (
        <CardStack fields={fields} params={params} />
      )}
      {(params?.Variant === "Default" || !params?.Variant) && (
        <section className="bg-white">
          <div className="w-full flex md:flex-row flex-col relative overflow-hidden">
            {fields?.cards?.map((item, index) => {
              return (
                <div
                  className={`md:w-1/2 w-full min-h-[600px] md:px-20 px-5 py-32 flex flex-col justify-between ${
                    index === 0 ? "bg-tumeric-50" : "bg-blueDark-50"
                  }`}
                  key={index}
                >
                  <div>
                    <h3
                      className={`text-5xl text-blueDark-500 font-[500] w-fit`}
                    >
                      <RichText field={item.fields?.Title} />
                    </h3>
                    <div
                      className={`mt-8 text-lg md:text-xl text-blueDark-500`}
                    >
                      <RichText field={item?.fields?.Description} />
                    </div>
                  </div>
                  {item?.fields?.CTAbutton?.value?.href && (
                    <div className="mt-6">
                      <CTA
                        className={`flex w-fit items-center ${
                          index === 0 ? "bg-blueDark-600" : "bg-brand"
                        }`}
                        color={index === 0 ? "blueDark" : "brand"}
                      >
                        <Link field={item?.fields?.CTAbutton} />
                      </CTA>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </section>
      )}
    </>
  );
};

export default Cards;
