import React, { useState, useRef } from "react";
import arrowLeft from "../../../../assets/icons/arrow-left.svg";
import arrowLeftDark from "../../../../assets/icons/arrow-left-dark.svg";
import arrowRight from "../../../../assets/icons/arrow-right.svg";
import arrowRightDark from "../../../../assets/icons/arrow-right-dark.svg";
import CollapserContainer from "../../../Common/CollapserContainer";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";

const MultiTabAccordion = ({ sitecoreContext, fields, params, isRtl }) => {
  const ref = useRef(null);
  const [activeTab, setActiveTab] = useState(0);

  const scroll = (scrollOffset) => {
    if (isRtl === "true") {
      ref.current.scrollLeft = -1 * scrollOffset;
    } else {
      ref.current.scrollLeft = scrollOffset;
    }
  };

  const handleClick = (active) => {
    setActiveTab(active);
    scroll(active * 150);
  };

  const moveRight = () => {
    if (activeTab < 2) {
      setActiveTab(activeTab + 1);
      scroll((activeTab + 1) * 150);
    } else {
      return;
    }
  };

  const moveLeft = () => {
    if (activeTab > 0) {
      setActiveTab(activeTab - 1);
      scroll((activeTab - 1) * 150);
    } else {
      return;
    }
  };

  return (
    <div>
      {fields?.SelectedTabs.length > 1 && (
        <section
          className={`${
            sitecoreContext?.route?.fields?.PageTheme?.value === "Blue"
              ? "bg-white"
              : "bg-brand"
          } py-10 md:px-0 px-6`}
        >
          <>
            <div
              ref={ref}
              className={`md:border-b border-none overflow-hidden ${
                sitecoreContext?.route?.fields?.PageTheme?.value === "Blue"
                  ? "border-blueDark-500"
                  : "border-white"
              } flex gap-10 items-center`}
            >
              {fields?.SelectedTabs.map((item, index) => (
                <h4
                  key={index}
                  onClick={() => handleClick(index)}
                  className={
                    "md:w-1/3 text-start cursor-default md:text-center py-2 " +
                    (activeTab === index
                      ? sitecoreContext?.route?.fields?.PageTheme?.value ===
                        "Blue"
                        ? "md:border-b-2 border-blueDark-500 text-blueDark-500"
                        : "md:border-b-2 border-white text-white"
                      : sitecoreContext?.route?.fields?.PageTheme?.value ===
                        "Blue"
                      ? "text-blueDark-500/50"
                      : "text-white/50")
                  }
                >
                  {item?.displayName}
                </h4>
              ))}
            </div>
            <div
              className={`border-b ${
                sitecoreContext?.route?.fields?.PageTheme?.value === "Blue"
                  ? "border-blueDark-500"
                  : "border-white"
              } py-8 w-full md:hidden flex items-center justify-start gap-6`}
            >
              <div onClick={moveLeft}>
                {sitecoreContext?.route?.fields?.PageTheme?.value === "Blue" ? (
                  <img
                    src={isRtl === "true" ? arrowRightDark : arrowLeftDark}
                    alt="arrow-left-dark"
                  />
                ) : (
                  <img
                    src={isRtl === "true" ? arrowRight : arrowLeft}
                    alt="arrow-left"
                  />
                )}
              </div>
              <div onClick={moveRight}>
                {sitecoreContext?.route?.fields?.PageTheme?.value === "Blue" ? (
                  <img
                    src={isRtl === "true" ? arrowLeftDark : arrowRightDark}
                    alt="arrow-right-dark"
                  />
                ) : (
                  <img
                    src={isRtl === "true" ? arrowLeft : arrowRight}
                    alt="arrow-right"
                  />
                )}
              </div>
            </div>
          </>
        </section>
      )}
      {activeTab === 0 && (
        <CollapserContainer
          fields={fields?.SelectedTabs[0]?.fields}
          sitecoreContext={sitecoreContext}
        />
      )}
      {activeTab === 1 && (
        <CollapserContainer
          fields={fields?.SelectedTabs[1]?.fields}
          sitecoreContext={sitecoreContext}
        />
      )}
      {activeTab === 2 && (
        <CollapserContainer
          fields={fields?.SelectedTabs[2]?.fields}
          sitecoreContext={sitecoreContext}
        />
      )}
    </div>
  );
};

export default withSitecoreContext()(MultiTabAccordion);
