import React from "react";
import { RichText } from "@sitecore-jss/sitecore-jss-react";

const TitleAndDescription = ({ fields }) => {
  return (
    <section className="morpheus-den-gradient color-block bg-brand">
      <div className="container">
        <div className="pt-10 ">
          <h3 className="md:w-full w-full text-white font-[500] text-center md:text-start">
            <RichText field={fields?.Title} />
          </h3>
          <div className="mt-10 flex flex-wrap gap-y-5 justify-center gap-x-5">
            {fields?.cards?.map((item, index) => {
              return (
                <div className={"md:px-4 mt-5 pb-10 md:w-[430px]"} key={index}>
                  <div className="text-center">
                    <div className="text-white text-lg md:text-xl font-bold">
                      <RichText field={item?.fields?.Title} />
                    </div>
                    <div className="text-white mt-5">
                      <RichText field={item?.fields?.Description} />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TitleAndDescription;
